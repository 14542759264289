import React, { useEffect, useState } from "react";
import { Card, Tabs } from "antd";
import UserArtworks from "../UserArtworks/UserArtworks";
import UserBlogs from "../UserBlogs/UserBlogs";
import UserArtVerification from "../UserArtVerification/UserArtVerification";
import UserActiveLog from "../UserActivtyLog/UserActiveLog";
import UserExhibitionPhotography from "../UserExhibitionPhotography/UserExhibitionPhotography";
import { withRouter } from "react-router";
import { firestore } from '../../firebase/firebase';
import ProfileImage from "../../assets/images/default_profile.jpg"
import ExhibitionPoints from "../ExhibitionPoints/ExhibitionPoints";
const TabPane = Tabs.TabPane;
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserOtherDetails = (props) => {
  const [userDocument, setUserDocument] = useState(null);
  const [loader, setLoading] = useState(false);
  const docID = localStorage.getItem('doc_Id')
  
  useEffect(() => {
    setLoading(true);
    const docRef = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' :'users').doc(docID);
    setLoading(false);
    docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        setUserDocument(documentData);
      }
    });
  }, [docID]);
  return (
    <Card title="User Details">
      <div className="user-alldetails">
        <div className="detail-img">
          <img src={userDocument?.profile_url == '' ? ProfileImage : userDocument?.profile_url} width='80px' height="80px"></img>
        </div>
        <div className="user-deails">
          <b>Full Name :</b> {userDocument?.full_name}<br/>
          <b>Email : </b>{userDocument?.email}<br/>
          <b>Phone Number : </b>{userDocument?.country_code + userDocument?.phone_number}<br/>
          <b>Subscription : </b>{userDocument?.subscription_plan}<br/>
        </div>
      </div>
      <Tabs defaultActiveKey="1" size={"large"}>
        <TabPane tab="Artworks" key="1"><UserArtworks /></TabPane>
        <TabPane tab="Blogs" key="2"><UserBlogs /></TabPane>
        <TabPane tab="Artwork Verification" key="3"><UserArtVerification /></TabPane>
        <TabPane tab="Exhibition Photography" key="4"><UserExhibitionPhotography/></TabPane>
        <TabPane tab="Exhibition Points" key="6"><ExhibitionPoints/></TabPane>
        <TabPane tab="Activity Log" key="5"><UserActiveLog /></TabPane>
      </Tabs>
    </Card>

  )
}



export default withRouter(UserOtherDetails);