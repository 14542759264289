import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Button, Form, Card, Space, Upload, Select, DatePicker, TimePicker, Modal, Radio, message } from 'antd';
import { firestore, firebase, storage } from '../../firebase/firebase'
import CircularProgress from "../CircularProgress/index";
import moment from 'moment';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { REACT_APP_PRODUCTION_MODE } = process.env;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const AddPartnerGallary = (props) => {
    const [loader, setLoading] = useState(false);
    const [date, setDate] = useState(null);
    const { register } = useForm();
    const [form2] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(false);
    const [image, setImage] = useState(false);
    const [imageUrlArr, setImageUrlArr] = useState([])
    const [exhibitionPeriod, setExhibitionPeriod] = useState("");
    const [time, setTime] = useState(null);
    const { Option } = Select;

    const validator = async (rule, value) => {
        if (rule.field === 'exhibition_period') {

            if (date == null) {
                return Promise.reject('Please input your exhibition period');
            }
            else {
                return Promise.resolve();
            }
        }
        if (rule.field === 'exhibition_time_period') {

            if (time == null) {
                return Promise.reject('Please input your exhibition time period');
            }
            else {
                return Promise.resolve();
            }
        }
        if (rule.field === 'gallary_name' && value) {
            const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : 'partner_galleries')
                .where("gallary_name", '==', value.toUpperCase())
                .get()
            if (!querySnapshot.empty) {
                return Promise.reject('Partner gallary name already exists');
            }
            else {
                return Promise.resolve();
            }
        }
        else {
            return Promise.resolve();
        }
    }

    const currentMonthData = (value) => {
        setExhibitionPeriod(value);
        const date = new Date();
        const firstDay = moment(
            new Date(date.getFullYear(), date.getMonth() + 1, 1)
        )
            .format("Do-MMMM-YYYY")
            .toString();
        const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0))
            .format("Do-MMMM-YYYY")
            .toString();
        setDate([firstDay, lastDay]);
    };

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const docRef = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : 'partner_galleries').doc().id
            const imageData = imageUrlArr.slice(0, 3)
            let imagePartnerData = []
            for (const imagePartner of imageData) {
                const url = await customUpload(imagePartner?.file, docRef);
                imagePartnerData.push(url)
            }

            const addgalleies = {
                gallary_name: values.gallary_name.toUpperCase(),
                exhibition_start_period: date[0].split(" ")[0],
                exhibition_end_period: date[1].split(" ")[0],
                exhibition_start_time: time[0].split(" ")[0],
                exhibition_end_time: time[1].split(" ")[0],
                unlimited_exhibition: exhibitionPeriod == "Unlimited" ? true : false,
                gallary_image: imagePartnerData,
                sunday: values.sunday,
                monday: values.monday,
                tuesday: values.tuesday,
                wednesday: values.wednesday,
                thursday: values.thursday,
                friday: values.friday,
                saturday: values.saturday,
                address: values.address,
                caption: values.caption == undefined ? '' : values.caption,
                exhibition_current: 'on',
                create_at: firebase.firestore.FieldValue.serverTimestamp(),
                update_at: firebase.firestore.FieldValue.serverTimestamp()
            };
            // write to Cloud Firestore
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : 'partner_galleries').doc(docRef).set(addgalleies)
            setLoading(false)
            message.success('Successfully Add Gallary');

            return props.history.push({
                pathname: `/exhibition-partner-galleries-list`,
            });
        }
        catch (error) {
            console.log(error);
            setLoading(false)
            message.error('Something went to wrong');
        }

    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => (resolve(reader.result), setImage(reader.result));
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        // setImage(file.url || file.preview) 
        setPreviewImage(true)

    }

    const customUpload = (partnerImage, partnerGallaryId) => {
        return (new Promise(async (resolve, reject) => {
            try {
                if (fileList == []) {
                    return resolve([]);
                }
                const metadata = {
                    contentType: 'image/jpeg'
                }
                const storageRef = storage.ref();
                const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_partner_galleries/${partnerGallaryId}/${new Date()}` : `partner_galleries/${partnerGallaryId}/${new Date()}`).put(partnerImage, metadata);
                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                // console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                // console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        uploadTask.snapshot.ref.getDownloadURL(partnerGallaryId).then((url) => {
                            if (!!url) {
                                resolve(url);
                            }
                        })
                    }
                );

            } catch (e) {
                console.log(e);
                reject(e);
            }
        }))
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLtM = file.size / 1024 / 1024 < 5;
        if (!isLtM) {
            message.error("Image must smaller than 5MB!");
        }
        return isJpgOrPng;
    };

    const handleChange = async (info) => {
        if (info.file.status === "uploading") {
            info.file.status = "done";
        }
        if (info.file.status === "done") {
            setFileList(info.fileList);
        }
    };

    const uploaderProps = {
        name: "file",
        listType: "picture-card",
        accept: "image/*",
        multiple: true,
        maxCount: 3,
        fileList: fileList,
        onPreview: handlePreview,
        beforeUpload: beforeUpload,
        onChange: handleChange,
        customRequest: async ({ onSuccess, file }) => {
            // const compressedFile = await getCompressedFile(file);
            imageUrlArr.push({
                file,
                // compressedFile: compressedFile,
            });
            setImageUrlArr([...imageUrlArr]);
            onSuccess("ok");
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const index_2 = imageUrlArr.findIndex(
                (item) => item.file?.uid === file.uid || item.uid === file.uid
            );
            if (index_2 > -1) {
                imageUrlArr.splice(index_2, 1);
                setImageUrlArr([...imageUrlArr]);
            }
            fileList.splice(index, 1);
            setFileList([...fileList]);
        },
    };

    const disabledDate = (current) => {
        return current && current < moment().subtract(1, 'days');
    };

    const uploadButton = (
        <Upload {...uploaderProps}>
            {fileList.length < uploaderProps.maxCount && "+ Upload"}
        </Upload>
    );
    return (
        <div>
            {loader == false ?
                <Card title="Add Partner Gallery">
                    <Form layout="vertical"
                        {...formItemLayout}
                        form={form2}
                        onFinish={onSubmit}
                    >
                        <Form.Item name="id" hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Gallary Name"
                            name="gallary_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your gallary name',
                                }
                            ]}
                        >
                            <Input ref={register} />
                        </Form.Item>

                        <Form.Item
                            label="Exhibition Period"
                            name="exhibition_period"
                            rules={[{ validator: validator }]}
                        >
                            <Select
                                size="large"
                                onSelect={(value) =>
                                    value == "Unlimited"
                                        ? currentMonthData(value)
                                        : setExhibitionPeriod(value) || setDate(null)
                                }
                            >
                                <Option value="Exhibition Date Set">Exhibition Date Set</Option>
                                <Option value="Unlimited">Unlimited</Option>
                            </Select>
                            <div style={{ paddingTop: 10 }}>
                                {exhibitionPeriod == "Unlimited" ? (
                                    <span>
                                        Exhibition Peroid : {date?.[0] + " to " + date?.[1]}
                                    </span>
                                ) : null}
                                {exhibitionPeriod == "Exhibition Date Set" ? (
                                    <span>
                                        <RangePicker
                                            style={{ width: 400 }}
                                            format="Do-MMMM-YYYY"
                                            disabledDate={disabledDate}

                                            onChange={(value, dateString) => {
                                                setDate(value == null ? null : dateString);
                                            }}
                                        />
                                    </span>
                                ) : null}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="Exhibition Time Period"
                            name="exhibition_time_period"
                            rules={[{ validator: validator }]}
                        >
                            <TimePicker.RangePicker
                                style={{ width: 400 }}
                                format="hh:mm-A"

                                onChange={(value, dateString) => {
                                    setTime(value == null ? null : dateString);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Days Select  :"
                            name="days"
                        >
                            <Form.Item
                                label="Sunday :"
                                name="sunday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Monday :"
                                name="monday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Tuesday :"
                                name="tuesday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Wednesday :"
                                name="wednesday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Thursday :"
                                name="thursday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Friday :"
                                name="friday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Saturday :"
                                name="saturday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select days open & close',
                                    }
                                ]}
                            >
                                <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                    <Space direction="horizontal">
                                        <Radio value='open'>Open</Radio>
                                        <Radio value='close'>Close</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your gallary address',
                                }
                            ]}
                        >
                            <TextArea rows={4} ref={register} />
                        </Form.Item>
                        <Form.Item
                            label="Gallary Caption"
                            name="caption"
                        >
                            <TextArea rows={4} ref={register} />
                        </Form.Item>

                        <Form.Item
                            label="Exhibition Gallary Image"
                            name="gallary_image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your gallary image',
                                }
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                {uploadButton}
                            </Space>

                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button id="addbutton" type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>
                    <Modal
                        visible={previewImage}
                        footer={null}
                        onCancel={() => setPreviewImage(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={image} />
                    </Modal>
                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>
            }
        </div>
    );
}

export default AddPartnerGallary;
