import React, { useState, useEffect } from 'react'
import { Card } from 'antd';
import { firestore } from "../../firebase/firebase"
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserActiveLog = () => {
    const [activityData, setActivityData] = useState([]);
    const [loader, setLoading] = useState(false);
    const ID = localStorage.getItem('doc_Id')

    useEffect(() => {
        try {
            setLoading(true)
            firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_notification' : 'notification').doc(ID).collection('details').orderBy('create_at', 'desc')
                .onSnapshot((querySnapshot) => {
                    const activitydata = querySnapshot.docs.map((doc) => {
                        let activityd = doc.data()
                        return activityd;
                    });
                    setActivityData(activitydata);
                });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }

    }, []);

    return (
        <>
            {loader == false ?
                <Card>
                    {activityData.length > 0 ?
                        <>
                            {activityData.map((activity, index) => (
                                <div className="art-varification-block" key={index}>
                                    {/* <div className="art-varification-heading"> */}
                                    <h5>Ticket No : {activity?.ticket_no}</h5><br />
                                    <h5>Title : {activity?.title == '' ? '' : activity?.title}</h5><br />
                                    <h5>Description : {activity?.body == '' ? '' : activity?.body}</h5><br />
                                    <h5>postId : {activity?.post_id == '' ? '' : activity?.post_id}</h5><br />
                                    <h5>Created at : {activity?.create_at.toDate().toDateString() + ' ' + activity?.create_at.toDate().toLocaleTimeString()}</h5>

                                    {/* </div> */}
                                </div>))}
                        </>
                        :
                        <div className="art-varification-block">
                            <div className="art-varification-heading">
                                No Activity Log
                            </div>
                        </div>}
                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>
            }
        </>
    );
};
export default UserActiveLog