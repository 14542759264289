import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
// import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { firestore } from "../../firebase/firebase"
import { useSelector } from "react-redux";
import { NotificationOutlined, PictureOutlined, QuestionCircleOutlined, RightCircleOutlined, FlagOutlined, ExclamationCircleOutlined, BgColorsOutlined, FileDoneOutlined, FileExclamationOutlined } from "@ant-design/icons"
const SubMenu = Menu.SubMenu;
const { REACT_APP_PRODUCTION_MODE } = process.env;



const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const [userDocummnet, setUserDocument] = useState();
  const [artworkList, setArtworkList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [loader, setLoading] = useState(false);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const getartworkList = async () => {
    setLoading(true)
    await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
      .where('type', '==', 'artwork')
      .where('status', "==", "pending")
      .onSnapshot(async (querySnapshot) => {
        const artworkapproved = await Promise.all(querySnapshot.docs.map((doc) => {
          let artworkapproveddata = doc.data();
          artworkapproveddata.docId = doc.id;
          return artworkapproveddata;
        }));
        setArtworkList(artworkapproved);
      });
    setLoading(false)
  }

  const getblogList = async () => {
    setLoading(true)
    await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
      .where('type', '==', 'blog')
      .where('status', "==", "pending")
      .onSnapshot(async (querySnapshot) => {
        const artworkapproved = await Promise.all(querySnapshot.docs.map((doc) => {
          let artworkapproveddata = doc.data();
          artworkapproveddata.docId = doc.id;
          return artworkapproveddata;
        }));
        setBlogList(artworkapproved);
      });
    setLoading(false)
  }


  useEffect(() => {
    setLoading(true)
    const docRef = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin').doc(localStorage.getItem('user_id'));
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        setUserDocument(documentData);

      }
    });
    getartworkList()
    getblogList()
    setLoading(false)
    return unsubscribe;
  }, []);


  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="usersdetails">
              <Link to="/usersdetails"><i className="icon icon-profile2" /><span>
                <IntlMessages id="sidebar.users" /></span></Link>
            </Menu.Item>

            <Menu.Item key="artworksapproved">
              <Link to="/artworksapproved">
                <FileDoneOutlined className="icon" />
                <span><IntlMessages id="sidebar.artworks" /></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="artworksverification">
              <Link to="/artworksverification">
                <FileExclamationOutlined className="icon" />
                <span><IntlMessages id="sidebar.artworksverification" /></span>
                {artworkList?.length > 0 ?
                  <span className="side-circle" style={{
                    background: '#af0612',
                    height: '13px',
                    width: '13px',
                    display: 'block',
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    borderRadius: '100%'
                  }}></span>
                  : null}
              </Link>
            </Menu.Item>
            <Menu.Item key="blogsverification">
              <Link to="/blogsverification">
                <FileExclamationOutlined className="icon" />
                <span><IntlMessages id="sidebar.blogsverification" /></span>
                {blogList?.length > 0 ?
                  <span className="side-circle" style={{
                    background: '#af0612',
                    height: '13px',
                    width: '13px',
                    display: 'block',
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    borderRadius: '100%'
                  }}></span>
                  : null}
              </Link>
            </Menu.Item>

            <Menu.Item key="report">
              <Link to="/reportlist">
                <ExclamationCircleOutlined className="icon" />
                <span><IntlMessages id="sidebar.reportlist" /></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="blogs">
              <Link to="/blogs">
                <PictureOutlined className="icon" />
                <span><IntlMessages id="sidebar.blogs" /></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="techniqueslist">
              <Link to="/techniqueslist">
                <i className="icon icon-ticket-new" />
                <span><IntlMessages id="sidebar.techniques" /></span>
              </Link>
            </Menu.Item>

            <SubMenu key="exhibitions-management" popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={<span> <i className="icon icon-dasbhoard" />
                <span><IntlMessages id="sidebar.exhibitions-management" /></span></span>}>
              <Menu.Item key="exhibition-artworks">
                <Link to="/exhibition-artworks">
                  <BgColorsOutlined className="icon" />
                  <span><IntlMessages id="sidebar.exhibition-artworks" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="galleries">
                <Link to="/exhibition-galleries-list">
                  <PictureOutlined className="icon" />
                  <span><IntlMessages id="sidebar.galleries" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="partnergalleries">
                <Link to="/exhibition-partner-galleries-list">
                  <PictureOutlined className="icon" />
                  <span><IntlMessages id="sidebar.partnergalleries" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="exhibition-photograph-list">
                <Link to="/exhibition-photography-list">
                  <PictureOutlined className="icon" />
                  <span><IntlMessages id="sidebar.exhibition-photograph-list" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/banner-card">
                <Link to="/banner-card">
                  <FlagOutlined className="icon" />
                  <span><IntlMessages id="sidebar.banner-cards" /></span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/pop-image">
                <Link to="/pop-image">
                  <FlagOutlined className="icon" />
                  <span><IntlMessages id="sidebar.pop-image" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/contest-list">
                <Link to="/contest-list">
                  <FlagOutlined className="icon" />
                  <span><IntlMessages id="sidebar.content" /></span>
                </Link>
              </Menu.Item>

              {/* <Menu.Item key="exhibitions-management/certificates-manage">
                <Link to="/exhibitions-management/certificates-manage">
                  <SafetyCertificateOutlined className="icon" />
                  <span><IntlMessages id="sidebar.certificates-manage" /></span>
                </Link>
              </Menu.Item> */}
            </SubMenu>

            <Menu.Item key="coupon-code">
              <Link to="/coupon-code">
                <i className="icon icon-ticket-new" />
                {/* <NotificationOutlined className="icon" /> */}
                <span><IntlMessages id="sidebar.coupon-code" /></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="user-notifications">
              <Link to="/send-notifications">
                <NotificationOutlined className="icon" />
                <span><IntlMessages id="sidebar.user-notifications" /></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="cms-pages">
              <Link to="/cms-pages">
                {/* <i className="icon icon-profile2"/> */}
                <NotificationOutlined className="icon" />
                <span><IntlMessages id="sidebar.cms-pages" /></span>
              </Link>
            </Menu.Item>


            {userDocummnet?.isAdmin === "SuperAdmin" ?
              <Menu.Item key="admin-manage/admindetails">
                <Link to="/admindetails">
                  <i className="icon icon-setting" />
                  <span><IntlMessages id="sidebar.admin-manage" /></span>
                </Link>
              </Menu.Item>
              : null}

            {/* <Menu.Item key="faq-manage">
              <Link to="/faq">
                <QuestionCircleOutlined className="icon" />
                <span><IntlMessages id="sidebar.faq" /></span>
              </Link>
            </Menu.Item>*/}
          </Menu> 
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

