import React, { useState, useEffect } from "react";
import { Button, Card, Image, Col, Row } from "antd";
import { firestore, firebase } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import CircularProgress from "../CircularProgress/index";
const {
  REACT_APP_PRODUCTION_MODE,
  REACT_APP_IMAGE_URL_FIREBASE,
  REACT_APP_IMAGE_URL_COMPRESS,
} = process.env;

export default function PhotographyListNew() {
  const [photosList, setPhotosList] = useState([]);
  const [loader, setLoading] = useState(false);
  const [keyDoc, setKeyDoc] = useState("");

  useEffect(() => {
    setLoading(true);
    const exhibitionPicDataAPi = async () => {
      const exhibitionPicData = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development"
            ? "dev_exhibition_photographs"
            : "exhibition_photographs"
        )
        .where("status", "==", "approved")
        .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
        .limit(100)
        .get();
      if (!exhibitionPicData.empty) {
        const photos = await Promise.all(
          exhibitionPicData.docs.map((doc) => {
            let photosdata = doc.data();
            photosdata.docId = doc.id;
            photosdata.img_url = photosdata.img_url.replace(
              REACT_APP_IMAGE_URL_FIREBASE,
              REACT_APP_IMAGE_URL_COMPRESS
            );

            setKeyDoc(doc.id);
            return photosdata;
          })
        );
        setPhotosList(photos);
        setLoading(false);
      } else {
        setPhotosList([]);
      }
      setLoading(false);
    };
    setLoading(false);
    exhibitionPicDataAPi();
  }, []);

  const nextPhotographlist = async () => {
    setLoading(true);
    // setPhotosList([])
    setKeyDoc("");
    await firestore
      .collection(
        REACT_APP_PRODUCTION_MODE == "development"
          ? "dev_exhibition_photographs"
          : "exhibition_photographs"
      )
      .where("status", "==", "approved")
      .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
      .startAfter(keyDoc)
      .limit(100)
      .onSnapshot(async (querySnapshot) => {
        const photos = await Promise.all(
          querySnapshot.docs.map((doc) => {
            let photosdata = doc.data();
            photosdata.docId = doc.id;
            photosdata.img_url = photosdata.img_url.replace(
              REACT_APP_IMAGE_URL_FIREBASE,
              REACT_APP_IMAGE_URL_COMPRESS
            );
            setKeyDoc(doc.id);
            return photosdata;
          })
        );
        setPhotosList(photosList.concat(photos));
      });
    setLoading(false);
  };

  return (
    <>
      {loader == false ? (
        <Card title="Photograph List">
          <div className="text-right">
            <Link to="/add-exhibition-photograph">
              <Button type="primary">Add Exhibition Photographs</Button>
            </Link>
          </div>
          {photosList.length > 0 ? (
            <>
              <Row className="photo-list">
                {photosList.map((photo, index) => (
                  <Col
                    md={8}
                    sm={12}
                    xs={24}
                    key={index}
                    className="photographs"
                  >
                    <Image src={photo?.img_url} key={index}></Image>
                  </Col>
                ))}
              </Row>
              
              {keyDoc != "" ?(
                // <div className="text-center" >
                <></>
              ) : (
                //  <span style={{ marginLeft: 450 }}>No more data</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button type="primary" onClick={() => nextPhotographlist()}>
                    Load more
                  </Button>
                  
                </div>
               )} 
             </>
          ) : (
            <div style={{ textAlign: "center" }}>No Photograph Data</div>
          )}
        </Card>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
