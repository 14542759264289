import React from 'react'
import { firestore } from "../../firebase/firebase"
import moment from 'moment';
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserNotification = () => {

    const checkViewProfileNotification = async () => {
        try {
            let notificationarr = []
            const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_notification' : "notification").get()
            if (!doc.empty) {
                await Promise.all(doc.docs.map(async (doc) => {
                    const viewData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_notification' : 'notification').doc(doc.id).collection("details").where("type", "==", "2").get();
                    if (!viewData.empty) {
                        const ViewNotificationData = await Promise.all(viewData.docs.map(async (ViewNotification) => {

                            let data = ViewNotification.data()
                            data.id = ViewNotification.id
                            return data;

                        }))
                        notificationarr.push(...ViewNotificationData);
                    }
                }))

                notificationarr.map(async (data) => {
                    var timestampInSeconds = Math.round((Date.now() - 15 * 24 * 60 * 60 * 1000) / 1000);

                    timestampInSeconds > data.create_at.seconds
                        &&
                        deleteViewProfileNotification(data.id)


                })
            }

        } catch (error) {
            console.log(error);
        }

    }

    const deleteViewProfileNotification = async (id) => {
        try {

            const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_notification' : "notification").get()
            if (!doc.empty) {
                await Promise.all(doc.docs.map(async (doc) => {
                    await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_notification' : 'notification').doc(doc.id).collection("details").doc(id).delete()

                }))

            }

        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div>
            Check User Notifiaction
            <button onClick={checkViewProfileNotification}>Check View Profile Notification</button>
        </div>
    )
}

export default UserNotification
