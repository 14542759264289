import React, { useState, useEffect } from "react"
import { Table, Modal, Card, Checkbox, Button, Input, Space, Image, message, Popconfirm, Tag } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { DeleteOutlined, EyeOutlined, SearchOutlined, FlagOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { withRouter } from "react-router-dom"
import webUrl from "../../routes/api";
import Highlighter from 'react-highlight-words';
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
import moment from "moment";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserArtworks = (props) => {
  const [artworkList, setArtworkList] = useState([])
  const [loader, setLoading] = useState(false)
  const [hasSelected, setHasSelected] = useState('')
  const [suspendId, setSuspendId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  const searchInput = React.useRef(null)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const ID = localStorage.getItem('doc_Id')
  const [fieldValue, setFieldValue] = useState("")
  const [TextField, setTextField] = useState(false)

  useEffect(() => {
    setFieldValue("")
    setTextField(false)
  }, [])


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const artworkDeleteNotification = async (postId, userData, reportmessage, artworkName) => {
    try {
      const artworkNotification = {
        token: userData?.token,
        artwork_name: artworkName,
        type: '0',
        body: `Your artwork has been deleted. Reason: ${reportmessage}`,
        body_spanish: `Tu obra de arte ha sido eliminada. Razón: ${reportmessage}`,
        body_german: `Dein Kunstwerk wurde gelöscht. Grund: ${reportmessage}`,
        body_italian: `La tua opera d'arte è stata cancellata. Motivo: ${reportmessage}`,
        body_french: `Votre œuvre d'art a été supprimée. Raison: ${reportmessage}`,
        title: 'Artwork',
        title_spanish: 'Presentar una obra de arte',
        title_german: 'Kunstwerk einreichen',
        title_italian: "Inviare un'opera d'arte",
        title_french: "Soumettre une œuvre d'art",
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userData?.user_id,
        post_id: postId,
        check_mode: REACT_APP_PRODUCTION_MODE
      };
      let delete_details = {
        ...userData,
        ...artworkNotification
      }
      await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.deleteArtworkEmail,
        body: { user_id: userData.user_id, check_mode: REACT_APP_PRODUCTION_MODE, userData: delete_details }
      })
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: artworkNotification
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const handleDelete = async (index, reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters")
        return
      }
      setLoading(true)
      const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(index).get();
      if (doc.exists) {
        await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp() })
        let artworkName = doc.data().artwork_name
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', doc.data().user_id).get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {

              await artworkDeleteNotification(index, doc.data(), reportmessage, artworkName);
            });
          })
      }
      setLoading(false)
      message.success('Successfully Updated');
      return props.history.push({
        pathname: `/usersotherdetails`,
      });

    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {

    setPagination(pagination)
  }

  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is {artworkList.length}
      </label>
    )
  }


  useEffect(() => {
    try {
      setLoading(true)
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
        .where('user_id', '==', ID)
        .where('type', '==', 'artwork')
        .where('is_deleted', '==', false)
        .where('status', "in", ['approved', 'rejected'])
        .onSnapshot(async (querySnapshot) => {
          const artworkapproved =
            await Promise.all(querySnapshot.docs.map(async (doc) => {
              let artworkapproveddata = doc.data();
              artworkapproveddata.docId = doc.id;
              const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(artworkapproveddata.user_id).get()
              if (userData.exists) {
                artworkapproveddata.user_name = userData.data()?.user_name
              }
              let date = new Date()
              const monthDataNextMonth = moment(
                  new Date(date.getFullYear(), date.getMonth() + 1, 1)
              )
                  .format("MMMM")
                  .toString();
              const yearDataNextMonth = moment(
                  new Date(date.getFullYear(), date.getMonth() + 1, 1)
              )
                  .format("YYYY")
                  .toString();
              const nextMonthGallary = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : 'exhibition_galleries').where(`${monthDataNextMonth}_${yearDataNextMonth}`, 'array-contains', artworkapproveddata.docId).get()
              if (!nextMonthGallary.empty) {
                  const nextMonthGallaryData = await Promise.all(nextMonthGallary.docs.map((nextgallary) => {
                      return nextgallary.data()
                  }))
                  artworkapproveddata.nextMonthGallaryData = nextMonthGallaryData
              } else {
                artworkapproveddata.nextMonthGallaryData = []
              }

              const monthDataExhibitionMonth = moment(
                  new Date(date.getFullYear(), date.getMonth(), 1)
              )
                  .format("MMMM")
                  .toString();
              const yearDataExhibitionMonth = moment(
                  new Date(date.getFullYear(), date.getMonth(), 1)
              )
                  .format("YYYY")
                  .toString();
              const exhibitionMonthGallary = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : 'exhibition_galleries').where(`${monthDataExhibitionMonth}_${yearDataExhibitionMonth}`, 'array-contains', artworkapproveddata.docId).get()
              if (!exhibitionMonthGallary.empty) {
                  const exhibitionMonthGallaryData = await Promise.all(exhibitionMonthGallary.docs.map((exhibitiongallary) => {
                      return exhibitiongallary.data()
                  }))
                  artworkapproveddata.exhibitionMonthGallaryData = exhibitionMonthGallaryData
              } else {
                artworkapproveddata.exhibitionMonthGallaryData = []
              }
              return artworkapproveddata;
            }));
          setArtworkList(artworkapproved);
        });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }, []);

  const handleReportStatus = async (data) => {
    try {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_post' : 'report_post')
        .add({
          report_id: moment(new Date()).format('DDMMYYYYhhmmss').toString(),
          report_message: '',
          report_post_id: data?.docId,
          sender_report_user_id: 'ADMIN',
          is_deleted: false,
          status: false,
          create_at: firebase.firestore.FieldValue.serverTimestamp(),
          update_at: firebase.firestore.FieldValue.serverTimestamp()
        })
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data?.docId).update({ report_status: true })
      message.success('Sucessfully report')
    } catch (error) {
      message.error('something went to wrong')
    }
  }
  const handleModerationStatus = async (data, moderationFlag) => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data?.docId).update({ moderation: moderationFlag })
      setLoading(false)
      message.success('Sucessfully moderation updated')
    } catch (error) {
      setLoading(false)
      message.error('something went to wrong')
    }
  }

  const handleArtworkFeedShow = async (data, flag) => {
    try {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data?.docId).update({ post_show: flag })
      message.success('Sucessfully updated')
    } catch (error) {
      message.error('something went to wrong')
    }
  }
  const columns = [
    {
      title: 'Artwork Id',
      dataIndex: 'docId',
      width: 300,
      ellipsis: true,
      ...getColumnSearchProps('docId')
    },
    {
      title: 'Artwork',
      dataIndex: 'img_url',
      width: 300,
      height: 300,
      render: (text, record, id) => {
        // console.log(record.profile_url)
        return (
          <div>
            <Image width={150} height={150} src={record.img_url}
            />
          </div>
        );
      },
    },
    {
      title: 'Moderation',
      dataIndex: 'moderation',
      ...getColumnSearchProps('moderation'),
      width: 120,
      render: (text, record, id) => {
        return (
          <div>
            <Checkbox
              onClick={() => handleModerationStatus(record, record.moderation == 'ischecked' ? 'unchecked' : 'ischecked')}
              checked={record?.moderation == 'ischecked' ? true : false}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Flag',
      dataIndex: 'report_status',
      width: 80,
      height: 80,
      render: (text, record, id) => {

        return (
          <div>
            {record?.report_status ? <FlagOutlined style={{ color: 'red', fontSize: '30px' }} /> :
              <Popconfirm title="Sure to report?" onConfirm={() => handleReportStatus(record)}>
                <Button type="link success"><FlagOutlined style={{ color: 'green', fontSize: '30px' }} /></Button>
              </Popconfirm>

            }
          </div>
        );
      },
    },
    {
      title: 'Artwork Show',
      dataIndex: 'post_show',
      width: 160,
      height: 160,
      render: (text, record, id) => {
        return (
          <div>
            {record?.post_show == 'Yes' ?
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                <span className="side-circle" style={{
                  background: record?.nextMonthGallaryData?.length > 0 || record?.exhibitionMonthGallaryData?.length > 0 ? '#f50' : 'green',
                  height: '18px',
                  width: '18px',
                  display: 'block',
                  marginBottom: '8px',
                  marginRight: "15px",
                  borderRadius: '100%'
                }}></span>
                <Tag icon={<CheckOutlined />} color="green" onClick={() => handleArtworkFeedShow(record, 'No')}>Feed</Tag>
              </div>
              :
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                <span className="side-circle" style={{
                  background: record?.nextMonthGallaryData?.length > 0 || record?.exhibitionMonthGallaryData?.length > 0 ? '#f50' : 'green',
                  height: '18px',
                  width: '18px',
                  display: 'block',
                  marginBottom: '8px',
                  marginRight: "15px",
                  borderRadius: '100%'
                }}></span>
                <Tag icon={<PlusOutlined />} color="magenta" onClick={() => handleArtworkFeedShow(record, 'Yes')}>Feed</Tag>
              </div>
            }
          </div>
        );
      },
    },
    {
      title: 'Artwork name',
      dataIndex: 'artwork_name',
      width: 150,
      ellipsis: true,
      ...getColumnSearchProps('artwork_name')
    },
    {
      title: 'Caption',
      dataIndex: 'caption',
      width: 150,
      ellipsis: true
    }, {
      title: 'Height',
      dataIndex: 'height',
      width: 80
    },
    , {
      title: 'Width',
      dataIndex: 'width',
      width: 80
    },
    {
      title: 'Depth',
      dataIndex: 'depth',
      width: 80
    },
    {
      title: 'Techniques',
      dataIndex: 'display_technique',
      width: 120,
      sorter: (a, b) => a.display_technique.length - b.display_technique.length,
    },
    {
      title: 'Year of Creations',
      dataIndex: 'year_of_creation',
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      ...getColumnSearchProps('status'),
      render: (text, record, index) => (
        <div style={{
          display: "flex",
          flexwrap: "wrap"
        }}>
          {record.status == "approved" ? <span style={{ color: "green" }}>approved</span> : <span style={{ color: "red" }}>rejected</span>}{/* <Select
            className={`select ${record.status}`}
            size="large"
            defaultValue={record.status}
          // updateUserApproval(record.docId, value)
          >
            <Option value="approved" style={{ color: 'green' }}>Approved</Option>
            <Option value="rejected" style={{ color: 'red' }}>Rejected</Option>
          </Select> */}
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      render: (text, record, index) =>
        artworkList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>
            <Button type="link success" onClick={() => { setIsModalVisible(true); setSuspendId(record.docId) }}><DeleteOutlined style={{ fontSize: '18px', marginLeft: '10px' }} /></Button>

          </div>
        ) : null,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: 100,
      render: (text, record, index) =>
        artworkList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>
            <a href={`${webUrl?.postDetailsURl}/${record.docId}`} target="_blank">
              <Button type="link success">
                <EyeOutlined style={{ fontSize: '20px' }} />
              </Button>
            </a>
          </div>
        ) : null,
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected([])
      if (selectedRowKeys?.length <= 50) {
        setHasSelected(selectedRowKeys)
      } else {
        message.error('Please select maximum 50 data')
      }
    },
    onSelect: (record, selected, selectedRows) => {

    },
    onSelectAll: (selected, selectedRows, changeRows) => {
    },
  };

  const checkdelete = async (reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters")
        return
      }
      setLoading(true)
      await Promise.all(hasSelected.map(async (dataID) => {
        const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(dataID).get();
        if (doc.exists) {
          await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp() })
          let artworkName = doc.data().artwork_name
          await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', doc.data().user_id).get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(async function (doc) {

                await artworkDeleteNotification(dataID, doc.data(), reportmessage,artworkName);
              });
            })
        }
      }))
      message.success('Successfully Deleted');
      return props.history.push({
        pathname: `/usersotherdetails`,
      });

    } catch (error) {
      message.error('Something went to wrong');
    }
  }
  const handleModerationStatusAll = async () => {
    try {
      setLoading(true)
      await Promise.all(hasSelected.map(async (data) => {
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data).update({ moderation: 'ischecked' })
      }))
      setLoading(false)
      setHasSelected([])
      message.success('Sucessfully moderation updated')
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const handleFeedChangeAll = async (flag) => {
    try {
      setLoading(true)
      await Promise.all(hasSelected.map(async (data) => {
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data).update({ post_show: flag })
      }))
      setLoading(false)
      setHasSelected([])
      message.success('Sucessfully updated')
    } catch (error) {
      setLoading(false)
      message.error('something went to wrong')
    }
  }
  return (
    <div>
      {loader == false ?
        <Card>
          <Button type="danger" hidden={!hasSelected.length > 0} onClick={() => setIsReportModal(true)}>Delete</Button>
          <Popconfirm title="Sure to moderation?" onConfirm={() => handleModerationStatusAll()}>
            <Button style={{ backgroundColor: 'green', color: 'white' }} hidden={!hasSelected.length > 0}>Moderation</Button>
          </Popconfirm>
          <Popconfirm title="Sure to feed add?" onConfirm={() => handleFeedChangeAll('Yes')}>
            <Tag icon={<CheckOutlined />} color="green" hidden={!hasSelected.length > 0}>Feed</Tag>
          </Popconfirm>
          <Popconfirm title="Sure to feed remove?" onConfirm={() => handleFeedChangeAll('No')}>
            <Tag icon={<PlusOutlined />} color="magenta" hidden={!hasSelected.length > 0}>Feed</Tag>
          </Popconfirm>
          <Modal title="Reason.." visible={isReportModal} footer={false} onCancel={() => { setIsReportModal(false);; setFieldValue(''); setTextField(false) }}>
            <p><b>why are you this post delete?</b></p><br />
            <Button onClick={() => {
              checkdelete('Violation of Community Guidelines');
              setIsReportModal(false)
            }}>
              Violation of Community Guidelines</Button>
              <Button onClick={() => {
              checkdelete('Text is visible on the artwork');
              setIsReportModal(false)
            }}>
              Text is visible on the artwork</Button>
            <Button onClick={() => {
              checkdelete('Bad Photo Quality - Artwork is cut off');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Artwork is cut off</Button>
              <Button onClick={() => {
              checkdelete('Image is upside down / sideways');
              setIsModalVisible(false)
            }}>
             Image is upside down / sideways</Button>
             <Button onClick={() => {
              checkdelete('Multiple artworks in the same photo');
              setIsModalVisible(false)
            }}>
             Multiple artworks in the same photo</Button>
             <Button onClick={() => {
              checkdelete('Bad Photo Quality - blurry / flash');
              setIsModalVisible(false)
            }}>
            Bad Photo Quality - blurry / flash</Button>
            <Button onClick={() => {
              checkdelete('Bad Photo Quality - Wall/Furniture/Person is visible Less');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Wall/Furniture/Person is visible Less</Button>
            <Button onClick={() => {
              checkdelete('Bad Photo Quality - Higher resolution required');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Higher resolution required</Button>
            <Button onClick={() => {
              checkdelete('Not an artwork');
              setIsModalVisible(false)
            }}>Not an artwork</Button>
            <Button onClick={() => {
              checkdelete('Quality of art is not sufficient');
              setIsModalVisible(false)
            }}>Quality of art is not sufficient</Button>
            <Button onClick={() => {
              checkdelete('Commercials');
              setIsReportModal(false)
            }}>Commercials</Button>
            <Button onClick={() => {
              checkdelete('Plagiarism');
              setIsReportModal(false)
            }}>Plagiarism</Button>
            <Button onClick={() => setTextField(!TextField)}
            >Other reason</Button>
            {TextField ?
              <>
                <li>
                  <div className="report-form">
                    <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                    <Button className="btn primary-btn" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? checkdelete(fieldValue)
                      : message.error("please fill the data")}>Send report</Button>
                  </div>
                </li>
              </> : null}
          </Modal>
          <Table
            className="gx-table-responsive"
            columns={columns}
            rowSelection={{ ...rowSelection }}
            rowKey={(artworkList) => artworkList.docId}
            dataSource={[...artworkList]}
            pagination={pagination}
            onChange={handleTableChange}
            footer={getFooterDetails}
          />
          <Modal title="Reason.." visible={isModalVisible} footer={false} onCancel={() => { setIsModalVisible(false);; setFieldValue(''); setTextField(false) }}>
            <p><b>why are you this post delete?</b></p><br />
            <Button onClick={() => {
              handleDelete(suspendId, 'Violation of Community Guidelines');
              setIsModalVisible(false)
            }}>
              Violation of Community Guidelines</Button>
              <Button onClick={() => {
              handleDelete(suspendId, 'Text is visible on the artwork');
              setIsModalVisible(false)
            }}>
              Text is visible on the artwork</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Bad Photo Quality - Artwork is cut off');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Artwork is cut off</Button>
              <Button onClick={() => {
              handleDelete(suspendId, 'Image is upside down / sideways');
              setIsModalVisible(false)
            }}>
             Image is upside down / sideways</Button>
             <Button onClick={() => {
              handleDelete(suspendId, 'Multiple artworks in the same photo');
              setIsModalVisible(false)
            }}>
             Multiple artworks in the same photo</Button>
             <Button onClick={() => {
              handleDelete(suspendId, 'Bad Photo Quality - blurry / flash');
              setIsModalVisible(false)
            }}>
             Bad Photo Quality - blurry / flash</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Bad Photo Quality - Wall/Furniture/Person is visible Less');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Wall/Furniture/Person is visible
              Less</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Bad Photo Quality - Higher resolution required');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Higher resolution required</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Not an artwork');
              setIsModalVisible(false)
            }}>Not an artwork</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Quality of art is not sufficient');
              setIsModalVisible(false)
            }}>Quality of art is not sufficient</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Commercials');
              setIsModalVisible(false)
            }}>Commercials</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Plagiarism');
              setIsModalVisible(false)
            }}>Plagiarism</Button>
            <Button onClick={() => setTextField(!TextField)}
            >Other reason</Button>
            {TextField ?
              <>
                <li>
                  <div className="report-form">
                    <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                    <Button className="btn primary-btn" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? handleDelete(suspendId, fieldValue)
                      : message.error("please fill the data")}>Send report</Button>
                  </div>
                </li>
              </> : null}
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      }
    </div >
  )

}
export default withRouter(UserArtworks);