import { Button, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import Service from "../../service";
import "./ExhibitionPoints.css";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const options = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
];

const ExhibitionPoints = () => {
  const [points, setpoints] = useState(0);
  const [initialPoints, setinitialPoints] = useState(0);
  const [isDisable, setIsDisabled] = useState(true);
  const [loading, setloading] = useState(false);

  const ID = localStorage.getItem("doc_Id");

  useEffect(() => {
    getPoints();
  }, []);

  const getPoints = async () => {
    try {
      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getExhibitionpoints,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          user_id: ID,
        },
      });
      if (response.data && response.data.status === 200) {
        setpoints(response.data.data.month_limit);
        setinitialPoints(response.data.data.month_limit);
        setIsDisabled(true)
      }
    } catch (error) {
      message.error("Something went wronge!");
    }
  };

  const editExhibitionPoints = async () => {
    try {
        setloading(true)
        let response = await Service.makeAPICall({
          methodName: Service.postMethod,
          api_url: Service.editExhibitionPoints,
          body: {
            check_mode: REACT_APP_PRODUCTION_MODE,
            user_id: ID,
            points:initialPoints
          },
        });
        if (response.data && response.data.status === 200) {
            message.success(response.data.message);
            await getPoints()
            setloading(false)
        }else{
            message.error(response.data.message);
            setloading(false)
        }
      } catch (error) {
        message.error("Something went wronge!");
        setloading(false)
    }
  };

  const handleChange = (value) => {
    if (points !== value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setinitialPoints(value);
  };

  return (
    <div className="exh-points-main-wrapper">
      <div className="exh-points-select">
        {/* <div> */}
        <label>Current Points:</label>
        <Select
          value={initialPoints}
          style={{
            width: 120,
          }}
          onChange={handleChange}
          options={options}
        />
        {/* </div> */}
      </div>
      <div className="exh-point-save-btn">
        <Button loading={loading} onClick={editExhibitionPoints} disabled={isDisable}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ExhibitionPoints;
