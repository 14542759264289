import React, { useState, useEffect } from "react"
import { Table, Card, Button, Input, Space, Image } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const DoneReportPostCommentList = (props) => {
    const [reportList, setReportList] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const searchInput = React.useRef(null)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [loader, setLoading] = useState(false);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination)
    }

    const getFooterDetails = () => {
        return (
            <label>
                Total Records Count is {reportList.length}
            </label>
        )
    }


    useEffect(() => {
        doneReportPostCommentListing()
    }, []);

    const doneReportPostCommentListing = async () => {
        try {
            setLoading(true)
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_comment' :'report_comment')
                .where('status', '==', true)
                .onSnapshot(async (querySnapshot) => {
                    const report = await Promise.all(querySnapshot.docs.map(async (doc) => {
                        let reportdata = doc.data();
                        reportdata.docId = doc.id;
                        const post = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' :'posts').doc(doc.data().report_post_id).get();
                        if (post.exists) {
                            reportdata.post_img = post.data().img_url
                            reportdata.report_user_id = post.data().user_id
                            const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' :'users').doc(post.data().user_id).get()
                            if (userData.exists) {
                                reportdata.user_name = userData.data()?.user_name
                            }
                        }
                        return reportdata;
                    }));
                    setReportList(report);
                });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }

    }

    const columns = [
        {
            title: 'Report Id',
            dataIndex: 'report_id',
            width: 140,
            ...getColumnSearchProps('report_id')
        },
        {
            title: 'User name',
            dataIndex: 'user_name',
            width: 150,
            ellipsis: true,
            ...getColumnSearchProps('user_name')
          },
        {
            title: 'Sender UserId',
            dataIndex: 'sender_report_user_id',
            width: 170,
            ellipsis: true,
            ...getColumnSearchProps('sender_report_user_id'),
            render: (text, record, index) =>
                reportList.length >= 1 ? (
                    <div style={{
                        display: "flex",
                        flexwrap: "wrap"
                    }}>
                        <Link to='/usersotherdetails'><span onClick={() => { localStorage.setItem('doc_Id', record.sender_report_user_id) }}>{record.sender_report_user_id}</span></Link>
                    </div>
                ) : null,
        },
        {
            title: 'Reported PostId',
            dataIndex: 'report_post_id',
            width: 180,
            ...getColumnSearchProps('report_post_id'),
            render: (text, record, index) =>
                reportList.length >= 1 ? (
                    <div style={{
                        display: "flex",
                        flexwrap: "wrap"
                    }}>
                        <Link to='/usersotherdetails'><span onClick={() => { localStorage.setItem('doc_Id', record.report_user_id) }}>{record.report_post_id}</span></Link>
                    </div>
                ) : null,
        },
        {
            title: 'Reported CommentId',
            dataIndex: 'report_comment_id',
            width: 180,
            ...getColumnSearchProps('report_post_id'),
            render: (text, record, index) =>
                reportList.length >= 1 ? (
                    <div style={{
                        display: "flex",
                        flexwrap: "wrap"
                    }}>
                        <Link to='/usersotherdetails'><span onClick={() => { localStorage.setItem('doc_Id', record.report_user_id) }}>{record.report_comment_id}</span></Link>
                    </div>
                ) : null,
        },
        {
            title: 'Reported Post Image',
            dataIndex: 'post_img',
            width: 260,
            height: 260,
            render: (text, record, id) => {

                return (
                    <div>
                        <Image width={250} height={250} src={record.post_img} />
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_deleted',
            width: 100,
            render: (text, record, id) => {

                return (
                    <div>
                        {record.is_deleted == true ?
                            <span style={{ color: 'red' }}>Done</span> : null
                        }
                    </div>
                )
            }
        },
        {
            title: 'Report Message',
            dataIndex: 'report_message',
            width: 150,
        }
    ]

    return (
        <Card>
            {loader == false ?
                <Table
                    className="gx-table-responsive"
                    columns={columns}
                    rowKey={(reportList) => reportList?.docId}
                    dataSource={[...reportList]}
                    pagination={pagination}
                    onChange={handleTableChange}
                    footer={getFooterDetails}
                />
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>
            }
        </Card>
    )
}
export default DoneReportPostCommentList;