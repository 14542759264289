import React, { useState, useEffect } from 'react'
import { Table, Button, Modal, Card, Form, DatePicker, Title, InputNumber, Popconfirm, Input, Space, message, Typography } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { DeleteOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons'
import { withRouter, useHistory } from 'react-router-dom';
import CircularProgress from "../CircularProgress/index";
import Highlighter from 'react-highlight-words';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { REACT_APP_PRODUCTION_MODE } = process.env;


const CouponCode = (props) => {

    const [couponCodeList, setCouponCodeList] = useState([])
    const [hasSelected, setHasSelected] = useState('')
    const [IsModalUpdate, setIsModalUpdate] = useState(false)
    const [EditDocId, setEditDocId] = useState(null)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const [loader, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = React.useRef(null)
    const history = useHistory()
    const [form2] = Form.useForm();

    const [CheckCodeModal, setCheckCodeModal] = useState(false)
    const [checkForm] = Form.useForm();


    const HandleClick = () => {
        history.push("/add-coupon-code")
    }


    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const onSubmit = (values) => {
        try {
            setLoading(true)
            let start_date = values.select_Date[0]
            let expiry_date = values.select_Date[1]
            if (EditDocId != null) {
                firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_coupon_code' : "coupon_code")
                    .doc(EditDocId)
                    .update({
                        coupon_code: values.coupon_code.toString(),
                        number_user: values.number_user,
                        select_month: values.select_month,
                        start_date: new Date(start_date),
                        expiry_date: new Date(expiry_date),

                    });

                setLoading(false)
                setIsModalUpdate(false)
                message.success('Successfully Created!');
            }
        } catch (error) {
            setLoading(false)
            message.error('Something went to wrong');
            console.log(error);
        }
    };

    const handleDelete = async (index) => {
        try {

            setLoading(true)
            const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_coupon_code' : "coupon_code").doc(index).get()
            if (doc.exists) {
                await doc.ref.delete(index)
            }
            setLoading(false)
            message.success('Successfully Deleted');
        } catch (error) {
            setLoading(false)
            message.error(error);
        }

    };
    const getFooterDetails = () => {
        return (
            <label>
                Total Records Count is {couponCodeList.length}
            </label>
        )
    }

    const handleTableChange = (pagination, sorter) => {
        setPagination(pagination)
    }


    useEffect(() => {
        usersListing();
    }, []);

    const usersListing = async () => {
        try {
            setLoading(true);
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_coupon_code' : 'coupon_code')
                .where('is_deleted', '==', false)
                .onSnapshot((querySnapshot) => {
                    const code = querySnapshot.docs.map((doc) => {
                        let couponCode = doc.data();
                        couponCode.docId = doc.id;
                        return couponCode;
                    });

                    setCouponCodeList(code);
                });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }

    const editCoupanModal = async (record) => {
        setEditDocId(record.docId);
        form2.setFieldsValue({
            coupon_code: record.coupon_code,
            select_Date: [moment(record.start_date.seconds * 1000), moment(record.expiry_date.seconds * 1000)],
            select_month: record.select_month,
            number_user: record.number_user

        })
        setIsModalUpdate(true)

    }

    const columns = [
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            width: 150,
            ...getColumnSearchProps('coupon_code')
        }, {
            title: 'User Applied',
            dataIndex: 'user_applied',
            width: 150,
            render: (text, record, index) => (
                <span>{record?.user_applied?.length}
                </span>

            )
        },
        {
            title: 'Total Users',
            dataIndex: 'number_user',
            width: 150,
            render: (text, record, index) => (
                <span>{record.number_user}
                </span>

            )
        },
        {
            title: 'Free Months',
            dataIndex: 'select_month',
            width: 150,
        },
        {
            title: 'Start Date',
            dataIndex: `start_date`,
            width: 150,
            render: (text, record, index) => (
                <span>{moment(record.start_date.seconds * 1000).format('DD-MM-YYYY').toString()}

                </span>

            )
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiry_date',
            width: 150,
            render: (text, record, index) => (
                <span>{moment(record.expiry_date.seconds * 1000).format('DD-MM-YYYY').toString()}</span>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (text, record, index) => (
                <div style={{
                    display: "flex",
                    flexwrap: "wrap"
                }}>
                    <Button type="link success" title="Update" onClick={() => editCoupanModal(record)}>
                        <EditOutlined style={{ fontSize: "18px" }} />
                    </Button>

                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.docId)}>
                        <Button type="link success"><DeleteOutlined style={{ fontSize: '18px' }} /></Button>
                    </Popconfirm>
                </div>
            ),
        },

    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setHasSelected([])
            setHasSelected(selectedRowKeys)

        },
        onSelect: (record, selected, selectedRows) => {

        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };

    const checkdelete = async () => {
        try {
            setLoading(true);
            for (var i = 0; i < hasSelected.length; i++) {
                const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_coupon_code' : "coupon_code").doc(hasSelected[i]).get()
                if (doc.exists) {
                    await doc.ref.delete()
                }
            }
            setLoading(false);

            message.success('Successfully Deleted');
            return props.history.push({
                pathname: `/coupon-code`,
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
            message.error('Something went to wrong');
        }
    }

    const editCouponClose = () => {
        setIsModalUpdate(false)
    }


    const checkCouponCodeSubmit = async (values) => {
        try {
            const code = values.check_couponcode;
            const coupan_data = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_coupon_code' : "coupon_code")
                .where("coupon_code", "==", code).get();

            const CouponData = await Promise.all(coupan_data.docs.map(async (doc) => {
                let coupon = doc.data();
                coupon.docId = doc.id;
                return coupon;
            }))
            checkCouponCodeValid(...CouponData);

        } catch (error) {
            console.log(error);
        }

    }

    const checkCouponCodeValid = (CouponData) => {

        // const expireyDate = moment(CouponData?.expiry_date?.seconds * 1000).format('DD-MM-YYYY').toString()
        // const date = moment(new Date(Date.now())).format('DD-MM-YYYY').toString()
        let diffInMsStartDate = new Date(CouponData?.start_date?.toDate()) - new Date(new Date().toJSON())
        const diffInDaysStart = diffInMsStartDate / (1000 * 60 * 60 * 24);

        let diffInMs = new Date(CouponData?.expiry_date?.toDate()) - new Date(new Date().toJSON());
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        CouponData === undefined ?
            message.error("Incorrect Coupon code")
            :
            diffInDaysStart > 0 ?
                message.error("Incorrect Coupon code")
                :
                diffInDays < 0 ?
                    message.error("coupon code is Expired")
                    :
                    CouponData.number_user == CouponData.user_applied.length ?
                        message.error("The Coupon is not applicable")
                        :
                        message.success("Successfully Applied")


    }

    const CouponCodeModal = () => {
        setCheckCodeModal(true)
    }

    const checkCouponClose = () => {
        setCheckCodeModal(false)
    }


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };


    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    return (
        <>
            {loader == false ?
                (<>
                    <Card title="Coupon Code">
                        <Button type="primary" style={{ float: "right" }} onClick={HandleClick}>Add Coupon</Button>
                        <Button type="primary" style={{ float: "right" }} onClick={CouponCodeModal}>Check Coupon Code</Button>
                        {hasSelected.length > 0 &&
                            <Popconfirm title="Sure to delete?" onConfirm={() => checkdelete()}>
                                <Button type="danger">
                                    Delete
                                </Button>
                            </Popconfirm>}
                        <Table
                            className="gx-table-responsive"
                            columns={columns}
                            rowSelection={{ ...rowSelection }}
                            rowKey={(couponCodeList) => couponCodeList.docId
                            }
                            dataSource={[...couponCodeList]}
                            pagination={pagination}
                            onChange={handleTableChange}
                            footer={getFooterDetails}
                        />
                    </Card>

                    <Modal
                        title="Edit Coupan Code"
                        width={900}
                        visible={IsModalUpdate === true}
                        okText="Update"
                        footer={false}
                        onCancel={editCouponClose}
                    >
                        <Form layout="vertical"
                            {...formItemLayout}
                            form={form2}
                            onFinish={onSubmit}
                        >

                            <Form.Item
                                label="Coupon Code"
                                name="coupon_code"
                                rules={[{
                                    required: true,
                                    message: 'Please enter Coupon Code!',
                                }
                                ]}

                            >

                                <Input size="large" disabled={true} />
                            </Form.Item>

                            <Form.Item
                                label="Start / Expiry Date"
                                name="select_Date"
                                rules={[{
                                    required: true,
                                    message: 'Please Choose Date!',
                                }
                                ]}

                            >
                                <RangePicker
                                    format='DD-MM-YYYY'
                                    disabledDate={(current) => {
                                        let customDate = moment().format("DD-MM-YYYY");
                                        return current && current < moment(customDate, "DD-MM-YYYY");
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Select Month"
                                name="select_month"
                                rules={[{
                                    required: true,
                                    message: 'Please Choose Month!',
                                }
                                ]}

                            >
                                <InputNumber min={1} />
                            </Form.Item>

                            <Form.Item
                                label="Select Number Of Users"
                                name="number_user"
                                rules={[{
                                    required: true,
                                    message: 'Please Choose number of users!',
                                }
                                ]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                <Button id="addbutton" type="primary" htmlType="submit">
                                    Update
                                </Button>
                            </Form.Item>

                        </Form>
                    </Modal>
                    <Modal
                        title="Check Coupan Code"
                        width={900}
                        visible={CheckCodeModal === true}
                        okText="Update"
                        footer={false}
                        onCancel={checkCouponClose}
                    >
                        <Form layout="vertical"
                            {...formItemLayout}
                            form={checkForm}
                            onFinish={checkCouponCodeSubmit}
                        >

                            <Form.Item
                                label="Enter Coupon Code"
                                name="check_couponcode"
                                rules={[{
                                    required: true,
                                    message: 'Please enter Coupon Code!',
                                }
                                ]}

                            >

                                <Input size="large" />
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                <Button id="addbutton" type="primary" htmlType="submit">
                                    Check
                                </Button>
                            </Form.Item>

                        </Form>
                    </Modal>

                </>)
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>}
        </>
    )
}



export default withRouter(CouponCode)