import React, { useState } from 'react';
import { Input, Button, InputNumber, Form, DatePicker, Radio, Card, message } from 'antd';
import { firestore, firebase } from '../../firebase/firebase'
import CircularProgress from "../CircularProgress/index";
import voucher_codes from 'voucher-code-generator'
import moment from 'moment';
const { RangePicker } = DatePicker;
const { REACT_APP_PRODUCTION_MODE } = process.env;

const AddCouponCode = (props) => {

    const [loader, setLoading] = useState(false);
    const [value, setValue] = useState(0);

    const [form2] = Form.useForm();


    const radioOnChange = e => {
        setValue(e.target.value);
        if (e.target.value === 1) {
            randomCodeGenerate();
        } else {
            form2.setFieldsValue({
                coupon_code: null,
            });
        }
    };


    const randomCodeGenerate =  () => {

        const coupon =  voucher_codes.generate({
            length: 10,
            count: 1,
            charset: voucher_codes.charset("alphanumeric")
        });
        form2.setFieldsValue({
            coupon_code: coupon,
        });

    }

    const onSubmit = (values) => {
        try {
            setLoading(true)
            let start_date = values.select_Date[0]
            let expiry_date = values.select_Date[1]
            const docRef = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_coupon_code' :'coupon_code')

            // create user object
            const couponCodeDetails = {
                coupon_code: values.coupon_code.toString(),
                start_date: new Date(start_date),
                expiry_date: new Date(expiry_date),
                select_month: values.select_month,
                is_deleted: false,
                number_user : values.number_user,
                user_applied: [],
                create_at: firebase.firestore.FieldValue.serverTimestamp(),
                update_at: firebase.firestore.FieldValue.serverTimestamp()
            };
            // write to Cloud Firestore
            docRef.add(couponCodeDetails)
            setLoading(false)
            message.success('Successfully Created!');
            return props.history.push({
                pathname: `/coupon-code`,
            });
        } catch (error) {
            setLoading(false)
            message.error('Something went to wrong');
            console.log(error);
        }
    };


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };


    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <div>
            {loader == false ?
                <Card className="gx-card" title="Add Coupon Code">
                    <Form layout="vertical"
                        {...formItemLayout}
                        form={form2}
                        onFinish={onSubmit}
                    >
                        <Form.Item name="Option"
                            label="Generate Option"
                            name="radio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Choose anyone!',
                                }
                            ]}
                        >
                            <Radio.Group onChange={radioOnChange} value={value}>
                                <Radio value={1}>Random</Radio>
                                <Radio value={2}>Manual</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Coupon Code"
                            name="coupon_code"
                            rules={value != 1 ? [{
                                required: true,
                                message: 'Please enter Coupon Code!',
                            }
                            ] : null}

                        >
                            {
                                value != 2 ?
                                    <Input size="large" disabled={true} />
                                    :
                                    <Input size="large" />
                            }
                        </Form.Item>

                        <Form.Item
                            label="Start / Expiry Date"
                            name="select_Date"
                            rules={[{
                                required: true,
                                message: 'Please Choose Date!',
                            }
                            ] }

                        >
                            <RangePicker
                                format='DD-MM-YYYY'
                                disabledDate={(current) => {
                                    let customDate = moment().format("DD-MM-YYYY");
                                    return current && current < moment(customDate, "DD-MM-YYYY");
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Select Month"
                            name="select_month"
                            rules={[{
                                required: true,
                                message: 'Please Choose Month!',
                            }
                            ]}

                        >
                            <InputNumber min={1} defaultValue={0}  />
                        </Form.Item>

                        <Form.Item
                            label="Select Number Of Users"
                            name="number_user"
                            rules={[{
                                required: true,
                                message: 'Please Choose number of users!',
                            }
                            ]}
                        >
                            <InputNumber min={1} defaultValue={0}  />
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            <Button id="addbutton" type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>

                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>}
        </div>
    );
}


export default AddCouponCode
