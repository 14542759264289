import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { firestore } from "../firebase/firebase";
import AddAdmin from "../components/AddAdmin/AddAdmin";
import AdminDetailes from "../components/AdminDetailes/AdminDetailes";
import AdminProfile from "../components/AdminProfile/AdminProfile";
import AddUsers from "../components/AddUsers/AddUsers";
import UsersDetails from "../components/Usersdetails/UsersDetails";
import UserOtherdetails from "../components/UserOtherDetails/UserOtherdetails";
import ArtworkVerification from "../components/ArtworksPending/ArtworksPending";
import BlogVerification from "../components/BlogPending/BlogPending";
import Artworkapproved from "../components/ArtworkApproved/Artworkapproved";
import BlogDetailes from "../components/BlogsDetailes/BlogDetailes";
import AddTechniques from "../components/AddTechniques/AddTechniques";
import TechniquesList from "../components/TechqinuesList/TechniquesList";
import AddBannerCards from "../components/AddBannerCard/AddBannerCards";
import AddNotification from "../components/AddNotification/AddNotification";
// import  BannerCardList  from "../components/BannerCardList/BannerCardList";
import ContestSubmission from "../components/ContestSubmission/ContestSubmission";
import AddExhibitionGalleries from "../components/AddExhibitionGalleries/AddExhibitionGalleries";
import ExhibitionGalleriesList from "../components/ExhibitionGalleriesList/ExhibitionGalleriesList";
import AddPartnerGallary from "../components/AddPartnerGallary/AddPartnerGallary";
import ExhibitionPartnerGalleriesList from "../components/ExhibitionPartnerGalleriesList/ExhibitionPartnerGalleriesList";
import ReportDetails from "../components/ReportDetails/ReportDetails";
import ExhibitionArtwork from "../components/ExhibitionArtwork/ExhibitionArtwork";
import ExhibitionGallaryArtworks from "../components/ExhibitionGallaryArtworks/ExhibitionGallaryArtworks";
// import ExhibitionPhotographList from "../components/ExhibitionPhotographList/ExhibitionPhotographList";
import AddExhibitionPhotograph from "../components/AddExhibitionPhotograph/AddExhibitionPhotograph";
import ContentList from "../components/ContentList/ContentList";
import AddContest from "../components/AddContest/AddContest";
import CmsPages from "../components/CmsPages/CmsPages";
import AddCouponCode from '../components/AddCouponCode/AddCouponCode'
import CouponCode from '../components/CouponCode/CouponCode'
import UserNotification from "../components/UserNotification/UserNotification";
import AddPopImage from "../components/AddPopImage/AddPopImage";
// import ExhibitionPhotoCards from "../components/ExhibitionPhotographList/ExhibitionPhotoCards";
import PhotographyListNew from "../components/ExhibitionPhotographyListNew/PhotographyListNew";

const { REACT_APP_PRODUCTION_MODE } = process.env;

const App = ({ match }) => {
  const [userDocummnet, setUserDocument] = useState();

  useEffect(() => {
    const docRef = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin').doc(localStorage.getItem('user_id'));
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        setUserDocument(documentData);

      }
    });
    return unsubscribe;
  }, []);

  return (

    <div className="gx-main-content-wrapper">
      <Switch>
        {userDocummnet?.isAdmin === "SuperAdmin" ?
          <Route path={`${match.url}add-admin`} component={AddAdmin} />
          : null}
        {userDocummnet?.isAdmin === "SuperAdmin" ?
          <Route path={`${match.url}admindetails`} component={AdminDetailes} />
          : null}
        <Route path={`${match.url}adminprofile`} component={AdminProfile} />

        <Route path={`${match.url}addusers`} component={AddUsers} />
        <Route path={`${match.url}usersdetails`} component={UsersDetails} />
        <Route path={`${match.url}usersotherdetails`} component={UserOtherdetails} />
        <Route path={`${match.url}artworksverification`} component={ArtworkVerification} />
        <Route path={`${match.url}blogsverification`} component={BlogVerification} />
        <Route path={`${match.url}artworksapproved`} component={Artworkapproved} />
        <Route path={`${match.url}blogs`} component={BlogDetailes} />
        <Route path={`${match.url}reportlist`} component={ReportDetails} />
        <Route path={`${match.url}addtechniques`} component={AddTechniques} />
        <Route path={`${match.url}techniqueslist`} component={TechniquesList} />
        <Route path={`${match.url}banner-card`} component={AddBannerCards} />
        <Route path={`${match.url}pop-image`} component={AddPopImage} />
        <Route path={`${match.url}add-coupon-code`} component={AddCouponCode} />
        <Route path={`${match.url}coupon-code`} component={CouponCode} />
        <Route path={`${match.url}send-notifications`} component={AddNotification} />
        <Route path={`${match.url}add-exhibition-galleries`} component={AddExhibitionGalleries} />
        <Route path={`${match.url}exhibition-galleries-list`} component={ExhibitionGalleriesList} />
        <Route path={`${match.url}contest-list`} component={ContentList}/>
        <Route path={`${match.url}contest-submission-list`} component={ContestSubmission}/>
        <Route path={`${match.url}add-contest`} component={AddContest}/>
        <Route path={`${match.url}exhibition-artworks`} component={ExhibitionArtwork} />
        <Route path={`${match.url}exhibition-artworks-image`} component={ExhibitionGallaryArtworks} />  
        {/* <Route path={`${match.url}exhibition-photograph-list`} component={ExhibitionPhotographList} /> */}
        {/* <Route path={`${match.url}exhibition-photography-cards`} component={ExhibitionPhotoCards} /> */}

        <Route path={`${match.url}exhibition-photography-list`} component={PhotographyListNew} />

        <Route path={`${match.url}add-exhibition-photograph`} component={AddExhibitionPhotograph} />
        <Route path={`${match.url}add-exhibition-partner-galleries`} component={AddPartnerGallary} />
        <Route path={`${match.url}exhibition-partner-galleries-list`} component={ExhibitionPartnerGalleriesList} />
        <Route path={`${match.url}cms-pages`} component={CmsPages} />
        <Route path={`${match.url}user-notifications`} component={UserNotification} />
       


      </Switch>
    </div>
  )
}

export default App;
