import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Button, Form, Card, Space, Upload, Modal, message } from 'antd';
import { firestore, firebase, storage } from '../../firebase/firebase'
import CircularProgress from "../CircularProgress/index";
import { PlusOutlined } from "@ant-design/icons"
const { REACT_APP_PRODUCTION_MODE } = process.env;

const AddTechniques = (props) => {
  const [loader, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);
  const { register } = useForm();
  const [form2] = Form.useForm();
  const techniquestype = form2.getFieldValue('techniques_type')

  const validator = async (rule, value) => {
    if (rule.field === 'image_url' && value) {
      if (file == null) {
        return Promise.reject('Please enter your techniques image!');
      }
      else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  }
  const createUserDocument = async (values) => {
    // get a reference to the Firestore document
    setLoading(true)
    const url = await customUpload()
    values.image_url = url
    const docRef = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : 'home_categories')
    // create user object
    const addtechniques = {
      title: values.techniques_type,
      route_path: values.techniques_type.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, ''),
      techniques_type: values.techniques_type,
      image_url: values.image_url,
      type: 'techniques',
      create_at: firebase.firestore.FieldValue.serverTimestamp(),
      update_at: firebase.firestore.FieldValue.serverTimestamp()
    };
    // write to Cloud Firestore
    setLoading(false)
    return docRef.add(addtechniques)
  };


  const onSubmit = async (values) => {
    try {
      await createUserDocument(values);
      message.success('Successfully Add Techniques ');
      return props.history.push({
        pathname: `/techniqueslist`,
      });
    }
    catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }

  };


  // function beforeUpload(file) {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 5;
  //   if (!isLt2M) {

  //     message.error('Image must smaller than 5MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview) 
    setPreviewImage(true)

  }

  const handleChange = (info) => {

  }

  const customUpload = () => {
    return (new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: 'image/jpeg'
        }
        //  const file = fileList==''?userDocument?.profile_url:fileList
        const storageRef = storage.ref();
        const fileName = form2.getFieldValue('techniques_type')
        const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_home_categories/${fileName}/technique-image` : `home_categories/${fileName}/technique-image`).put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(techniquestype).then((url) => {
              if (!!url) {

                setImageUrl(url)
                resolve(url);
              }
            })
          }
        );

      } catch (e) {
        console.log(e);
        reject(e);
      }
    }))
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };


  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const uploadButton = (
    <div>
      <PlusOutlined type={loader ? 'loading' : 'plus'} />
    </div>
  )

  // init function

  return (
    <div>

      {loader == false ?
        <Card title="Add Techniques">
          <Form layout="vertical"
            {...formItemLayout}
            form={form2}
            onFinish={onSubmit}
          >
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>



            {/* <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter your title!',
                }
              ]}
            >
              <Input size="large" ref={register} />
            </Form.Item> */}

            <Form.Item
              label="Techniques Type"
              name="techniques_type"
              rules={[
                {
                  required: true,
                  message: 'Please enter your techniques type!',
                },
                { max: 30, message: 'Techniques type must be maximum 30 characters.' },
              ]}
            >
              <Input size="large" ref={register} />
            </Form.Item>
            <Form.Item
              label="Techniques Image"
              name="image_url"
              rules={[
                {
                  required: true,
                  message: 'Please enter your techniques image!',
                }, { validator: validator }
              ]}
            >
              <Space direction="vertical" size={12}>
                <Upload
                  name="image_url"
                  listType="picture-card"
                  maxCount={1}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  onRemove={() => setFile(null)}
                  customRequest={({ onSuccess, file }) =>
                  (onSuccess('ok'),
                    setFile(file))}
                >
                  {imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
                </Upload>
              </Space>

            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button id="addbutton" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>

          </Form>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={image} />
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
    </div>
  );
}

export default AddTechniques;
