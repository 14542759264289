import React, { useState, useEffect, useCallback } from "react"
import { Input, Card, Modal, Button, Row, Col, Form, Select, Space, Upload, Radio, TimePicker, DatePicker, Switch, Carousel, Popconfirm, message } from 'antd';
import { firestore, firebase, storage } from "../../firebase/firebase";
import { useForm } from 'react-hook-form';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ShowMoreText from 'react-show-more-text';
import moment from "moment";
import { Link } from "react-router-dom";
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const { Meta } = Card
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

const ExhibitionPartnerGalleriesList = (props) => {
    const [initialValues, setInitialValues] = useState(null)
    const [IsModalVisible, setIsModalVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState(false);
    const [image, setImage] = useState(false);
    const [galleries, setGalleries] = useState([])
    const [fileList, setFileList] = useState([]);
    const [removeFileList, setRemoveFileList] = useState([]);
    const [imageUrlArr, setImageUrlArr] = useState([])
    const [date, setDate] = useState(null);
    const [loader, setLoading] = useState(false);
    const { register } = useForm();
    const [form2] = Form.useForm();
    const [exhibitionPeriod, setExhibitionPeriod] = useState("");
    const [time, setTime] = useState(null);
    const { Option } = Select;

    useEffect(() => {
        try {
            setLoading(true)
            firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : 'partner_galleries')
                .orderBy('create_at', 'desc')
                .onSnapshot((querySnapshot) => {
                    const galleries = querySnapshot.docs.map((doc) => {
                        let userdata = doc.data();
                        userdata.docId = doc.id;
                        return userdata;
                    });
                    setGalleries(galleries);
                });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }

    }, []);

    const validator = async (rule, value) => {
        if (rule.field === 'exhibition_time_period') {

            if (time == null) {
                return Promise.reject('Please input your exhibition time period');
            }
            else {
                return Promise.resolve();
            }
        }
        if (rule.field === 'exhibition_period') {

            if (date == null) {
                return Promise.reject('Please input your exhibition period');
            }
            else {
                return Promise.resolve();
            }
        }
        else {
            return Promise.resolve();
        }
    }


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => (resolve(reader.result), setImage(reader.result));
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        // setImage(file.url || file.preview) 
        setPreviewImage(true)

    }

    const customUpload = (partnerImage, partnerGallaryId) => {
        return (new Promise(async (resolve, reject) => {
            try {
                if (fileList == []) {
                    return resolve([]);
                }
                const metadata = {
                    contentType: 'image/jpeg'
                }
                const storageRef = storage.ref();
                const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_partner_galleries/${partnerGallaryId}/${new Date()}` : `partner_galleries/${partnerGallaryId}/${new Date()}`).put(partnerImage, metadata);
                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                // console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                // console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        uploadTask.snapshot.ref.getDownloadURL(partnerGallaryId).then((url) => {
                            if (!!url) {
                                resolve(url);
                            }
                        })
                    }
                );

            } catch (e) {
                console.log(e);
                reject(e);
            }
        }))
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLtM = file.size / 1024 / 1024 < 5;
        if (!isLtM) {
            message.error("Image must smaller than 5MB!");
        }
        return isJpgOrPng;
    };

    const handleChange = async (info) => {
        if (info.file.status === "uploading") {
            info.file.status = "done";
        }
        if (info.file.status === "done") {
            setFileList(info.fileList);
        }
    };

    const uploaderProps = {
        name: "file",
        listType: "picture-card",
        accept: "image/*",
        multiple: true,
        maxCount: 3,
        fileList: fileList,
        onPreview: handlePreview,
        beforeUpload: beforeUpload,
        onChange: handleChange,
        customRequest: async ({ onSuccess, file }) => {
            // const compressedFile = await getCompressedFile(file);
            imageUrlArr.push({
                file,
                // compressedFile: compressedFile,
            });
            setImageUrlArr([...imageUrlArr]);
            onSuccess("ok");
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            if (fileList[index].url?.includes('firebasestorage')) {
                setRemoveFileList([...removeFileList, fileList[index].name]);
            }
            const index_2 = imageUrlArr.findIndex(
                (item) => item.file?.uid === file.uid || item.uid === file.uid
            );
            if (index_2 > -1) {
                imageUrlArr.splice(index_2, 1);
                setImageUrlArr([...imageUrlArr]);
            }
            fileList.splice(index, 1);
            setFileList([...fileList]);
        },
    };

    const disabledDate = (current) => {
        return current && current < moment().subtract(1, 'days');
    };

    const uploadButton = (
        <Upload {...uploaderProps}>
            {initialValues?.gallary_image >= 3 ? initialValues?.gallary_image.map((image, index) => (
                <img src={image} key={index} />
            )) :
                fileList.length < uploaderProps.maxCount && "+ Upload"}
        </Upload>
    );

    const currentMonthData = (value) => {
        setExhibitionPeriod(value);
        const date = new Date();
        const firstDay = moment(
            new Date(date.getFullYear(), date.getMonth() + 1, 1)
        )
            .format("Do-MMMM-YYYY")
            .toString();
        const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0))
            .format("Do-MMMM-YYYY")
            .toString();
        setDate([firstDay, lastDay]);
    };

    const onSubmit = async (values) => {
        try {
            let gallary_image = [];
            for (const iterator of fileList) {
                if (iterator.url) {
                    gallary_image.push(iterator.url);
                }
            }
            // console.log('gallary_image', gallary_image);
            // console.log('removeFileList', removeFileList);
            // console.log('imageUrlArr', imageUrlArr);
            setLoading(true);
            const imageData = imageUrlArr.slice(0, 3)
            for (const imagePartner of imageData) {
                const url = await customUpload(imagePartner?.file, values.docId);
                gallary_image.push(url);
            }

            //Remove func here

            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : "partner_galleries").doc(values.docId).update({
                gallary_name: values.gallary_name.toUpperCase(),
                exhibition_start_period: date[0].split(" ")[0],
                exhibition_end_period: date[1].split(" ")[0],
                exhibition_start_time: time[0].split(" ")[0],
                exhibition_end_time: time[1].split(" ")[0],
                unlimited_exhibition: exhibitionPeriod == 'Unlimited' ? true : false,
                address: values.address,
                sunday: values.sunday,
                monday: values.monday,
                tuesday: values.tuesday,
                wednesday: values.wednesday,
                thursday: values.thursday,
                friday: values.friday,
                saturday: values.saturday,
                gallary_image,
                caption: values.caption,
                update_at: firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true })
            setIsModalVisible(false);
            setLoading(false);
            message.success('Successfully Update data');
            return props.history.push({
                pathname: `exhibition-partner-galleries-list`,
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
            message.error('Something went to wrong');
        }
    };


    const handleCancel = useCallback(() => {
        form2.resetFields();
        setDate(null)
        setIsModalVisible(false);
    }, [form2]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const openUserModal = (values) => {

        setExhibitionPeriod('')
        setDate([])
        setTime([])
        setExhibitionPeriod(values.unlimited_exhibition == true ? "Unlimited" : 'Exhibition Date Set')
        setDate([values.exhibition_start_period, values.exhibition_end_period]);
        setTime([values.exhibition_start_time, values.exhibition_end_time]);
        setInitialValues(values)
        setFileList(values.gallary_image.map((item) => {
            return {
                uid: uuidv4(),
                name: item,
                status: 'done',
                url: item
            };
        }));
        form2.setFieldsValue(values);
        setIsModalVisible(true)
    }

    const executeOnClick = (isExpanded) => {

    }

    const exGalleryStartEnd = async (e, gallaryDoc) => {
        if (e == true) {
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : "partner_galleries").doc(gallaryDoc).update({ exhibition_current: 'on' })
        } else {
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : "partner_galleries").doc(gallaryDoc).update({ exhibition_current: 'off' })
        }
    }

    const partnerGalleryDelete = async (gallaryId) => {
        try {
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_partner_galleries' : "partner_galleries").doc(gallaryId).delete();
            message.success('Successfully Delete data');
        } catch (error) {
            message.error('Something went to wrong');
            console.log(error);
        }

    }

    return (
        <>
            {loader == false ?
                <Card title="Partner Galleries">
                    <div style={{ textAlign: "right", paddingBottom: "10px" }}>
                        <Link to="/add-exhibition-partner-galleries"><Button type="primary">Add Partner Gallery</Button></Link>
                    </div>
                    <div className="gx-main-content">
                        {galleries.length == 0 ? <div style={{ textAlign: 'center', width: '100%', display: 'block' }}>No Data</div> :
                            <Row>
                                {galleries.map((gallary, index) => (
                                    <Col xl={8} lg={12} md={12} sm={24} xs={12} key={index}>
                                        <Card
                                            className="gallery-list"
                                            hoverable
                                            cover={<Carousel autoplay>
                                                {gallary.gallary_image.map((img, index) => (
                                                    <img alt="image" src={img} key={index} />
                                                ))}
                                                {/* <img alt="image" src={gallary.gallary_image} /> */}
                                            </Carousel>}
                                            actions={[
                                                <Button type="link success" onClick={() => {
                                                    openUserModal(gallary);
                                                }}>
                                                    <EditOutlined key="edit" />
                                                </Button>,
                                                <Popconfirm title="Sure to delete?" onConfirm={() => {
                                                    partnerGalleryDelete(gallary.docId);
                                                }}>
                                                    <Button type="link success"
                                                    >
                                                        <DeleteOutlined key='delete' />
                                                    </Button>
                                                </Popconfirm>,
                                                <Switch defaultChecked={gallary.exhibition_current == 'on' ? true : false} checkedChildren="on" unCheckedChildren="off" onChange={(value) => exGalleryStartEnd(value, gallary.docId)} />
                                            ]}
                                        >
                                            <Meta title={gallary.gallary_name} style={{ paddingBottom: "8px" }} />
                                            <Meta description={`${gallary.exhibition_start_period} to ${gallary.exhibition_end_period}`} style={{ paddingBottom: "8px" }} />
                                            <Meta description={`${gallary.exhibition_start_time} to ${gallary.exhibition_end_time}`} style={{ paddingBottom: "8px" }} />
                                            <Meta description={`${gallary.address}`} style={{ paddingBottom: "8px" }} />
                                            <ShowMoreText
                                                more='Show more'
                                                less='Show less'
                                                anchorClass='my-anchor-css-class'
                                                onClick={() => executeOnClick()}
                                                expanded={false}
                                                width={1}
                                            >
                                                <Meta description={gallary.caption} />
                                            </ShowMoreText>
                                        </Card>
                                    </Col>))}
                            </Row>
                        }
                    </div>

                    <Modal
                        title="Update Partner Gallery"
                        visible={IsModalVisible}
                        okText="Update"
                        onOk={false}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <Form layout="vertical"
                            {...formItemLayout}
                            form={form2}
                            onFinish={onSubmit}
                            initialValues={{ ...initialValues }}
                        >

                            <Form.Item name="docId" hidden={true}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Gallary Name"
                                name="gallary_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your gallary name',
                                    }
                                ]}
                            >
                                <Input ref={register} />
                            </Form.Item>

                            <Form.Item
                                label="Exhibition Period"
                                name="exhibition_period"
                                rules={[{ validator: validator }]}
                            >
                                <Select
                                    size="large"
                                    defaultValue={exhibitionPeriod == 'Unlimited' ? 'Unlimited' : 'Exhibition Date Set'}
                                    onSelect={(value) =>
                                        value == "Unlimited"
                                            ? currentMonthData(value)
                                            : setExhibitionPeriod(value) || setDate(null)
                                    }
                                >
                                    <Option value="Exhibition Date Set">Exhibition Date Set</Option>
                                    <Option value="Unlimited">Unlimited</Option>
                                </Select>
                                <div style={{ paddingTop: 10 }}>
                                    {exhibitionPeriod == "Unlimited" ? (
                                        <span>
                                            Exhibition Peroid : {date?.[0] + " to " + date?.[1]}
                                        </span>
                                    ) : null}
                                    {exhibitionPeriod == "Exhibition Date Set" ? (
                                        <span>
                                            <RangePicker
                                                style={{ width: 400 }}
                                                format="Do-MMMM-YYYY"
                                                disabledDate={disabledDate}
                                                defaultValue={exhibitionPeriod == 'Exhibition Date Set' && initialValues.unlimited_exhibition == false ? [moment(date?.[0], 'Do-MMMM-YYYY'), moment(date?.[1], 'Do-MMMM-YYYY')] : null}
                                                onChange={(value, dateString) => {
                                                    setDate(value == null ? null : dateString);
                                                }}
                                            />
                                        </span>
                                    ) : null}
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Exhibition Time Period"
                                name="exhibition_time_period"
                                rules={[{ validator: validator }]}
                            >
                                <TimePicker.RangePicker
                                    style={{ width: 400 }}
                                    format="hh:mm-A"
                                    defaultValue={[moment(time?.[0], "hh:mm-A"), moment(time?.[1], "hh:mm-A")]}
                                    onChange={(value, dateString) => {
                                        setTime(value == null ? null : dateString);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Days Select  :"
                                name="days"
                            >
                                <Form.Item
                                    label="Sunday :"
                                    name="sunday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Monday :"
                                    name="monday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Tuesday :"
                                    name="tuesday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Wednesday :"
                                    name="wednesday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Thursday :"
                                    name="thursday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Friday :"
                                    name="friday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Saturday :"
                                    name="saturday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select days open & close',
                                        }
                                    ]}
                                >
                                    <Radio.Group onChange={(e) => console.log(e.target.value)}>
                                        <Space direction="horizontal">
                                            <Radio value='open'>Open</Radio>
                                            <Radio value='close'>Close</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your gallary address',
                                    }
                                ]}
                            >
                                <TextArea rows={4} ref={register} />
                            </Form.Item>

                            <Form.Item
                                label="Gallary Caption"
                                name="caption"
                            >
                                <TextArea rows={4} ref={register} />
                            </Form.Item>

                            <Form.Item
                                label="Exhibition Gallary Image"
                                name="gallary_image"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your gallary image',
                                    }
                                ]}
                            >
                                <Space direction="vertical" size={12}>
                                    {uploadButton}
                                </Space>

                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button id="addbutton" type="primary" htmlType="submit">
                                    Update
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        visible={previewImage}
                        footer={null}
                        onCancel={() => setPreviewImage(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={image} />
                    </Modal>
                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>}
        </>

    )

}
export default ExhibitionPartnerGalleriesList;