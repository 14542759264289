import React, { useState, useEffect } from "react"
import { Table, Card, Button, Input, Space, message } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import { Link, withRouter } from "react-router-dom";
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const ReportUserList = (props) => {
    const [reportUserList, setReportUserList] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })
    const searchInput = React.useRef(null)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [hasSelected, setHasSelected] = useState([])
    const [loader, setLoading] = useState(false);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination)
    }

    const getFooterDetails = () => {
        return (
            <label>
                Total Records Count is {reportUserList.length}
            </label>
        )
    }


    useEffect(() => {
        reportUserListing();
    }, []);

    const reportUserListing = async () => {
        try {
            setLoading(true)
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_user' : 'report_user')
                .where('status', '==', false)
                .orderBy('create_at', 'desc')
                .onSnapshot(async (querySnapshot) => {
                    const report = await Promise.all(querySnapshot.docs.map(async (doc) => {
                        let reportdata = doc.data();
                        reportdata.docId = doc.id;
                        const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(reportdata.report_user_id).get()
                        if (userData.exists) {
                            reportdata.user_name = userData.data()?.user_name
                        }
                        return reportdata;
                    }));
                    setReportUserList(report);
                });
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)

        }

    }

    const updateReportUser = async (reportUserId) => {
        try {
            setLoading(true)
            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_user' : "report_user").doc(reportUserId).update({ is_deleted: true, status: true, update_at: firebase.firestore.FieldValue.serverTimestamp() })
            props.history.push({
                pathname: 'reportlist'
            })
            setLoading(false)
            message.success('Successfully Updated');
        } catch (error) {
            setLoading(false)
            message.error('Something went to wrong');
            console.log(error);
        }
    }

    const columns = [
        {
            title: 'Report Id',
            dataIndex: 'report_id',
            width: 150,
            ...getColumnSearchProps('report_id')
        },
        {
            title: 'User name',
            dataIndex: 'user_name',
            width: 150,
            ellipsis: true,
            ...getColumnSearchProps('user_name')
        },
        {
            title: 'Sender User',
            dataIndex: 'sender_report_user_id',
            width: 200,
            ellipsis: true,
            ...getColumnSearchProps('sender_report_user_id'),
            render: (text, record, index) =>
                reportUserList.length >= 1 ? (
                    <div style={{
                        display: "flex",
                        flexwrap: "wrap"
                    }}>
                        <Link to='/usersotherdetails'><span onClick={() => { localStorage.setItem('doc_Id', record.sender_report_user_id) }}>{record.sender_report_user_id}</span></Link>
                    </div>
                ) : null,
        },
        {
            title: 'Report UserId',
            dataIndex: 'report_user_id',
            width: 200,
            ...getColumnSearchProps('report_user_id'),
            render: (text, record, index) =>
                reportUserList.length >= 1 ? (
                    <div style={{
                        display: "flex",
                        flexwrap: "wrap"
                    }}>
                        <Link to='/usersotherdetails'><span onClick={() => { localStorage.setItem('doc_Id', record.report_user_id) }}>{record.report_user_id}</span></Link>
                    </div>
                ) : null,
        },
        {
            title: 'Action',
            dataIndex: 'is_deleted',
            width: 100,
            render: (text, record, id) => {

                return (
                    <div>
                        <Button type='primary' onClick={() => updateReportUser(record.docId)}>Done</Button>
                    </div>
                )
            }
        },
        {
            title: 'Report Message',
            dataIndex: 'report_message',
            width: 150,
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setHasSelected([])
            setHasSelected(selectedRowKeys)
        },
        onSelect: (record, selected, selectedRows) => {

        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        },
    };

    const allUpdateUserReport = async () => {
        try {
            setLoading(true)
            for (var i = 0; i < hasSelected.length; i++) {
                await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_user' : "report_user").doc(hasSelected[i]).update({ is_deleted: true, status: true, update_at: firebase.firestore.FieldValue.serverTimestamp() })
            }
            setLoading(false)
            props.history.push({
                pathname: 'reportlist'
            })
            message.success('Successfully Updated');
        } catch (error) {
            setLoading(false)
            message.error('Something went to wrong');
            console.log(error);
        }
    }

    return (
        <>
            {loader == false ?
                <Card>
                    {hasSelected.length > 0 &&
                        <Button type='primary' onClick={allUpdateUserReport}>
                            Done
                        </Button>
                    }
                    <Table
                        className="gx-table-responsive"
                        columns={columns}
                        rowSelection={{ ...rowSelection }}
                        rowKey={(reportUserList) => reportUserList.docId}
                        dataSource={[...reportUserList]}
                        pagination={pagination}
                        onChange={handleTableChange}
                        footer={getFooterDetails}
                    />
                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>
            }
        </>
    )
}
export default withRouter(ReportUserList);