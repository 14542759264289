import React, { useState, useEffect, useCallback } from "react";
import { Input, Card, Modal, Button, Row, Col, Form, Space, Upload, DatePicker, Select, Switch, Carousel, message, Radio, Popconfirm } from 'antd';
import { firestore, firebase, storage } from "../../firebase/firebase";
import { useForm } from 'react-hook-form';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ShowMoreText from 'react-show-more-text';
import moment from "moment";
import Service from "../../service";
import { Link } from "react-router-dom";
import CircularProgress from "../CircularProgress/index";
import { toInteger } from 'lodash-es';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const { Option } = Select
const { Meta } = Card
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { REACT_APP_PRODUCTION_MODE } = process.env;




const ContentList = (props) => {
  const [initialValues, setInitialValues] = useState(null)
  const [IsModalVisible, setIsModalVisible] = useState(false)
  const [isWinner, setIswinner] = useState(false)
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);
  const [submissionDate, setSubmissionDate] = useState([]);
  const [contests, setContests] = useState([])
  const [contestType, setContestType] = useState("standard");
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState(null);
  const [date, setDate] = useState([]);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [loader, setLoading] = useState(false);
  const [exhibitionPeriod, setExhibitionPeriod] = useState('');
  const { register } = useForm();
  const [form2] = Form.useForm();
  const contest_name = form2.getFieldValue('contest_name')
  const dateFormat = 'DD-MM-YYYY';

  const handleCancel = useCallback(() => {
    form2.resetFields();
    setDate([])
    setSubmissionDate([])
    setIsModalVisible(false);
    setExhibitionPeriod('');
  }, [form2]);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  useEffect(()=>{
    ContestListing()
  },[])
  const ContestListing = async () => {
    try {
      setLoading(true);
      // setContests([]);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getContestList,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          // check_mode:'development',
         },
      });
      if (response.data.status) {
        setContests(response.data.data)
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };


console.log(contests,'contests')
  
  const onChange = (e) => {
    setContestType(e.target.value);
  };

  const onSubmit = async (values) => {
    try {
      console.log("🚀 ~ onSubmit ~ values:", values)
      setLoading(true);
      const url = await customUpload();
      let submission_start_date = values.submission_period[0]
      let submission_end_date = values.submission_period[1]
      values.contest_image = file == null ? values.contest_image : url
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : "contest").doc(values.docId).update({
        contest_name: values.contest_name,
        contest_description: values.contest_description,
        link_details: values.link_details,
        link_photos: values.link_photos,
        is_deleted : false,
        is_winner : isWinner,
        winner_2: values.winner_2,
        winner_1: values.winner_1,
        exhibition_start_period:moment(date[0]).hours("00").minute("00").second("00").valueOf(),
        exhibition_end_period: moment(date[1]).hours("23").minute("55").second("00").valueOf(),
        submission_start_period: moment(submissionDate[0]).hours("00").minute("00").second("00").valueOf(),
        submission_end_period: moment(submissionDate[1]).hours("23").minute("55").second("00").valueOf(),
        contest_image: values.contest_image,
        contest_type: contestType,
        update_at: firebase.firestore.FieldValue.serverTimestamp()
      })
      setIsModalVisible(false);
      setLoading(false);
      message.success('Successfully Update data');
      await ContestListing();
      return props.history.push({
        pathname: `contest-list`,
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error('Something went to wrong');
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview) 
    setPreviewImage(true)

  }

  const handleChange = (info) => {

  }
  const uploadButton = (
    <div>
      <PlusOutlined type={loader ? "loading" : "plus"} />
    </div>
  );
  const customUpload = () => {
    return (new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: 'image/jpeg'
        }
        const fileName = form2.getFieldValue('contest_name')
        console.log("🚀 ~ return ~ fileName:", fileName)
        const storageRef = storage.ref();
        const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_exhibition-galleries/${fileName}/gallary-image` : `exhibition-galleries/${fileName}/gallary-image`).put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(contest_name).then((url) => {
              if (!!url) {
                resolve(url);
              }
            })
          }
        );

      } catch (e) {
        console.log(e);
        reject(e);
      }
    }))
  };
//  useEffect(() => {
//     try {
//       setLoading(true)
//       firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : 'contest').where("is_deleted", "==" ,false)
//         .orderBy('create_at', 'desc')
//         .onSnapshot((querySnapshot) => {
//           const contests = querySnapshot.docs.map((doc) => {
//             let userdata = doc.data();
//             userdata.docId = doc.id;
//             return userdata;
//           });
//           setContests(contests);
//         });
//       setLoading(false)
//     } catch (error) {
//       setLoading(false)
//       console.log(error);
//     }

//   }, []);
  const handleClick = (id) => {
    props.history.push({
      pathname: '/contest-submission-list',
    })
    localStorage.setItem("contestId", id)
  }
  const validator = async (rule, value) => {
    if (rule.field === 'gallary_name') {
      if (value && !(/^[a-zA-Z0-9 ]+$/.test(value))) {
        return Promise.reject('Please remove special character');
      } else {
        return Promise.resolve();
      }
    }
    if (rule.field === 'exhibition_period') {

      if (date ==  [] ) {
        return Promise.reject('Please input your exhibition period');
      }
      else if (moment(date[0]).valueOf() < moment(submissionDate[1]).valueOf()) {
        return Promise.reject('Your exhibition Start Date can not be less than Submission end date')
      }
      else {
        return Promise.resolve();
      }
    }




    else {
      return Promise.resolve();
    }

  }

  const currentMonthData = (value) => {
    setExhibitionPeriod(value)
    const date = new Date();
    const firstDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('Do-MMMM-YYYY').toString();
    const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0)).format('Do-MMMM-YYYY').toString();
    const monthData = moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('MMMM');
    const yearData = moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('YYYY');
    setDate([firstDay, lastDay]);
    setMonth(monthData)
    setYear(yearData)
  }


  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const openUserModal = (values) => {
    setDate([values.exhibition_start_period, values.exhibition_end_period]);
    setSubmissionDate([values.submission_start_period, values.submission_end_period])
    setInitialValues(values)
    form2.setFieldsValue(values);
    setIsModalVisible(true)
    setContestType(values.contest_type)
    setIswinner(values?.is_winner)
  }
  const executeOnClick = (isExpanded) => {

  }

  const exGalleryStartEnd = async (e, gallaryDoc) => {
    if (e == true) {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : "contest").doc(gallaryDoc).update({ contest_current: 'on', is_add: true })
      message.success('Successfully Update data');
    } else {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : "contest").doc(gallaryDoc).update({ contest_current: 'off', is_add: false })
      message.success('Successfully Update data');
    }
  }

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days');
  };
  const ContestDelete = async (contestId) => {
    try {
      const checkuser = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').where("contests", "array-contains", contestId).onSnapshot((querySnapshot) => {
        const contests = querySnapshot.docs.map((doc) => {
          let userdata = doc.id;
          return userdata;
        })
        for (const item of contests) {
          const userDelete = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(item);
          userDelete.update({
            contests: firebase.firestore.FieldValue.arrayRemove(contestId)
          })

        }
      })
      const checkartwork = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').where("contests", "array-contains", contestId).onSnapshot((querySnapshot) => {
        const contests = querySnapshot.docs.map((doc) => {
          let userdata = doc.id;
          return userdata;
        })
        for (const item of contests) {
          const artworkDelete = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(item);
          artworkDelete.update({
            contests: firebase.firestore.FieldValue.arrayRemove(contestId)
          })

        }
      })
      const checkContestArtwork = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest_artworks' : 'contest_artworks').where("contest_id", "==", contestId).onSnapshot((querySnapshot) => {
        const contestArtwork = querySnapshot.docs.map((doc) => {
          let contestData = doc.id;
          return contestData;
        })
        for (const item of contestArtwork) {
          const contestArtworkDelete = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest_artworks' : 'contest_artworks').doc(item).delete()

        }
      })
      const deleteContest = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : 'contest').doc(contestId)
      deleteContest.update({
        is_deleted: true
      })


      message.success('Successfully Delete data');

    } catch (error) {
      message.error('Something Went to Wrong');
      console.log(error);

    }
  }


  return (
    <>
      {loader == false ?
        <Card title="Contests">
          <div style={{ textAlign: "right", paddingBottom: "10px" }}>
            <Link to="/add-contest"><Button type="primary">Add Contest</Button></Link>
          </div>
          <div className="gx-main-content">
            {contests.length == 0 ? <div style={{ textAlign: 'center' }}>No Data</div> :
              <Row>
                {contests.map((contest, index) => (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24} key={index} className="contest-card">
          

                    <Card
                      className="gallery-list"
                      hoverable
                      cover={
                        // <Carousel autoplay>
                        //   <img alt="image" src={contest.contest_image} />
                        // </Carousel>
                      <LazyLoadImage
                      alt="image"
                      src={contest.contest_image}
                      effect="blur"
                      threshold={300}
                      sizes="(max-width: 300px) 100vw, (max-width: 768px) 50vw, 25vw"
                  />  
                      }
                    >
                      <div className="data-content">
                        <Meta title={contest.contest_name} style={{ paddingBottom: "8px" }} />
                        {/* <Meta description={`${gallary.city}/${gallary.country}`} style={{ paddingBottom: "8px" }} /> */}
                        <Meta description={`${moment(contest.exhibition_start_period).format('Do-MMMM-YYYY')} to ${moment(contest.exhibition_end_period).format('Do-MMMM-YYYY')}`} style={{ paddingBottom: "8px" }} />
                        <Meta description={`Deadline: ${moment(contest.submission_end_period).format('Do-MMMM-YYYY')}`} style={{ paddingBottom: "8px" }}>Deadline:</Meta>
                      </div>
                      <ul class="ant-card-actions content-actions">
                        <li>
                          <Button type="link success" onClick={() => {
                            openUserModal(contest);
                          }}>
                            <EditOutlined key="edit" />
                          </Button>
                        </li>
                        <li>
                          <Popconfirm title="Sure to delete?" onConfirm={() => {
                            ContestDelete(contest.docId);
                          }}>
                            <Button type="link success">
                              <DeleteOutlined key='delete' />
                            </Button>
                          </Popconfirm>
                        </li>
                        <li>
                          <Switch defaultChecked={contest.contest_current == 'on' ? true : false} checkedChildren="on" unCheckedChildren="off" onChange={(value) => exGalleryStartEnd(value, contest.docId)} />
                        </li>
                      </ul>
                      <div className="text-center">
                        <Button onClick={() => { handleClick(contest.docId) }} type="primary" className="minwidth">View entries</Button>
                      </div>
                    </Card>
                  </Col>

                ))}
              </Row>

            }
          </div>

          <Modal
            title="Update Contest"
            visible={IsModalVisible}
            okText="Update"
            onOk={false}
            onCancel={handleCancel}
            footer={null}
            className="contest-edit--form"
          >
            <Form
              layout="vertical"
              {...formItemLayout}
              form={form2}
              onFinish={onSubmit}
              initialValues={{ ...initialValues }}
            >
              <Form.Item name="docId" hidden={true}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Contest Name"
                name="contest_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Contest name",
                  },
                  { max: 30, message: 'Contest name must be maximum 30 characters.' },
                  { validator: validator }
                ]}
              >
                <Input ref={register} />
              </Form.Item>

              <Form.Item label="Contest Description" name="contest_description"
                rules={[
                  { required: true },
                  { max: 100, message: 'Contest Description must be maximum 100 characters.' },
                ]}>
                <TextArea rows={4} ref={register} />
              </Form.Item>

              <Form.Item
                label="Submission Period"
                name="submission_period"
                rules={[{
                  required: true,
                  message: 'Please Choose Contest  Submission Date!',
                }
                ]}
                initialValue={[moment(submissionDate?.[0]), moment(submissionDate?.[1])]}
              >
                <RangePicker
                 format={dateFormat}
                  style={{ width: 400 }}
                  disabledDate={disabledDate}
                  defaultValue={[moment(submissionDate?.[0]), moment(submissionDate?.[1])]}

                  onChange={(value, dateString) => {
                    const myArray = value?.toString().split(",");
                    value == null ? setSubmissionDate([]) :
                      setSubmissionDate(myArray);
                    form2.setFieldsValue({ 'exhibition_period': null },
                      form2.setFields([{ name: 'exhibition_period', disabled: true }]))
                    setDate([])
                  }}
                />


              </Form.Item>
              <Form.Item
                label="Contest Exhibition Period"
                name="exhibition_period"
                rules={[
                  {
                    required: true,
                    message: "Please input your Contest Exhibition Period",
                  },
                ]}
                initialValue={[moment(date?.[0]), moment(date?.[1])]}
              >
 
                <RangePicker
                 format={dateFormat}
                  disabled={(submissionDate.length == 0) ? true : false}
                  style={{ width: 400 }}
                  disabledDate={submissionDate != null ? (value) => {
                    return moment(value).valueOf() < (submissionDate != null ? moment(submissionDate[1]).add("1", "day").valueOf() : "")
                  } : false}
                  defaultValue={[moment(date?.[0]), moment(date?.[1])]}
                  onChange={(value, dateString) => {
                    const myArray = value?.toString().split(",");
                    setDate(myArray);
                  }}

                />


              </Form.Item>
              <Form.Item
                label="Link to Details"
                name="link_details"
                rules={[
                  {
                    required: true,
                    message: "Please input your Link Details",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input type="text" ref={register} />
              </Form.Item>
              <Form.Item
                label="Link to Photos"
                name="link_photos"
                rules={[
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  },
                ]}
              >
                <Input type="text" ref={register} />
              </Form.Item>
              <Form.Item
                label="Winner line 1"
                name="winner_1"
                rules={[
                  { required: true, max: 20, message: 'Winner line 1 must be maximum 20 characters.' },
                ]}
              >
                <Input type="text" ref={register} />
              </Form.Item>
              <Form.Item
                label="Winner line 2"
                name="winner_2"
                rules={[
                  { required: true, max: 20, message: 'Winner line 2 must be maximum 20 characters.' },
                ]}
              >
                <Input type="text" ref={register} />
              </Form.Item>
              <Form.Item
                label="Contest Image"
                name="contest_image"
                rules={[
                  {
                    required: true,
                    message: "Please input your Contest image",
                  },
                ]}
              >
                <Space direction="vertical" size={12}>
                  <Upload
                    name="contest_image"
                    listType="picture-card"
                    maxCount={1}
                    onPreview={handlePreview}
                    onRemove={() => setFile(null)}
                    multiple={false}
                    onChange={handleChange}
                    customRequest={({ onSuccess, file }) => (
                      onSuccess("ok"), setFile(file)
                    )}
                  >
                    {file == null ? <img src={initialValues?.contest_image} alt="avatar" style={{ width: 80, height: 80 }} /> : null}
                  </Upload>
                </Space>
              </Form.Item>
              <Form.Item
              initialValue={initialValues?.conrest_type}>
                <Radio.Group
               
                onChange={onChange}value={contestType} name="contest_type">
                  <Radio value={"standard"}>Standard</Radio>
                  <Radio value={"premium"}>Premium Only</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button id="addbutton" type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={image} />
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
    </>


  )

}
export default ContentList;