import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { firestore } from '../../firebase/firebase';
import { withRouter } from "react-router";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserInfo = (props) => {
  const [admin, setAdmin] = useState([]);
  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => props.history.push('/adminprofile')}>My Profile</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  useEffect(() => {
    const docRef = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin').doc(localStorage.getItem('user_id'));
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const admin = doc.data();
        setAdmin(admin);
        // form.setFieldsValue(documentData);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <>
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar src={admin?.profile_url}
          className="gx-avatar gx-pointer" alt="" />
      </Popover>
    </>
  )

}

export default withRouter(UserInfo);
