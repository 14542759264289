import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, message, Form, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { firebase, firestore } from "../firebase/firebase";
import {
  hideMessage,
  userSignInSuccess, // userFacebookSignIn,
} from "../appRedux/actions/Auth";

import IntlMessages from "../util/IntlMessages";
import CircularProgress from "../components/CircularProgress/index";
import Service from "../service/index";
// import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
const { Option } = Select;

const SignIn = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [isVisible, setVisible] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [adminDetail, setAdminDetail] = useState(null);
  const history = useHistory();
  const [loader, setLoading] = useState(false);
  const { REACT_APP_PRODUCTION_MODE } = process.env;

  const validator = async (rule, value) => {
    if (rule.field == "phone_number") {
      if (value && !/^[0-9]+$/.test(value)) {
        return Promise.reject("Please enter only number");
      } else {
        return Promise.resolve();
      }
    } else if (rule.field == "otp") {
      if (value && !/^[0-9]+$/.test(value)) {
        return Promise.reject("Please enter only number");
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (showMessage) {
      const timeoutId = setTimeout(() => {
        dispatch(hideMessage());
      }, 50);

      return () => clearTimeout(timeoutId);
    }

    if (authUser) {
      history.push("/");
    }
  }, [showMessage, authUser, dispatch, history]);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in", {
      size: "invisible",
    });
  }, []);

  // const onFinishFailed = (errorInfo) => {
  //   message.error("Failed:", errorInfo);
  // };

  const otpVerification = (otp) => {
    const code = otp;
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        setVisible(false);
        localStorage.setItem("user_id", adminId);
        dispatch(userSignInSuccess({ id: adminId, admin: adminDetail }));
      })
      .catch((error) => {
        // setVisible(false);
        message.error(error.message);
      });
  };

  const otpHandler = (phoneNo) => {
    try {
      const phoneNumber = phoneNo;
      const appVerifier = window.recaptchaVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          console.log("🚀 ~ .then ~ confirmationResult:", confirmationResult)
          window.confirmationResult = confirmationResult;
          setVisible(true);
        })
        .catch((error) => {
          message.error(error.code);
          message.error(error.message);
          message.error("your phoneNumber few time block");
        });
    } catch (error) {
      message.error(error);
    }
  };
  const [phoneNumber, setPhoneNumber] = useState([]);
  const onFinish = async (values) => {
    setLoading(true);
    const admindata = await firestore
      .collection(
        REACT_APP_PRODUCTION_MODE == "development" ? "dev_admin" : "admin"
      )
      .get();
    let adminPhoneNumber;
    let adminDetails;
    let admin_Id;
    for (let i = 0; i < admindata.docs.length; i++) {
      if (
        `${values.country_code + values.phone_number}` ===
        admindata.docs[i].data()?.country_code +
          admindata.docs[i].data()?.phone_number
      ) {
        adminPhoneNumber =
          admindata.docs[i].data()?.country_code +
          admindata.docs[i].data()?.phone_number;
        adminDetails = admindata.docs[i].data();
        admin_Id = admindata.docs[i].id;
        setAdminId(admindata.docs[i].id);
        setAdminDetail(admindata.docs[i].data());
        break;
      }
    }
    // console.log(admindata.docs[0].data());
    if (`${values.country_code + values.phone_number}` === adminPhoneNumber) {
      if (Service.loginNumber.includes(adminPhoneNumber)) {
        localStorage.setItem("user_id", admin_Id);
        dispatch(userSignInSuccess({ id: admin_Id, admin: adminDetails }));
        // props.history.push({
        //   pathname: `/usersdetails`
        // })
      } else {
        otpHandler(adminPhoneNumber);
        message.success("Otp send your phone number ");
      }
    } else {
      message.error("Not registered your phone number");
      history.push("/signin");
    }
    let user;
    if (user) {
      routeOnLogin(user);
    } else {
      setLoading(false);
    }
  };
  const routeOnLogin = async (user) => {
    const token = await user.getIdTokenResult();
    if (token.claims.admin) {
      props.history.push("/");
    } else {
      props.history.push(`/`);
    }
  };

  function onSearch() {}
  const prefixSelector = (
    <Form.Item name="country_code" noStyle>
      <Select
        showSearch
        placeholder="Select a Country Code"
        style={{ width: 200 }}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="+213">Algeria (+213)</Option>
        <Option value="+376">Andorra (+376)</Option>
        <Option value="+244">Angola (+244)</Option>
        <Option value="+1264">Anguilla (+1264)</Option>
        <Option value="+1268">Antigua &amp; Barbuda (+1268)</Option>
        <Option value="+54">Argentina (+54)</Option>
        <Option value="+374">Armenia (+374)</Option>
        <Option value="+297">Aruba (+297)</Option>
        <Option value="+61">Australia (+61)</Option>
        <Option value="+43">Austria (+43)</Option>
        <Option value="+994">Azerbaijan (+994)</Option>
        <Option value="+1242">Bahamas (+1242)</Option>
        <Option value="+973">Bahrain (+973)</Option>
        <Option value="+880">Bangladesh (+880)</Option>
        <Option value="+1246">Barbados (+1246)</Option>
        <Option value="+375">Belarus (+375)</Option>
        <Option value="+32">Belgium (+32)</Option>
        <Option value="+501">Belize (+501)</Option>
        <Option value="+229">Benin (+229)</Option>
        <Option value="+1441">Bermuda (+1441)</Option>
        <Option value="+975">Bhutan (+975)</Option>
        <Option value="+591">Bolivia (+591)</Option>
        <Option value="+387">Bosnia Herzegovina (+387)</Option>
        <Option value="+267">Botswana (+267)</Option>
        <Option value="+55">Brazil (+55)</Option>
        <Option value="+673">Brunei (+673)</Option>
        <Option value="+359">Bulgaria (+359)</Option>
        <Option value="+226">Burkina Faso (+226)</Option>
        <Option value="+257">Burundi (+257)</Option>
        <Option value="+855">Cambodia (+855)</Option>
        <Option value="+237">Cameroon (+237)</Option>
        <Option value="+1">Canada (+1)</Option>
        <Option value="+238">Cape Verde Islands (+238)</Option>
        <Option value="+1345">Cayman Islands (+1345)</Option>
        <Option value="+236">Central African Republic (+236)</Option>
        <Option value="+56">Chile (+56)</Option>
        <Option value="+86">China (+86)</Option>
        <Option value="+57">Colombia (+57)</Option>
        <Option value="+269">Comoros (+269)</Option>
        <Option value="+242">Congo (+242)</Option>
        <Option value="+682">Cook Islands (+682)</Option>
        <Option value="+506">Costa Rica (+506)</Option>
        <Option value="+385">Croatia (+385)</Option>
        <Option value="+53">Cuba (+53)</Option>
        <Option value="+90392">Cyprus North (+90392)</Option>
        <Option value="+357">Cyprus South (+357)</Option>
        <Option value="+42">Czech Republic (+42)</Option>
        <Option value="+45">Denmark (+45)</Option>
        <Option value="+253">Djibouti (+253)</Option>
        <Option value="+1809">Dominica &amp; Dominican Republic (+1809)</Option>
        <Option value="+593">Ecuador (+593)</Option>
        <Option value="+20">Egypt (+20)</Option>
        <Option value="+503">El Salvador (+503)</Option>
        <Option value="+240">Equatorial Guinea (+240)</Option>
        <Option value="+291">Eritrea (+291)</Option>
        <Option value="+372">Estonia (+372)</Option>
        <Option value="+251">Ethiopia (+251)</Option>
        <Option value="+500">Falkland Islands (+500)</Option>
        <Option value="+298">Faroe Islands (+298)</Option>
        <Option value="+679">Fiji (+679)</Option>
        <Option value="+358">Finland (+358)</Option>
        <Option value="+33">France (+33)</Option>
        <Option value="+594">French Guiana (+594)</Option>
        <Option value="+689">French Polynesia (+689)</Option>
        <Option value="+241">Gabon (+241)</Option>
        <Option value="+220">Gambia (+220)</Option>
        <Option value="+7880">Georgia (+7880)</Option>
        <Option value="+49">Germany (+49)</Option>
        <Option value="+233">Ghana (+233)</Option>
        <Option value="+350">Gibraltar (+350)</Option>
        <Option value="+30">Greece (+30)</Option>
        <Option value="+299">Greenland (+299)</Option>
        <Option value="+1473">Grenada (+1473)</Option>
        <Option value="+590">Guadeloupe (+590)</Option>
        <Option value="+671">Guam (+671)</Option>
        <Option value="+502">Guatemala (+502)</Option>
        <Option value="+224">Guinea (+224)</Option>
        <Option value="+245">Guinea - Bissau (+245)</Option>
        <Option value="+592">Guyana (+592)</Option>
        <Option value="+509">Haiti (+509)</Option>
        <Option value="+504">Honduras (+504)</Option>
        <Option value="+852">Hong Kong (+852)</Option>
        <Option value="+36">Hungary (+36)</Option>
        <Option value="+354">Iceland (+354)</Option>
        <Option value="+91">India (+91)</Option>
        <Option value="+62">Indonesia (+62)</Option>
        <Option value="+98">Iran (+98)</Option>
        <Option value="+964">Iraq (+964)</Option>
        <Option value="+353">Ireland (+353)</Option>
        <Option value="+972">Israel (+972)</Option>
        <Option value="+39">Italy (+39)</Option>
        <Option value="+1876">Jamaica (+1876)</Option>
        <Option value="+81">Japan (+81)</Option>
        <Option value="+962">Jordan (+962)</Option>
        <Option value="+7">Kazakhstan (+7)</Option>
        <Option value="+254">Kenya (+254)</Option>
        <Option value="+686">Kiribati (+686)</Option>
        <Option value="+850">Korea North (+850)</Option>
        <Option value="+82">Korea South (+82)</Option>
        <Option value="+383">Kosovo (+383)</Option>
        <Option value="+965">Kuwait (+965)</Option>
        <Option value="+996">Kyrgyzstan (+996)</Option>
        <Option value="+856">Laos (+856)</Option>
        <Option value="+371">Latvia (+371)</Option>
        <Option value="+961">Lebanon (+961)</Option>
        <Option value="+266">Lesotho (+266)</Option>
        <Option value="+231">Liberia (+231)</Option>
        <Option value="+218">Libya (+218)</Option>
        <Option value="+417">Liechtenstein (+417)</Option>
        <Option value="+370">Lithuania (+370)</Option>
        <Option value="+352">Luxembourg (+352)</Option>
        <Option value="+853">Macao (+853)</Option>
        <Option value="+389">Macedonia (+389)</Option>
        <Option value="+261">Madagascar (+261)</Option>
        <Option value="+265">Malawi (+265)</Option>
        <Option value="+60">Malaysia (+60)</Option>
        <Option value="+960">Maldives (+960)</Option>
        <Option value="+223">Mali (+223)</Option>
        <Option value="+356">Malta (+356)</Option>
        <Option value="+692">Marshall Islands (+692)</Option>
        <Option value="+596">Martinique (+596)</Option>
        <Option value="+222">Mauritania (+222)</Option>
        <Option value="+269">Mayotte (+269)</Option>
        <Option value="+52">Mexico (+52)</Option>
        <Option value="+691">Micronesia (+691)</Option>
        <Option value="+373">Moldova (+373)</Option>
        <Option value="+377">Monaco (+377)</Option>
        <Option value="+976">Mongolia (+976)</Option>
        <Option value="+1664">Montserrat (+1664)</Option>
        <Option value="+212">Morocco (+212)</Option>
        <Option value="+258">Mozambique (+258)</Option>
        <Option value="+95">Myanmar (+95)</Option>
        <Option value="+264">Namibia (+264)</Option>
        <Option value="+674">Nauru (+674)</Option>
        <Option value="+977">Nepal (+977)</Option>
        <Option value="+31">Netherlands (+31)</Option>
        <Option value="+687">New Caledonia (+687)</Option>
        <Option value="+64">New Zealand (+64)</Option>
        <Option value="+505">Nicaragua (+505)</Option>
        <Option value="+227">Niger (+227)</Option>
        <Option value="+234">Nigeria (+234)</Option>
        <Option value="+683">Niue (+683)</Option>
        <Option value="+672">Norfolk Islands (+672)</Option>
        <Option value="+670">Northern Marianas (+670)</Option>
        <Option value="+47">Norway (+47)</Option>
        <Option value="+968">Oman (+968)</Option>
        <Option value="+680">Palau (+680)</Option>
        <Option value="+507">Panama (+507)</Option>
        <Option value="+92">Pakistan (+92)</Option>
        <Option value="+675">Papua New Guinea (+675)</Option>
        <Option value="+595">Paraguay (+595)</Option>
        <Option value="+51">Peru (+51)</Option>
        <Option value="+63">Philippines (+63)</Option>
        <Option value="+48">Poland (+48)</Option>
        <Option value="+351">Portugal (+351)</Option>
        <Option value="+1787">Puerto Rico (+1787)</Option>
        <Option value="+974">Qatar (+974)</Option>
        <Option value="+262">Reunion (+262)</Option>
        <Option value="+40">Romania (+40)</Option>
        <Option value="+7">Russia (+7)</Option>
        <Option value="+250">Rwanda (+250)</Option>
        <Option value="+378">San Marino (+378)</Option>
        <Option value="+239">Sao Tome &amp; Principe (+239)</Option>
        <Option value="+966">Saudi Arabia (+966)</Option>
        <Option value="+221">Senegal (+221)</Option>
        <Option value="+381">Serbia (+381)</Option>
        <Option value="+248">Seychelles (+248)</Option>
        <Option value="+232">Sierra Leone (+232)</Option>
        <Option value="+65">Singapore (+65)</Option>
        <Option value="+421">Slovak Republic (+421)</Option>
        <Option value="+386">Slovenia (+386)</Option>
        <Option value="+677">Solomon Islands (+677)</Option>
        <Option value="+252">Somalia (+252)</Option>
        <Option value="+27">South Africa (+27)</Option>
        <Option value="+34">Spain (+34)</Option>
        <Option value="+94">Sri Lanka (+94)</Option>
        <Option value="+290">St. Helena (+290)</Option>
        <Option value="+1869">St. Kitts (+1869)</Option>
        <Option value="+1758">St. Lucia (+1758)</Option>
        <Option value="+249">Sudan (+249)</Option>
        <Option value="+597">Suriname (+597)</Option>
        <Option value="+268">Swaziland (+268)</Option>
        <Option value="+46">Sweden (+46)</Option>
        <Option value="+41">Switzerland (+41)</Option>
        <Option value="+963">Syria (+963)</Option>
        <Option value="+886">Taiwan (+886)</Option>
        <Option value="+7">Tajikstan (+7)</Option>
        <Option value="+66">Thailand (+66)</Option>
        <Option value="+228">Togo (+228)</Option>
        <Option value="+676">Tonga (+676)</Option>
        <Option value="+1868">Trinidad &amp; Tobago (+1868)</Option>
        <Option value="+216">Tunisia (+216)</Option>
        <Option value="+90">Turkey (+90)</Option>
        <Option value="+7">Turkmenistan (+7)</Option>
        <Option value="+993">Turkmenistan (+993)</Option>
        <Option value="+1649">Turks &amp; Caicos Islands (+1649)</Option>
        <Option value="+688">Tuvalu (+688)</Option>
        <Option value="+256">Uganda (+256)</Option>
        <Option value="+44">UK (+44)</Option>
        <Option value="+380">Ukraine (+380)</Option>
        <Option value="+971">United Arab Emirates (+971)</Option>
        <Option value="+598">Uruguay (+598)</Option>
        <Option value="+1">USA (+1)</Option>
        <Option value="+7">Uzbekistan (+7)</Option>
        <Option value="+678">Vanuatu (+678)</Option>
        <Option value="+379">Vatican City (+379)</Option>
        <Option value="+58">Venezuela (+58)</Option>
        <Option value="+84">Vietnam (+84)</Option>
        <Option value="+84">Virgin Islands - British (+1284)</Option>
        <Option value="+84">Virgin Islands - US (+1340)</Option>
        <Option value="+681">Wallis &amp; Futuna (+681)</Option>
        <Option value="+969">Yemen (North)(+969)</Option>
        <Option value="+967">Yemen (South)(+967)</Option>
        <Option value="+260">Zambia (+260)</Option>
        <Option value="+263">Zimbabwe (+263)</Option>
      </Select>
    </Form.Item>
  );
  const memoizedForm = useMemo(
    () => (
      <Form
        initialValues={{ country_code: "+91" }}
        name="basic"
        onFinish={onFinish}
        className="gx-signin-form gx-form-row0"
      >
        <Form.Item
          name="phone_number"
          rules={[
            { required: true, message: "Please enter your phone number!" },
            { validator: validator },
          ]}
        >
          <Input placeholder="Phone Number " addonBefore={prefixSelector} />
        </Form.Item>
        <Form.Item>
          <Button
            id="sign-in"
            type="primary"
            className="gx-mb-0"
            htmlType="submit"
           
          >
            <IntlMessages id="app.userAuth.signIn" />
          </Button>
        </Form.Item>
      </Form>
    ),
    [onFinish, validator]
  );
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature'/> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src={require("assets/images/artboxyLogo-01.png")}
              />
            </div>
          </div>
          <div className="gx-app-login-content signinform">{memoizedForm}</div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
      <Modal
        visible={isVisible}
        title="Enter Your OTP"
        okText="Submit"
        cancelText="Cancel"
        onCancel={() => setVisible(false)}
        onOk={() => {
          try {
            form.validateFields().then((values) => otpVerification(values.otp));
          } catch (error) {
            message.error("please Valid otp enter");
          }
        }}
      >
        <Form form={form} layout="vertical" name="OTP value">
          <Form.Item
            name="otp"
            label="OTP"
            rules={[
              { required: true, message: "Please input OTP" },
              { validator: validator },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SignIn;
