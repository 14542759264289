import React, { useState } from 'react';
import { Input, Button, Form, Card, message } from 'antd';
import CircularProgress from "../CircularProgress/index";
import Service from '../../service';
const { REACT_APP_PRODUCTION_MODE } = process.env;

const AddNotification = (props) => {
    const [loader, setLoading] = useState(false);
    const [form2] = Form.useForm();

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const artworkNotification = {
                notification_msg: values?.notification_msg,
                check_mode:REACT_APP_PRODUCTION_MODE
            };
            const sendNotification = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.sendAdminNotification,
                body: artworkNotification
            });
            
            if (sendNotification?.data?.status == true) {
                setLoading(false)
                message.success('Successfully send notification')
            } else {
                setLoading(false)
                message.error('Somethings went to wronng')
            }
            return props.history.push({
                pathname: `/send-notifications`,
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };


    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <div>
            {loader == false ?
                <Card className="gx-card" title="Send Notification">
                    <Form layout="vertical"
                        {...formItemLayout}
                        form={form2}
                        onFinish={onSubmit}
                    >
                        <Form.Item name="id" hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Notification Message"
                            name="notification_msg"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your notification message',
                                }
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button id="addbutton" type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>

                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>}
        </div>
    );
}

export default AddNotification;
