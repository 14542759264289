import React, { useState, useEffect, useCallback } from 'react';
import SunEditor from 'suneditor-react';
import { Table, Modal, Button, Card, Input, Space, Form, message } from 'antd';
import 'suneditor/dist/css/suneditor.min.css';
import { firestore, firebase } from "../../firebase/firebase"
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useForm } from 'react-hook-form';
const { TextArea } = Input;


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const CmsPages = () => {
  const [cmsPagesData, setCmsPagesData] = useState([])
  const [initialValues, setInitialValues] = useState(null)
  const [cmsContent, setCmsContent] = useState(null)
  const [IsModalVisible, setIsModalVisible] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  const searchInput = React.useRef(null)
  const [cmsContentCheck, setCmsContentCheck] = useState('Preview')
  const { register } = useForm();
  const [form2] = Form.useForm();

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, sorter) => {
    setPagination(pagination)
  }

  useEffect(() => {
    cmsPagesList();
  }, [cmsContent]);


  const cmsPagesList = async () => {
    await firestore.collection('cms_pages')
      .onSnapshot((querySnapshot) => {
        const cmsPage = querySnapshot.docs.map((doc) => {
          let cmsPagedata = doc.data();
          cmsPagedata.docId = doc.id;
          return cmsPagedata;
        });
        setCmsPagesData(cmsPage);
      });
  }

  const openCmsPageModal = (values) => {
    setInitialValues(null)
    setInitialValues(values)
    setCmsContent(values?.content)
    setIsModalVisible(true)
    setCmsContentCheck('EditContent')
    form2.setFieldsValue(values);
  }
  const columns = [
    {
      title: 'Cms Page Name',
      dataIndex: 'cms_page_name',
      width: 300,
      ...getColumnSearchProps('cms_page_name')
    },
    {
      title: 'Language',
      dataIndex: 'language',
      width: 300,
      ...getColumnSearchProps('language')
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (text, record, index) =>
        cmsPagesData.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>

            <Button type="link success" onClick={() => openCmsPageModal(record)}><EditOutlined style={{ fontSize: '18px' }} /></Button>

          </div>
        ) : null,
    }
  ]


  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is {cmsPagesData.length}
      </label>
    )
  }

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setInitialValues(null);
    setCmsContent(null)
    setCmsContentCheck('Preview')
  }, [form2]);

  const cmsPageUpdateMethod = async (values) => {
    try {
      await firestore.collection('cms_pages').doc(initialValues?.docId).update({
        content: values?.content,
        update_at: firebase.firestore.FieldValue.serverTimestamp()
      })
      setIsModalVisible(false);
      setInitialValues(null);
      setCmsContent(null)
      message.success('Successfully Update data');
    } catch (e) {
      console.log(e);
      message.error('Something went to wrong');
    }
  }

  return (
    <Card title="Cms Page List">
      <Table
        className="gx-table-responsive"
        columns={columns}
        rowKey={(cmsPagesData) => cmsPagesData.docId
        }
        dataSource={[...cmsPagesData]}
        pagination={pagination}
        onChange={handleTableChange}
        footer={getFooterDetails}
      />
      <Modal
        title="Update CMS Page"
        visible={IsModalVisible}
        okText="Update"
        onOk={false}
        onCancel={handleCancel}
        footer={null}
        class="cms-modal"
      >

        {cmsContentCheck == 'Preview' ?
          < Button id="addbutton" type="primary" onClick={() => setCmsContentCheck('EditContent')}>
            Edit HTML
          </Button>
          :
          <Button id="addbutton" type="primary" onClick={() => setCmsContentCheck('Preview')}>
            Preview
          </Button>
        }
        {cmsContentCheck == "EditContent" ?
          <Form layout="vertical"
            {...formItemLayout}
            form={form2}
            onFinish={cmsPageUpdateMethod}
            initialValues={{ ...initialValues }}
            className="foem-cms"
          >

            <Form.Item name="docId" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              name="content"
              rules={[
                {
                  required: true,
                  message: 'Please input your html content',
                }
              ]}
            >
              <TextArea rows={15} ref={register} />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button id="addbutton" type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
          :
          <div dangerouslySetInnerHTML={{ __html: cmsContent }}></div>
        }
        {/* <SunEditor onChange={(data) => setCmsContent(data)} defaultValue={initialValues?.content} /> */}

      </Modal>
    </Card >
  );


}
export default CmsPages



