import React, { useState, useEffect, useMemo } from "react"
import { Table, Modal, Card, Checkbox, Row, Col, Button, Image, Input, Space, message, Popconfirm } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { DeleteOutlined, EyeOutlined, SearchOutlined, FlagOutlined } from '@ant-design/icons'
import webUrl from "../../routes/api";
import Highlighter from 'react-highlight-words';
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
import moment from "moment";
const { REACT_APP_PRODUCTION_MODE, REACT_APP_IMAGE_URL_FIREBASE, REACT_APP_IMAGE_URL_COMPRESS } = process.env;

const { Search } = Input;
const Blogsdetailes = (props) => {
  const [suspendId, setSuspendId] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  const [loader, setLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [blogListCount, setBlogListCount] = useState();
  const [hasSelected, setHasSelected] = useState('')
  const [searchBlogData, setSearchBlogData] = useState([]);
  const [searchedText, setSearchedText] = useState("")
  const [page, setPage] = useState(1);
  const [pagetable, setPagetable] = useState(0)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  let count = blogListCount / 50;
  let totalPages = Math.ceil(count);
  const searchInput = React.useRef(null)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [fieldValue, setFieldValue] = useState("")
  const [TextField, setTextField] = useState(false)

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys)
    setIsSearch(true);
  }
  const handleReset = clearFilters => {
    // setSearchBlogData([]);
    setIsSearch(false);
    clearFilters();
    setSearchedText('');
    setSearchedColumn('');

  };
  const handleNext = async () => {
    let data = pagetable
    setPagetable(data + 50)
    const getData = async () => {
      setLoading(true);
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getBlogDetails,
        body: {
          from: pagetable + 50,
          size: 50,
          check_mode: REACT_APP_PRODUCTION_MODE,
          query: searchText
        }

      });

      if (response?.data.status == true) {
        { setBlogList(response?.data?.searchData) }
        setLoading(false);
        setPagetable(data + 50)
      }
    }
    getData()
  }
  const handlePrevious = async () => {
    let data = pagetable
    setPagetable(data - 50)
    getData()
  }
  const getFooterDetails = () => {
    return (
      <div className='footer-table'>
        {(searchBlogData.length == 0 && searchText == '' && searchedText == "" && blogList.length > 0) ? <div className='footer-labels'>
          {searchBlogData.length == 0 ? <label>
            Total Records Count is {(searchBlogData.length == 0) ? blogListCount : ""}
          </label> : ""}

          {searchBlogData.length == 0 ? <label>Current page is: {pagetable / 50 + 1}</label> : ""}
          {searchBlogData.length == 0 ? <label>Total page is: {totalPages}</label> : ""}


        </div> : ""}
        {(searchBlogData.length == 0 && searchText == '' && searchedText == "" && blogList.length > 0) ? <div className="button-class">
          {pagetable === 0 ? '' : <Button type="primary" style={{ marginRight: "5px" }} onClick={() => handlePrevious()}>Previous</Button>}
          {
            (blogList.length  < 49 ) ? '' : <Button type="primary" onClick={() => handleNext()}>Next</Button>
          }
        </div> : ""}
      </div>

    )
  }
  const handleTableChange = (pagination, sorter) => {
    setPagination(pagination)
  }
  //   const fetchPreviousData = async () => {
  //     firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
  //       .where('type', '==', 'blog')
  //       .where('status', "in", ['approved', 'rejected'])
  //       .where('is_deleted', '==', false)
  //       .orderBy('create_at', 'desc')
  //       .limit(50).endBefore(item.create_at)
  //       .limitToLast(50)
  //       .onSnapshot(async (querySnapshot) => {
  //         const blogsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
  //           let blogdata = doc.data();
  //           blogdata.docId = doc.id;
  //           const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(blogdata.user_id).get()
  //           if (userData.exists) {
  //             blogdata.user_name = userData.data()?.user_name
  //           }
  //           return blogdata;
  //         }));
  //         setBlogList(blogsData);
  //         setPage(page + 1);
  //       });

  //   };
  //   fetchPreviousData();
  // };

  const handleCancel = async () => {
    setSearchText('')
    const getData = async () => {
      setLoading(true);
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getBlogDetails,
        body: {
          from: pagetable,
          size: 50,
          check_mode: REACT_APP_PRODUCTION_MODE,
          query: ""
        }

      });

      if (response?.data.status == true) {
        { setBlogList(response?.data?.searchData) }
        setLoading(false);
      }
    }
    getData()
    setSearchBlogData([])
  }
  const handleInputChange = (event) => {
    let searchvalue = event.target.value;
    setSearchText(searchvalue)

  };

  const blogDeleteNotification = async (postId, userData, reportmessage) => {
    try {
      const blogNotification = {
        token: userData?.token,
        type: '0',
        body: `Your blog has been deleted. Reason: ${reportmessage}`,
        body_spanish: `Tu blog ha sido eliminado. Razón: ${reportmessage}`,
        body_german: `Dein Blog wurde gelöscht. Grund: ${reportmessage}`,
        body_italian: `Il tuo blog è stato cancellato. Motivo: ${reportmessage}`,
        body_french: `Votre blog a été supprimé. Raison: ${reportmessage}`,
        title: 'Blog',
        title_spanish: 'Blog',
        title_german: 'Blog',
        title_italian: 'Blog',
        title_french: 'Blog',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userData?.user_id,
        post_id: postId,
        check_mode: REACT_APP_PRODUCTION_MODE
      };
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: blogNotification
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const handleDelete = async (index, reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters")
        return
      }
      setLoading(true)
      const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(index).get();
      if (doc.exists) {
        await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp() })
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', doc.data().user_id).get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
              await blogDeleteNotification(index, doc.data(), reportmessage);
            });
          })
      }
      setLoading(false)
      message.success('Successfully Updated');
      return props.history.push({
        pathname: `/blogs`,
      });
    } catch (error) {
      setLoading(false)
      console.log(error);
      message.error('Something went to wrong');
    }
  };

  useEffect(() => {
    getData();
  }, [])


  const getData = async () => {
    setLoading(true);
    const response = await Service.makeAPICall({
      methodName: Service.postMethod,
      api_url: Service.getBlogDetails,
      body: {
        from: pagetable,
        size: 50,
        check_mode: REACT_APP_PRODUCTION_MODE,
        query: searchText
      }

    });

    if (response?.data.status == true) {
      if (response?.data?.searchData.length == 0) {
        setBlogList([])
      }
      else if (searchText == '') {
        setBlogList(response?.data?.searchData)
        setSearchBlogData([])
      } else {
        setSearchBlogData(response?.data?.searchData)
      }
      setLoading(false);
    }
  }



  useEffect(() => {
    // blogsListing()
    BlogListingcount()
  }, []);

  const handleReportStatus = async (data) => {
    try {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_post' : 'report_post')
        .add({
          report_id: moment(new Date()).format('DDMMYYYYhhmmss').toString(),
          report_message: '',
          report_post_id: data?.docId,
          sender_report_user_id: 'ADMIN',
          is_deleted: false,
          status: false,
          create_at: firebase.firestore.FieldValue.serverTimestamp(),
          update_at: firebase.firestore.FieldValue.serverTimestamp()
        })
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data?.docId).update({ report_status: true })
      setTimeout(() => {
        getData()
      }, 1000);
      message.success('Sucessfully report')
    } catch (error) {
      message.error('something went to wrong')
    }
  }
  const BlogListingcount = async () => {
    setLoading(true)
    const data = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
      .where('type', '==', 'blog')
      .where('status', "in", ['approved', 'rejected'])
      .where('is_deleted', '==', false).get()
    const count = data.size;
    setBlogListCount(count);

    setLoading(false)

  }
  const handleModerationStatus = async (data, moderationFlag) => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data?.docId).update({ moderation: moderationFlag })
      setTimeout(() => {
        getData()
      }, 1500);
      setLoading(false)
      message.success('Sucessfully moderation updated')
    } catch (error) {
      setLoading(false)
      message.error('something went to wrong')
    }
  }
  const columns = [
    {
      title: 'Blog Id',
      dataIndex: 'docId',
      width: 300,
      ellipsis: true,
      ...getColumnSearchProps('docId')
    },
    {
      title: 'Blog',
      dataIndex: 'img_url',
      width: 300,
      height: 300,
      render: (text, record) => {
        return (
          <div>
            <Image width={150} height={150} src={record.img_url}
            />
          </div>
        );
      },
    },
    {
      title: 'Moderation',
      dataIndex: 'moderation',
      width: 120,
      ...getColumnSearchProps('moderation'),
      render: (text, record, id) => {
        return (
          <div>
            <Checkbox
              onClick={() => handleModerationStatus(record, record.moderation == 'ischecked' ? 'unchecked' : 'ischecked')}
              checked={record?.moderation == 'ischecked' ? true : false}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Flag',
      dataIndex: 'report_status',
      width: 80,
      height: 80,
      render: (text, record, id) => {

        return (
          <div>
            {record?.report_status ? <FlagOutlined style={{ color: 'red', fontSize: '30px' }} /> :
              <Popconfirm title="Sure to report?" onConfirm={() => handleReportStatus(record)}>
                <Button type="link success"><FlagOutlined style={{ color: 'green', fontSize: '30px' }} /></Button>
              </Popconfirm>

            }
          </div>
        );
      },
    },
    , {
      title: 'Caption',
      dataIndex: 'caption',
      width: 130,
      ellipsis: true,
      ...getColumnSearchProps('caption')
    },
    ,
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      ...getColumnSearchProps('status'),
      render: (text, record, index) => (
        <div style={{
          display: "flex",
          flexwrap: "wrap"
        }}>

          {record.status == "approved" ? <span style={{ color: "green" }}>approved</span> : <span style={{ color: "red" }}>rejected</span>}
        </div>

      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      render: (text, record, index) =>
        blogList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>
            <Button type="link success" onClick={() => { setIsModalVisible(true); setSuspendId(record.docId) }}><DeleteOutlined style={{ fontSize: '18px', marginLeft: '10px' }} /></Button>

          </div>
        ) : null,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: 100,
      render: (text, record, index) =>
        blogList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>
            <a href={`${webUrl?.postDetailsURl}/${record.docId}`} target="_blank">
              <Button type="link success">
                <EyeOutlined style={{ fontSize: '20px' }} />
              </Button>
            </a>
          </div>
        ) : null,
    }
  ]


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected([])
      if (selectedRowKeys?.length <= 50) {
        setHasSelected(selectedRowKeys)
      } else {
        message.error('Please select maximum 50 data')
      }
    },
  };

  const checkdelete = async (reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters")
        return
      }
      setLoading(true)
      setIsReportModal(false)
      await Promise.all(hasSelected.map(async (dataID) => {
        const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(dataID).get();
        if (doc.exists) {
          await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp() })
          await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', doc.data().user_id).get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(async function (doc) {
                await blogDeleteNotification(dataID, doc.data(), reportmessage);
              });
            })
        }
      }))
      setLoading(false)
      message.success('Successfully Deleted');
      return props.history.push({
        pathname: `/blogs`,
      });
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const handleModerationStatusAll = async () => {
    try {
      setLoading(true)
      await Promise.all(hasSelected.map(async (data) => {
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data).update({ moderation: 'ischecked' })
      }))
      setTimeout(() => {
        getData()
      }, 1500);
      setLoading(false)
      setHasSelected([])
      message.success('Sucessfully moderation updated')
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const table = useMemo(() => {
    return loader == false ? <Table
      className="gx-table-responsive"
      columns={columns}
      rowSelection={{ ...rowSelection }}
      rowKey={(record) => record.docId}
      dataSource={searchBlogData.length == 0 ? blogList : searchBlogData}
      pagination={searchBlogData.length == 0 ? false : pagination}
      onChange={handleTableChange}
      footer={getFooterDetails}
    />
      :
      <div className="gx-loader-view">
        <CircularProgress />
      </div>
  }, [blogList, searchBlogData, loader, blogListCount, pagetable, pagination])
  return (
    <div>
      {
        loader == false ?
          <Card title="Blog Details">
            <Row>
              <Col md={16} span={24}>
                <div style={{ maxWidth: "400px", display: "flex", alignItems: "center", gap: "15px" }}>
                  <Search
                    placeholder="Search..."
                    enterButton="Search"
                    size="medium"
                    onSearch={() => {
                      getData()
                      setHasSelected([])
                    }}
                    onCancel={handleCancel}
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                    value={searchText}
                  />
                  <Button
                    onClick={handleCancel}
                    style={{ width: 90 }}
                    type="primary"
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>

            <Button type="danger" hidden={!hasSelected.length > 0} onClick={() => setIsReportModal(true)}>Delete</Button>
            <Popconfirm title="Sure to moderation?" onConfirm={() => handleModerationStatusAll()}>
              <Button style={{ backgroundColor: 'green', color: 'white' }} hidden={!hasSelected.length > 0}>Moderation</Button>
            </Popconfirm>
            <Modal title="Reason.." visible={isReportModal} footer={false} onCancel={() => { setIsReportModal(false);; setFieldValue(''); setTextField(false) }}>
              <p><b>why are you this post delete?</b></p><br />
              <Button onClick={() => {
                checkdelete('Inappropriate content');
                setIsReportModal(false)
              }}>
                Inappropriate content</Button>
              <Button onClick={() => {
                checkdelete('Commercials');
                setIsReportModal(false)
              }}>Commercials</Button>
              <Button onClick={() => {
                checkdelete('Plagiarism');
                setIsReportModal(false)
              }}>Plagiarism</Button>
              <Button onClick={() => setTextField(!TextField)}
              >Other reason</Button>
              {TextField ?
                <>
                  <li>
                    <div className="report-form">
                      <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                      <Button className="btn primary-btn" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? checkdelete(fieldValue)
                        : message.error("please fill the data")}>Send report</Button>
                    </div>
                  </li>
                </> : null}
            </Modal>
            {table}
          </Card>
          :
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
      }

      <Modal title="Reason.." visible={isModalVisible} footer={false} onCancel={() => { setIsModalVisible(false); setFieldValue(''); setTextField(false); }}>
        <p><b>why are you this post delete?</b></p><br />
        <Button onClick={() => {
          handleDelete(suspendId, 'Inappropriate content');
          setIsModalVisible(false)
        }}>
          Inappropriate content</Button>
        <Button onClick={() => {
          handleDelete(suspendId, 'Commercials');
          setIsModalVisible(false)
        }}>Commercials</Button>
        <Button onClick={() => {
          handleDelete(suspendId, 'Plagiarism');
          setIsModalVisible(false)
        }}>Plagiarism</Button>
        <Button onClick={() => setTextField(!TextField)}
        >Other reason</Button>
        {TextField ?
          <>
            <li>
              <div className="report-form">
                <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                <Button className="btn primary-btn" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? handleDelete(suspendId, fieldValue)
                  : message.error("please fill the data")}>Send report</Button>
              </div>
            </li>
          </> : null}
      </Modal>

    </div>
  )

}
export default Blogsdetailes;