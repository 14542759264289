import React, { useEffect, useState } from 'react';
import { Input, Select, Button, Form, Card, Space, Upload, Modal, message, Radio } from 'antd';
import { firestore, firebase, storage } from '../../firebase/firebase'
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const AddPopImage = (props) => {
    const [loader, setLoading] = useState(false);
    const [value, setValue] = useState();
    const [technique, setTechnique] = useState([])
    const [form2] = Form.useForm();
    const [previewImage, setPreviewImage] = useState(false);
    const [image, setImage] = useState(false);
    const [previewPopUp, setPreviewPopUp] = useState(false);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => (resolve(reader.result), setImage(reader.result));
            reader.onerror = error => reject(error);
        });
    }
    useEffect(() => {
        getInitialValue();
    }, [])
    const getInitialValue = async () => {
        const data = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_pop_image` : 'pop_image').onSnapshot((querySnapshot) => {
            let postData = querySnapshot.docs.map((doc) => {
                let techniquesdata = doc.data();
                techniquesdata.docId = doc.id
                return techniquesdata
            });
            let data = postData[0]?.enabled;
            setValue(data);


        });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        // setImage(file.url || file.preview) 
        setPreviewImage(true)

    }

    const handleChange = (info) => {

    }
    const onChangeRadio = async (e) => {

        setValue(e.target.value);

        setPreviewPopUp(true);
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_pop_image` : 'pop_image')
            .doc(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_pop_image` : 'pop_image')
            .update({
                "enabled": e.target.value,

            })
        {
            e.target.value == 1 ? message.success('The popup is enabled successfully.') : message.success('The popup is disabled successfully.')
        }

    };

    const customUpload = (docId, file) => {
        try {
            setLoading(true)
            if (file == null) {
                setLoading(false)
                return
            }
            const metadata = {
                contentType: 'image/jpeg'
            }
            const storageRef = storage.ref();
            const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_pop_image/${docId}` : `pop_image/${docId}`).put(file, metadata);
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            // console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            // console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                        if (!!url) {
                            await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_pop_image` : 'pop_image')
                                .doc(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_pop_image` : 'pop_image')
                                .update({
                                    [docId]: url,

                                })
                            setLoading(false)
                            message.success('Successfully Update Pop Image');
                            return props.history.push({
                                pathname: `/pop-image`,
                            });
                        }
                    })
                }
            );


        } catch (e) {
            setLoading(false)
            console.log(e);
        }

    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };


    useEffect(() => {
        setLoading(true)
        firestore
            .collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_pop_image' : 'pop_image')
            .onSnapshot((querySnapshot) => {
                let techniques = querySnapshot.docs.map((doc) => {
                    let techniquesdata = doc.data();
                    techniquesdata.docId = doc.id
                    return techniquesdata
                });
                setTechnique(techniques);
            });
        setLoading(false)
    }, []);


    return (
        <div className='popup-wrapperbox'>
            {loader == false ?
                <Card className="gx-card" title="Update Pop image">
                    <div className='popimg-radio'>
                        <Radio.Group onChange={onChangeRadio} value={value}>
                            <Radio value={1}>Enabled</Radio>
                            <Radio value={0}>Disabled</Radio>
                        </Radio.Group>
                    </div>
                    <Form layout="vertical"
                        {...formItemLayout}
                        form={form2}
                    // onFinish={onSubmit}
                    >
                        <Form.Item name="id" hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Pop Image (English)"
                            name="banner_image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pop image!',
                                }
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <Upload
                                    name="banner_image"
                                    listType="picture-card"
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    customRequest={({ onSuccess, file }) =>
                                    (onSuccess('ok'),
                                        customUpload("en", file))}
                                >
                                    {technique[0]?.en ? <img src={technique[0]?.en} alt="avatar" /> : <img src="" alt="avatar" />}
                                </Upload>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            label="Pop Image (French)"
                            name="banner_image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pop image!',
                                }
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <Upload
                                    name="banner_image"
                                    listType="picture-card"
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    customRequest={({ onSuccess, file }) =>
                                    (onSuccess('ok'),
                                        customUpload("fr", file))}
                                >
                                    {technique[0]?.fr ? <img src={technique[0]?.fr} alt="avatar" /> : <img src="" alt="avatar" />}
                                </Upload>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            label="Pop Image (Italian)"
                            name="banner_image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pop image!',
                                }
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <Upload
                                    name="banner_image"
                                    listType="picture-card"
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    customRequest={({ onSuccess, file }) =>
                                    (onSuccess('ok'),
                                        customUpload("in", file))}
                                >
                                    {technique[0]?.in ? <img src={technique[0]?.in} alt="avatar" /> : <img src="" alt="avatar" />}
                                </Upload>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            label="Pop Image (Spanish)"
                            name="banner_image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pop image!',
                                }
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <Upload
                                    name="banner_image"
                                    listType="picture-card"
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    customRequest={({ onSuccess, file }) =>
                                    (onSuccess('ok'),
                                        customUpload("sp", file))}
                                >
                                    {technique[0]?.sp ? <img src={technique[0]?.sp} alt="avatar" /> : <img src="" alt="avatar" />}
                                </Upload>
                            </Space>
                        </Form.Item>
                        <Form.Item
                            label="Pop Image (German)"
                            name="banner_image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pop image!',
                                }
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <Upload
                                    name="banner_image"
                                    listType="picture-card"
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    customRequest={({ onSuccess, file }) =>
                                    (onSuccess('ok'),
                                        customUpload("ge", file))}
                                >
                                    {technique[0]?.ge ? <img src={technique[0]?.ge} alt="avatar" /> : <img src="" alt="avatar" />}
                                </Upload>
                            </Space>
                        </Form.Item>

                    </Form>
                </Card>
                :
                <div className="gx-loader-view">
                    <CircularProgress />
                </div>}
            <Modal
                visible={previewImage}
                footer={null}
                onCancel={() => setPreviewImage(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={image} />
            </Modal>
        </div>
    );
}

export default AddPopImage;
