import React, { useEffect, useState } from "react";
import { firestore, firebase } from "../../firebase/firebase";
import moment from "moment";
import webUrl from "../../routes/api";
import {
  Table,
  Modal,
  Button,
  Card,
  Tag,
  Popconfirm,
  Input,
  Space,
  Form,
  message,
  Image,
} from "antd";
import {
  SearchOutlined,
  EyeOutlined,
  CheckOutlined,
  PlusOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import CircularProgress from "../CircularProgress/index";
import { create, set } from "lodash";
import Service from "../../service";
const {
  REACT_APP_PRODUCTION_MODE,
  REACT_APP_IMAGE_URL_FIREBASE,
  REACT_APP_IMAGE_URL_COMPRESS,
} = process.env;
let todayDate = moment(new Date()).valueOf();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ContestSubmission = (props) => {
  const [loader, setLoading] = useState(false);
  const [hasSelected, setHasSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [initialValues, setInitialValues] = useState(null);
  const [cmsContent, setCmsContent] = useState(null);
  const [IsModalVisible, setIsModalVisible] = useState(false);
  const [userContestList, setUserContestList] = useState([]);
  const [winnerData, setWinnerData] = useState([]);
  const [ExhibtionStartDate, setExhibitionStartDate] = useState([]);
  const [winner, setWinner] = useState([]);
  const [winnerList, setwinnerList] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [cmsContentCheck, setCmsContentCheck] = useState("Preview");
  const searchInput = React.useRef(null);
  const [form2] = Form.useForm();
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Artwork Id",
      dataIndex: "docId",
      ellipsis: true,
      width: 300,
      ...getColumnSearchProps("docId"),
    },
    {
      title: "Artwork",
      dataIndex: "img_url",
      width: 200,
      height: 200,
      // width: 300,
      render: (text, record, id) => {
        return (
          <div>
            <Image width={150} height={150} src={record.img_url} />
          </div>
        );
      },
      // ...getColumnSearchProps('img_url')
    },
    {
      title: "Artwork Name",
      dataIndex: "artwork_name",
      width: 300,
      ...getColumnSearchProps("artwork_name"),
    },
    {
      title: "Winner?",
      dataIndex: "winner_artworks",
      width: 160,
      height: 160,
      render: (text, record, id) => {
        return (
          <div>
            {record?.winning_contest?.includes(
              localStorage.getItem("contestId")
            ) ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  className="side-circle"
                  style={{
                    // background: record?.next_month > 0 || record?.in_exhibition > 0 ? '#f50' : 'green',
                    height: "18px",
                    width: "18px",
                    display: "block",
                    marginBottom: "8px",
                    marginRight: "15px",
                    borderRadius: "100%",
                  }}
                ></span>
                <Tag
                  icon={<CheckOutlined />}
                  color="green"
                  onClick={() => {
                    winner?.length > 0
                      ? message.error("Winner is already Declared")
                      : handleArtworkFeedHide(record, "No");
                  }}
                >
                  Winner
                </Tag>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  className="side-circle"
                  style={{
                    // background: record?.next_month > 0 || record?.in_exhibition > 0 ? '#f50' : 'green',
                    height: "18px",
                    width: "18px",
                    display: "block",
                    marginBottom: "8px",
                    marginRight: "15px",
                    borderRadius: "100%",
                  }}
                ></span>
                <Tag
                  icon={<PlusOutlined />}
                  color="magenta"
                  onClick={() => {
                    winner?.length > 0
                      ? message.error("Winner is already Declared")
                      : handleArtworkFeedShow(record, "Yes");
                  }}
                >
                  Winner?
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "UserId",
      dataIndex: "user_id",
      width: 300,
      ellipsis: true,
      ...getColumnSearchProps("user_id"),
    },
    {
      title: "User name",
      dataIndex: "user_name",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Caption",
      dataIndex: "caption",
      width: 120,
      ellipsis: true,
      ...getColumnSearchProps("artwork_name"),
    },
    // {
    //   title: 'Artwork Name',
    //   dataIndex: 'artwork_name',
    //   render: (text, record, index) =>
    //     userContestList.length >= 1 ? (
    //       <div style={{
    //         display: "flex",
    //         flexwrap: "wrap"
    //       }}>

    //         <Button type="link success" onClick={() => openCmsPageModal(record)}><EditOutlined style={{ fontSize: '18px' }} /></Button>

    //       </div>
    //     ) : null,
    // }
    {
      title: "Details",
      dataIndex: "details",
      width: 100,
      render: (text, record, index) =>
        userContestList.length >= 1 ? (
          <div
            style={{
              display: "flex",
              flexwrap: "wrap",
            }}
          >
            <a
              href={`${webUrl?.postDetailsURl}/${record.docId}`}
              target="_blank"
            >
              <Button type="link success">
                <EyeOutlined style={{ fontSize: "20px" }} />
              </Button>
            </a>
          </div>
        ) : null,
    },
  ];
  useEffect(() => {
    DetailListing();
    getWinnerDataforbutton();
    buttonsetting();
    // EmailTesting();
  }, []);
  useEffect(()=>{
    getWinnerDataforbutton();
  },[winnerData])
  const handleTableChange = (pagination, sorter) => {
    setPagination(pagination);
  };
  const buttonsetting = async () => {
    const winning_data = await firestore
      .collection(
        REACT_APP_PRODUCTION_MODE == "development" ? "dev_contest" : "contest"
      )
      .doc(localStorage.getItem("contestId"))
      .get();
    if (winning_data.exists) {
      let winArtwork = winning_data.data();
      if (winArtwork?.winning_artworks)
      {
        setWinner(winArtwork?.winning_artworks);
      }
      setExhibitionStartDate(winArtwork?.submission_end_period);
    }
  };
  const handleArtworkFeedShow = async (data, flag) => {
    try {
      setLoading(true);
      const doc1 = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
        )
        .doc(data?.docId);
      doc1.update({
        winning_contest: firebase.firestore.FieldValue.arrayUnion(
          localStorage.getItem("contestId")
        ),
      });
      const doc2 = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_contest" : "contest"
        )
        .doc(localStorage.getItem("contestId"));
      doc2.update({
        admin_winning_artwork: firebase.firestore.FieldValue.arrayUnion(
          data?.docId
        ),
      });

      setTimeout(() => {
        DetailListing();
      }, 2000);

      setLoading(false);
      message.success("Sucessfully updated");
    } catch (error) {
      message.error("something went to wrong");
    }
  };
  const handleArtworkFeedHide = async (data) => {
    try {
      setLoading(true);
      const doc1 = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
        )
        .doc(data?.docId);
      doc1.update({
        winning_contest: firebase.firestore.FieldValue.arrayRemove(
          localStorage.getItem("contestId")
        ),
      });
      const doc2 = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_contest" : "contest"
        )
        .doc(localStorage.getItem("contestId"));
      doc2.update({
        admin_winning_artwork: firebase.firestore.FieldValue.arrayRemove(
          data?.docId
        ),
      });
      setTimeout(() => {
        DetailListing();
      }, 2000);
      setLoading(false);
      message.success("Sucessfully updated");
    } catch (error) {
      message.error("something went to wrong");
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  // const handleFeedChangeAll = async (flag) => {
  //   try {
  //     setLoading(true)
  //     await Promise.all(hasSelected.map(async (dataId) => {
  //       const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : 'contest').doc(localStorage.getItem("contestId"))
  //       doc.update({
  //         winning_artworks: firebase.firestore.FieldValue.arrayUnion(dataId)
  //       })
  //     }))
  //     await Promise.all(hasSelected.map(async (dataId) => {
  //       const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(dataId).get()
  //       if (doc.exists) {
  //         let userData = doc.data();
  //         const userDetails = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(userData?.user_id)
  //         userDetails.update({
  //           is_winner: true
  //         })
  //       }

  //     }))

  //     await Promise.all(hasSelected.map(async (dataId) => {
  //       const userDetails = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(dataId).get()
  //       let userdata = userDetails.data()
  //       const winnerDetails = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_awards' : 'awards').doc();
  //       winnerDetails.set({
  //         artwork_id: dataId,
  //         user_id: userdata?.user_id,
  //         contest_id: localStorage.getItem("contestId"),
  //         declare_on: firebase.firestore.FieldValue.serverTimestamp()
  //       })
  //     }))
  //     await Promise.all(hasSelected.map(async (dataId) => {
  //       const userDetails = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(dataId)
  //       userDetails.update({
  //         winning_artwork: firebase.firestore.FieldValue.arrayUnion(localStorage.getItem("contestId"))
  //       })

  //     }))

  //     setHasSelected([])
  //     message.success('Sucessfully updated')
  //   } catch (error) {
  //     setLoading(false)
  //     message.error('something went to wrong')
  //   }
  // }

  const openCmsPageModal = (values) => {
    setInitialValues(null);
    setInitialValues(values);
    setCmsContent(values?.content);
    setIsModalVisible(true);
    setCmsContentCheck("EditContent");
    form2.setFieldsValue(values);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getFooterDetails = () => {
    return <label>Total Records Count is {userContestList.length}</label>;
  };
  // const DetailListing = async () => {
  //   try {
  //     setLoading(true);
  //     const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : 'contest').doc(localStorage.getItem("contestId")).get()
  //     if (userData.exists) {
  //       let userDetails = userData.data()
  //       let temparr = []
  //       for (const item of userDetails?.participated_artworks) {
  //         const artwork = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(item).get()
  //         if (artwork.exists) {
  //           let artworkdata = artwork.data();
  //           artworkdata.docId = item;
  //           const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(artworkdata.user_id).get()
  //           if (userData.exists) {
  //             artworkdata.user_name = userData.data()?.user_name
  //           }
  //           temparr.push(artworkdata)
  //         }
  //         else {
  //           console.log("error")
  //         }
  //       }
  //       setUserContestList(temparr);
  //       setLoading(false);

  //     }
  //     else {
  //       console.log("error"
  //       )
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // }
  const DetailListing = async () => {
    setLoading(true);
    const response = await Service.makeAPICall({
      methodName: Service.postMethod,
      api_url: Service.getArtworkSubmissionDetails,
      body: {
        check_mode: REACT_APP_PRODUCTION_MODE,
        query: localStorage.getItem("contestId"),
      },
    });

    if (response?.data.status == true) {
      if (response?.data) {
        let posts = [];
        await Promise.all(
          response?.data?.searchData.map(async (doc) => {
            let postdata = doc;
            let user = await firestore
              .collection(
                REACT_APP_PRODUCTION_MODE == "development"
                  ? "dev_users"
                  : "users"
              )
              .where("user_id", "==", postdata.user_id)
              .get();
            if (user.docs.length > 0) {
              {
                user.docs[0].data().user_name
                  ? (postdata.user_name = user.docs[0].data().user_name)
                  : (postdata.user_name = "no userName");
              }
              postdata.user_name = user.docs[0].data().user_name;
              posts.push(postdata);
              setUserContestList(posts);
              setwinnerList(false);

            }
          })
        );
      }
    } else {
      console.log("error");
    }
    setLoading(false);
  };
  const winnerListing = async () => {
    setLoading(true);
    const response = await Service.makeAPICall({
      methodName: Service.postMethod,
      api_url: Service.getContestWinnerList,
      body: {
        check_mode: REACT_APP_PRODUCTION_MODE,
        query: localStorage.getItem("contestId"),
        winner : winner.length == 0 ? true : false
      },
    });

    if (response?.data.status == true) {
      if (response?.data) {
        let posts = [];
        await Promise.all(
          response?.data?.searchData.map(async (doc) => {
            let postdata = doc;
            let user = await firestore
              .collection(
                REACT_APP_PRODUCTION_MODE == "development"
                  ? "dev_users"
                  : "users"
              )
              .where("user_id", "==", postdata.user_id)
              .get();
            if (user.docs.length > 0) {
              {
                user.docs[0].data().user_name
                  ? (postdata.user_name = user.docs[0].data().user_name)
                  : (postdata.user_name = "no userName");
              }
              postdata.user_name = user.docs[0].data().user_name;
              posts.push(postdata);
              setwinnerList(true);
              setUserContestList(posts);
            }
          })
        );
      }
    } else {
      console.log("error");
    }
    setLoading(false);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected([]);
      if (selectedRowKeys?.length <= 50) {
        setHasSelected(selectedRowKeys);
      } else {
        message.error("Please select maximum 50 data");
      }
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
  };
  const handleOk = async () => {
    setLoading(true);
    let userId = [];
    await Promise.all(
      winnerData.map(async (dataId) => {
        const doc = await firestore
          .collection(
            REACT_APP_PRODUCTION_MODE == "development"
              ? "dev_contest"
              : "contest"
          )
          .doc(localStorage.getItem("contestId"));
        doc.update({
          winning_artworks: firebase.firestore.FieldValue.arrayUnion(dataId),
          is_winner: true,
        });

        const doc1 = await firestore
          .collection(
            REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
          )
          .doc(dataId)
          .get();
        if (doc1.exists) {
          let userData = doc1.data();
          const userDetails = await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development" ? "dev_users" : "users"
            )
            .doc(userData?.user_id);
          userDetails.update({
            is_winner: true,
          });
        }
        const userDetails = await firestore
          .collection(
            REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
          )
          .doc(dataId)
          .get();
        let userdata = userDetails.data();
        userId.push(userdata.user_id);
        const winnerDetails = await firestore
          .collection(
            REACT_APP_PRODUCTION_MODE == "development" ? "dev_awards" : "awards"
          )
          .doc();
        winnerDetails.set({
          artwork_id: dataId,
          user_id: userdata?.user_id,
          contest_id: localStorage.getItem("contestId"),
          is_deleted: false,
          declare_on: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const userDetails1 = await firestore
          .collection(
            REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
          )
          .doc(dataId);
        userDetails1.update({
          winning_artwork: firebase.firestore.FieldValue.arrayUnion(
            localStorage.getItem("contestId")
          ),
        });

        setIsModalVisible(false);

        setTimeout(() => {
          DetailListing();
        }, 2000);
        setLoading(false);
        buttonsetting();
      })
    );
    EmailTesting();
    console.log("API called")
  };
  // const EmailTesting = async () => {
  //   try {
   
  //     const userData = await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE == "development" ? "dev_contest" : "contest"
  //       )
  //       .doc(localStorage.getItem("contestId"))
  //       .get();
  //     if (userData.exists) {
  //       let userDetails = userData.data();
  //       for (const item of userDetails?.participated_users) {
  //         setLoading(true)
  //         await Service.makeAPICall({
  //           methodName: Service.postMethod,
  //           api_url: Service.winnerArtworkEmail,
  //           body: {
  //             contest_id: localStorage.getItem("contestId"),
  //             user_id: item,
  //             check_mode: REACT_APP_PRODUCTION_MODE,
  //           },
  //         });
  //         setLoading(false)
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const EmailTesting = async () => {
    try {
      const userData = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE === "development" ? "dev_contest" : "contest"
        )
        .doc(localStorage.getItem("contestId"))
        .get();
      console.log("🚀 ~ EmailTesting ~ userData:", userData.data());
  
      if (userData.exists) {
        let userDetails = userData.data();
        console.log("🚀 ~ EmailTesting ~ userDetails:", userDetails);
  
        setLoading(true);
        await Service.makeAPICall({
          methodName: Service.postMethod,
          api_url: Service.winnerArtworkEmail,
          body: {
            contest_id: localStorage.getItem("contestId"),
            user_id: userDetails.participated_users, 
            check_mode: REACT_APP_PRODUCTION_MODE, 
            // check_mode : 'development'
          },
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  


  const getWinnerData = async () => {
    const userData = await firestore
      .collection(
        REACT_APP_PRODUCTION_MODE == "development" ? "dev_contest" : "contest"
      )
      .doc(localStorage.getItem("contestId"))
      .get();
    if (userData.exists) {
      let data = userData.data();
      if(data?.admin_winning_artwork)
      {
      setWinnerData(data?.admin_winning_artwork);
      }
      setIsModalVisible(true);
    }
  };
  const getWinnerDataforbutton = async () => {
    const userData = await firestore
      .collection(
        REACT_APP_PRODUCTION_MODE == "development" ? "dev_contest" : "contest"
      )
      .doc(localStorage.getItem("contestId"))
      .get();
    if (userData.exists) {
      let data = userData.data();
      if(data?.admin_winning_artwork)
      {
      setWinnerData(data?.admin_winning_artwork);
      }
      // setIsModalVisible(true);
    }
  };
  const handleWinner = async () => {
    try {

      setLoading(true);
      await Promise.all(
        hasSelected.map(async (dataId) => {
          const doc1 = await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
            )
            .doc(dataId);
          doc1.update({
            winning_contest: firebase.firestore.FieldValue.arrayUnion(
              localStorage.getItem("contestId")
            ),
          });
          const doc2 = await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development"
                ? "dev_contest"
                : "contest"
            )
            .doc(localStorage.getItem("contestId"));
          doc2.update({
            admin_winning_artwork:
              firebase.firestore.FieldValue.arrayUnion(dataId),
          });
        })
      );

      setTimeout(() => {
        DetailListing();
      }, 2000);
      setLoading(false);
      setHasSelected([]);
    } catch (error) {
      setLoading(false);
      message.error("something went to Wrong");
    }
  };
  const sendMail = async () => {
    try {
      const contestData = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE === "development" ? "dev_contest" : "contest"
        )
        .doc(localStorage.getItem("contestId"))
        .get();
  
      console.log("🚀 ~ file: ContestSubmission.js:830 ~ sendMail ~ contestData:", contestData);
  
      try {
        if (contestData.exists) {
          let allUserIds = [];
          const userCollection = firestore.collection(REACT_APP_PRODUCTION_MODE === "development" ? "dev_users" : "users");
          
          // Get all user IDs
          const allUsers = await userCollection.get();
          allUsers.forEach((doc) => {
            allUserIds.push(doc.id);
          });
  
          const sendUserId = contestData.data().participated_users || [];

          const userId = allUserIds.filter((userId) => !sendUserId.includes(userId));
  
          console.log("🚀 ~ file: ContestSubmission.js ~ userId:", userId);
  
          for (const item of userId) {
            await Service.makeAPICall({
              methodName: Service.postMethod,
              api_url: Service.submissionEndsoonMail,
              body: {
                contest_id: localStorage.getItem("contestId"),
                user_id: item,
                check_mode: REACT_APP_PRODUCTION_MODE,
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  
  
  
  
  const handleWinnerRemove = async () => {
    try {
      setLoading(true);
      await Promise.all(
        hasSelected.map(async (dataId) => {
          const doc1 = await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
            )
            .doc(dataId);
          doc1.update({
            winning_contest: firebase.firestore.FieldValue.arrayRemove(
              localStorage.getItem("contestId")
            ),
          });
          const doc2 = await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development"
                ? "dev_contest"
                : "contest"
            )
            .doc(localStorage.getItem("contestId"));
          doc2.update({
            admin_winning_artwork:
              firebase.firestore.FieldValue.arrayRemove(dataId),
          });
        })
      );

      setHasSelected([]);
      setTimeout(() => {
        DetailListing();
      }, 2000);

      setLoading(false);
      message.success("successfully updated");
    } catch (error) {
      setLoading(false);
      message.error("something went to wrong");
    }
  };
  return (
    <>

      {loader == false ? (
        <Card title="Submissions">
          <Button
            onClick={() => getWinnerData()}
            disabled={
              winner?.length > 0 ||
              moment(todayDate) < ExhibtionStartDate ||
              hasSelected.length > 0
            }
            className="ant-btn-primary"
          >
            Submit Selection
          </Button>
           {/* <Button onClick={() =>sendMail() }className="ant-btn-primary">Send Email</Button>  */}

          <Popconfirm title="Sure to Make Winner?" onConfirm={handleWinner}>
            <Tag
              icon={<CheckOutlined />}
              color="green"
              hidden={!hasSelected.length > 0 || winner?.length > 0}
            >
              Winner
            </Tag>
          </Popconfirm>
          <Popconfirm
            title="Sure to Remove Winner?"
            onConfirm={handleWinnerRemove}
          >
            <Tag
              icon={<QuestionOutlined />}
              color="magenta"
              hidden={!hasSelected.length > 0 || winner?.length > 0}
            >
              Winner
            </Tag>
          </Popconfirm>
          <Modal
            visible={IsModalVisible}
            footer={false}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            className="contest-modal-submission"
          >
            <div className="contest-submit-data">
              <h4>
                Are you sure that you want to submit this selection? This
                submission is final and will trigger a series of e-mail to the
                participants
              </h4>
              <button onClick={handleOk} className="ant-btn ant-btn-primary">
                Submit selection
              </button>
            </div>
          </Modal>
          <>
            <Button
              onClick={() => winnerListing()}
              hidden={winnerData?.length == 0  ? true : false}
              disabled ={!winnerList ? false : true}

              className="ant-btn-primary"
            >
              Winner List
            </Button>
            <Button
              onClick={() => DetailListing()}
              hidden={winnerData?.length == 0  ? true : false}
              disabled ={winnerList ? false : true}
              className="ant-btn-primary"
            >
              Submission List
            </Button>
          </>

          <Table
            className="gx-table-responsive"
            columns={columns}
            rowSelection={{ ...rowSelection }}
            rowKey={(userContestList) => userContestList.docId}
            dataSource={[...userContestList]}
            pagination={pagination}
            onChange={handleTableChange}
            footer={getFooterDetails}
          />
        </Card>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
export default ContestSubmission;
