import React, { useState, useEffect, useCallback } from "react"
import { Input, Card, Modal, Button, Row, Col, Form, Space, Upload, DatePicker, Select, Switch, Carousel, message } from 'antd';
import { firestore, firebase, storage } from "../../firebase/firebase";
import { useForm } from 'react-hook-form';
import { EditOutlined } from '@ant-design/icons';
import ShowMoreText from 'react-show-more-text';
import moment from "moment";
import { Link } from "react-router-dom";
import CircularProgress from "../CircularProgress/index";
import { toInteger } from 'lodash-es';
const { Option } = Select
const { Meta } = Card
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { REACT_APP_PRODUCTION_MODE } = process.env;

const ExhibitionGalleriesList = (props) => {
  const [initialValues, setInitialValues] = useState(null)
  const [IsModalVisible, setIsModalVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);
  const [galleries, setGalleries] = useState([])
  const [file, setFile] = useState(null);
  const [date, setDate] = useState([]);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [loader, setLoading] = useState(false);
  const [exhibitionPeriod, setExhibitionPeriod] = useState('');
  const { register } = useForm();
  const [form2] = Form.useForm();
  const gallery_name = form2.getFieldValue('gallary_name')

  useEffect(() => {
    try {
      setLoading(true)
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : 'exhibition_galleries')
        .orderBy('create_at', 'desc')
        .onSnapshot((querySnapshot) => {
          const galleries = querySnapshot.docs.map((doc) => {
            let userdata = doc.data();
            userdata.docId = doc.id;
            return userdata;
          });
          setGalleries(galleries);
        });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }

  }, []);

  const validator = async (rule, value) => {
    if (rule.field === 'gallary_name') {
      if (value && !(/^[a-zA-Z0-9 ]+$/.test(value))) {
        return Promise.reject('Please remove special character');
      } else {
        return Promise.resolve();
      }
    }
    if (rule.field === 'exhibition_period') {

      if (date == null) {
        return Promise.reject('Please input your exhibition period');
      }
      else {
        return Promise.resolve();
      }
    }
    else {
      return Promise.resolve();
    }
  }

  const currentMonthData = (value) => {
    setExhibitionPeriod(value)
    const date = new Date();
    const firstDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('Do-MMMM-YYYY').toString();
    const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0)).format('Do-MMMM-YYYY').toString();
    const monthData = moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('MMMM');
    const yearData = moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('YYYY');
    setDate([firstDay, lastDay]);
    setMonth(monthData)
    setYear(yearData)
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview) 
    setPreviewImage(true)

  }

  const handleChange = (info) => {

  }

  const customUpload = () => {
    return (new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: 'image/jpeg'
        }
        const fileName = form2.getFieldValue('gallary_name')
        const storageRef = storage.ref();
        const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_exhibition-galleries/${fileName}/gallary-image` : `exhibition-galleries/${fileName}/gallary-image`).put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(gallery_name).then((url) => {
              if (!!url) {
                resolve(url);
              }
            })
          }
        );

      } catch (e) {
        console.log(e);
        reject(e);
      }
    }))
  };


  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const url = await customUpload();
      values.gallary_image = file == null ? values.gallary_image : url
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : "exhibition_galleries").doc(values.docId).update({
        gallary_name: values.gallary_name,
        city: values.city,
        country: values.country,
        exhibition_start_period: date[0],
        exhibition_end_period: date[1],
        unlimited_exhibition: exhibitionPeriod == 'Unlimited' ? true : false,
        gallary_image: values.gallary_image,
        exhibition_limit: toInteger(values.exhibition_limit),
        caption: values.caption,
        update_at: firebase.firestore.FieldValue.serverTimestamp()
      })
      setIsModalVisible(false);
      setLoading(false);
      message.success('Successfully Update data');
      return props.history.push({
        pathname: `exhibition-galleries-list`,
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error('Something went to wrong');
    }
  };


  const handleCancel = useCallback(() => {
    form2.resetFields();
    setDate([])
    setIsModalVisible(false);
    setExhibitionPeriod('')
  }, [form2]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const openUserModal = (values) => {

    setExhibitionPeriod('')
    setDate([])
    setMonth(null)
    setYear(null)
    setExhibitionPeriod(values.unlimited_exhibition == true ? "Unlimited" : 'Exhibition Date Set')
    setMonth(values.month)
    setYear(values.year)
    setDate([values.exhibition_start_period, values.exhibition_end_period]);
    setInitialValues(values)
    form2.setFieldsValue(values);
    setIsModalVisible(true)
  }

  const executeOnClick = (isExpanded) => {

  }

  const exGalleryStartEnd = async (e, gallaryDoc) => {
    if (e == true) {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : "exhibition_galleries").doc(gallaryDoc).update({ exhibition_current: 'on', is_add: true })
      message.success('Successfully Update data');
    } else {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : "exhibition_galleries").doc(gallaryDoc).update({ exhibition_current: 'off', is_add: false })
      message.success('Successfully Update data');
    }
  }

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days');
  };

  return (
    <>
      {loader == false ?
        <Card title="Exhibition Galleries">
          <div style={{ textAlign: "right", paddingBottom: "10px" }}>
            <Link to="/add-exhibition-galleries"><Button type="primary">Add Exhibition Gallery</Button></Link>
          </div>
          <div className="gx-main-content">
            {galleries.length == 0 ? <div style={{ textAlign: 'center' }}>No Data</div> :
              <Row>
                {galleries.map((gallary, index) => (
                  <Col xl={8} lg={12} md={12} sm={24} xs={12} key={index}>
                    <Card
                      className="gallery-list"
                      hoverable
                      cover={<Carousel autoplay>
                        <img alt="image" src={gallary.gallary_image} />
                        {/* <img alt="image" src={gallary.gallary_image} /> */}
                      </Carousel>}
                      actions={[
                        <Button type="link success" onClick={() => {
                          openUserModal(gallary);
                        }}>
                          <EditOutlined key="edit" />
                        </Button>,
                        // <Button type="link success" onClick={() => {
                        //   galleryDelete(gallary.docId);
                        // }}>
                        //   <DeleteOutlined key='delete' />
                        // </Button>,
                        <Switch defaultChecked={gallary.exhibition_current == 'on' ? true : false} checkedChildren="on" unCheckedChildren="off" onChange={(value) => exGalleryStartEnd(value, gallary.docId)} />
                      ]}
                    >
                      <Meta title={gallary.gallary_name + ' ' + ('(' + gallary.exhibition_limit + ')')} style={{ paddingBottom: "8px" }} />
                      <Meta description={`${gallary.city}/${gallary.country}`} style={{ paddingBottom: "8px" }} />
                      <Meta description={`${gallary.exhibition_start_period} to ${gallary.exhibition_end_period}`} style={{ paddingBottom: "8px" }} />

                      <ShowMoreText
                        more='Show more'
                        less='Show less'
                        anchorClass='my-anchor-css-class'
                        onClick={() => executeOnClick()}
                        expanded={false}
                        width={1}
                      >
                        <Meta description={gallary.caption} />
                      </ShowMoreText>
                    </Card>
                  </Col>))}
              </Row>
            }
          </div>

          <Modal
            title="Update Gallery"
            visible={IsModalVisible}
            okText="Update"
            onOk={false}
            onCancel={handleCancel}
            footer={null}
          >
            <Form layout="vertical"
              {...formItemLayout}
              form={form2}
              onFinish={onSubmit}
              initialValues={{ ...initialValues }}
            >

              <Form.Item name="docId" hidden={true}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Gallery Name"
                name="gallary_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your gallary name',
                  },
                  { max: 30, message: 'Gallary name must be maximum 30 characters.' },
                  { validator: validator }
                ]}
              >
                <Input ref={register} />
              </Form.Item>

              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input your city",
                  },
                  { max: 30, message: 'City must be maximum 30 characters.' },

                ]}
              >
                <Input ref={register} />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please input your country name",
                  },
                  { max: 30, message: 'Country name must be maximum 30 characters.' },

                ]}
              >
                <Input ref={register} />
              </Form.Item>

              <Form.Item
                label="Exhibition Period"
                name="exhibition_period"
                rules={[
                  { validator: validator }
                ]}

              >
                <Select
                  size="large"
                  disabled
                  defaultValue={exhibitionPeriod == 'Unlimited' ? 'Unlimited' : 'Exhibition Date Set'}
                  onSelect={(value) => value == 'Unlimited' ? currentMonthData(value) : setExhibitionPeriod(value) && setDate([])}
                >
                  <Option value='Exhibition Date Set'>Exhibition Date Set</Option>
                  <Option value='Unlimited'>Unlimited</Option>
                </Select>
                <div style={{ paddingTop: 10 }}>
                  {
                    exhibitionPeriod == 'Unlimited' ?
                      <span>Exhibition Peroid : {date?.[0] + ' to ' + date?.[1]}</span> : null
                  }
                  {
                    exhibitionPeriod == 'Exhibition Date Set' ?
                      <span>
                        <RangePicker
                          disabled
                          style={{ width: 400 }}
                          format="Do-MMMM-YYYY"
                          disabledDate={disabledDate}
                          defaultValue={exhibitionPeriod == 'Exhibition Date Set' && initialValues.unlimited_exhibition == false ? [moment(date?.[0], 'Do-MMMM-YYYY'), moment(date?.[1], 'Do-MMMM-YYYY')] : null}
                          onChange={(value, dateString) => { setDate(exhibitionPeriod == 'Unlimited' || value == null ? null : dateString); setMonth(value == null ? null : dateString[0].split(" ")[(dateString[0].split(" ")).length - 1]); setYear(value == null ? null : value[0]?._d.getFullYear().toString()); }}
                        /></span> : null
                  }
                </div>
              </Form.Item>
              <Form.Item
                label="Exhibition Limit"
                name="exhibition_limit"
                rules={[
                  {
                    required: true,
                    message: 'Please input your exhibition limit',
                  }
                ]}
              >
                <Input type="number" ref={register} />
              </Form.Item>
              <Form.Item
                label="Gallery Caption"
                name="caption"
                rules={[
                  { max: 500, message: 'Gallary caption must be maximum 500 characters.' },
                ]}
              >
                <TextArea rows={4} ref={register} />
              </Form.Item>

              <Form.Item
                label="Exhibition Gallery Image"
                name="gallary_image"
                rules={[
                  {
                    required: true,
                    message: 'Please input your gallary image',
                  }
                ]}
              >
                <Space direction="vertical" size={12}>
                  <Upload
                    name="gallary_image"
                    listType="picture-card"
                    onRemove={() => setFile(null)}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    customRequest={({ onSuccess, file }) =>
                    (onSuccess('ok'),
                      setFile(file))}
                  >
                    {file == null ? <img src={initialValues?.gallary_image} alt="avatar" style={{ width: 80, height: 80 }} /> : null}
                  </Upload>
                </Space>

              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button id="addbutton" type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={image} />
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
    </>


  )

}
export default ExhibitionGalleriesList;