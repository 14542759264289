import React, { useState, useEffect } from "react"
import { Modal, Button, message } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserArtVerification = (props) => {
  const [artworkList, setArtworkList] = useState([]);
  const [userart, setUserart] = useState();
  const [loader, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const ID = localStorage.getItem('doc_Id')

  useEffect(() => {
    try {
      setLoading(true)
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
        .where('user_id', '==', ID)
        .where('type', '==', 'artwork')
        .where('status', '==', 'pending')
        .onSnapshot((querySnapshot) => {
          const artverificatiion = querySnapshot.docs.map((doc) => {
            let artverificatiiondata = doc.data();
            artverificatiiondata.docId = doc.id;
            return artverificatiiondata;
          });
          setArtworkList(artverificatiion);

        });
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)

    }

  }, []);

  const updateUserApproval = async () => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : "posts").where('user_id', '==', ID).where('type', '==', 'artwork').where('status', '==', 'pending')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.update({ status: "approved", valid_image: true, update_at: firebase.firestore.FieldValue.serverTimestamp() });
          });
        })
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', ID)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            await artworkApprovedNotification(doc.data(), ID)
            await doc.ref.update({ artwork_status: true, onBoardPhase2: false , update_at: firebase.firestore.FieldValue.serverTimestamp()});
          });
        })
      setLoading(false)
      message.success('Successfully Updated');
    } catch (error) {
      setLoading(false)
      console.log(error);
      message.error('Something went to wrong');
    }
  }


  const updateUserReject = async (reportmessage) => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : "posts").where('user_id', '==', ID).where('type', '==', 'artwork').where('status', '==', 'pending')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.update({ status: "rejected", update_at: firebase.firestore.FieldValue.serverTimestamp() });
          });
        })

      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', ID).get()
        .then(async function (querySnapshot) {
          await querySnapshot.forEach(async function (doc) {
            await artworkRejectedNotification(doc.data(), ID, reportmessage);
          });
        })
      setLoading(false)
      message.success('Successfully Rejected');
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  }

  useEffect(() => {
    try {
      setLoading(true);
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(ID)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const documentData = doc.data();
            setUserart(documentData);
          }
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const artworkApprovedNotification = async (userData, userId) => {
    try {

      const artworkNotification = {
        token: userData?.token,
        type: '0',
        body: userData?.artwork_status == false ? 'Congratulations, your artworks have been accepted by the ARTBOXY curators!' : 'Your post has been reviewed by the ARTBOXY Team! It has been accepted and is now visible on your profile.',
        body_spanish: userData?.artwork_status == false ? "¡Enhorabuena, sus obras han sido aceptadas por los curadores de ARTBOXY!" : "Tu post ha sido revisado por el equipo de ARTBOXY. Ha sido aceptada y ahora es visible en tu perfil.",
        body_german: userData?.artwork_status == false ? "Herzlichen Glückwunsch, deine Kunstwerke wurden von unseren ARTBOXY Kuratoren akzeptiert!" : "Dein Beitrag wurde vom ARTBOXY Team geprüft! Er wurde angenommen und ist nun in deinem Profil sichtbar.",
        body_italian: userData?.artwork_status == false ? "Congratulazioni, la tua opera è stata accettata dai nostri curatori di ARTBOXY!" : "Il tuo post è stato esaminato dal team di ARTBOXY! È stato accettato ed è ora visibile sul tuo profilo.",
        body_french: userData?.artwork_status == false ? "Félicitations, vos œuvres ont été acceptées par les curateurs d'ARTBOXY !" : "Votre message a été revu par l'équipe ARTBOXY ! Il a été accepté et est maintenant visible sur votre profil.",
        title: 'Verification',
        title_spanish: 'Verificación',
        title_german: 'Verifikation',
        title_italian: 'Verifica',
        title_french: 'Vérification',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userId,
        post_id: "",
        check_mode: REACT_APP_PRODUCTION_MODE
      };

      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: artworkNotification
      });

      if (userData?.artwork_status == false) {
        await Service.makeAPICall({
          methodName: Service.postMethod,
          api_url: Service.artworkVerificationEmail,
          body: { user_id: userId, check_mode: REACT_APP_PRODUCTION_MODE }
        })
      }
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const artworkRejectedNotification = async (userData, userId, reportmessage) => {
    try {
      const artworkNotification = {
        token: userData?.token,
        type: '0',
        body: `Your post has been reviewed by the ARTBOXY Team! It has been declined and will not be visible on your profile. Reason: ${reportmessage}`,
        body_spanish: `Tu post ha sido rechazado por el equipo de ARTBOXY y no se mostrará en tu perfil. Razón : ${reportmessage}`,
        body_german: `YDein Post wurde von dem ARTBOXY Team abgelehnt und wird nicht auf Deinem Profil gezeigt. Grund : ${reportmessage}`,
        body_italian: `Il tuo post è stato rifiutato dal team di ARTBOXY e non verrà visualizzato sul tuo profilo. Motivo : ${reportmessage}`,
        body_french: `Votre post a été refusé par l'équipe ARTBOXY et ne sera pas affiché sur votre profil. Raison : ${reportmessage}`,
        title: 'Verification',
        title_spanish: 'Verificación',
        title_german: 'Verifikation',
        title_italian: 'Verifica',
        title_french: 'Vérification',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userId,
        post_id: "",
        check_mode: REACT_APP_PRODUCTION_MODE
      };
      let rejectDetails = {
        ...userData,
        ...artworkNotification
      }
      await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.rejectArtworkEmail,
        body: { user_id: userId, check_mode: REACT_APP_PRODUCTION_MODE, reject_details: rejectDetails }
      })
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: artworkNotification
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  return (
    <>
      {loader == false ?
        <>
          {artworkList.length >= 1 ?
            <div className="art-varification-block">
              <div className="art-varification-heading">
                <h4>{userart?.user_name}</h4>
              </div>
              <div className="art-varification-content">
                <div className="ant-row" style={{ rowGap: 0 }}>
                  <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-10 ant-col-lg-10 ant-col-xl-16">
                    <div className="ant-row" style={{ rowGap: 0 }}>
                      {artworkList.map((arts, index) =>
                      (
                        <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-8" key={index}>
                          <div className="art-varification-heading">
                            <h6>{arts?.create_at.toDate().toLocaleTimeString('en-US') + ' ' + arts?.create_at.toDate().toDateString()}</h6>
                          </div>
                          <div className="ant-card ant-card-bordered ant-card-hoverable">
                            <div className="ant-card-cover">
                              <img alt="techniques image" src={arts.img_url} />
                            </div>
                            <div className="ant-card-body card-desc">
                              <div className="ant-card-meta">
                                <div className="ant-card-meta-detail">
                                  <div className="ant-card-meta-title">Title : {arts.artwork_name}</div>
                                  <div className="ant-card-meta-description">Technique : {arts.display_technique}</div>
                                  <div className="ant-card-meta-description">Status : {arts.valid_image === true ? "Appropriate" : <span style={{ color: 'red' }}>Inappropriate</span>}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8 ant-col-xl-6">

                    <div className="art-button">

                      <button type="button" className="ant-btn ant-btn-primary button" onClick={() => updateUserApproval()}>Accept</button>
                      <button type="button" className="ant-btn ant-btn-primary button button-reject" onClick={() => setIsModalVisible(true)}>Reject</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            :
            <div className="art-varification-block">
              <div className="art-varification-heading">
                No Pending Artwork
              </div>
            </div>
          }
          <Modal title="Reason.." visible={isModalVisible} footer={false} onCancel={() => setIsModalVisible(false)}>
            <p><b>why are you this post reject?</b></p><br />
            <Button onClick={() => {
              updateUserReject('Violation of Community Guidelines');
              setIsModalVisible(false)
            }}>
              Violation of Community Guidelines</Button>
            <Button onClick={() => {
              updateUserReject('Bad Photo Quality - Artwork is cut off');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Artwork is cut off</Button>
              <Button onClick={() => {
              updateUserReject('Image is upside down / sideways');
              setIsModalVisible(false)
            }}>
              Image is upside down / sideways</Button>
              <Button onClick={() => {
              updateUserReject('Multiple artworks in the same photo');
              setIsModalVisible(false)
            }}>
              Multiple artworks in the same photo</Button>
              <Button onClick={() => {
              updateUserReject('Bad Photo Quality - blurry / flash');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - blurry / flash</Button>
            <Button onClick={() => {
              updateUserReject('Bad Photo Quality - Wall/Furniture/Person is visible Less');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Wall/Furniture/Person is visible
              Less</Button>
            <Button onClick={() => {
              updateUserReject('Bad Photo Quality - Higher resolution required');
              setIsModalVisible(false)
            }}>
              Bad Photo Quality - Higher resolution required</Button>
            <Button onClick={() => {
              updateUserReject('Text is visible on the artwork');
              setIsModalVisible(false)
            }}>
              Text is visible on the artwork</Button>
            <Button onClick={() => {
              updateUserReject('Not an artwork');
              setIsModalVisible(false)
            }}>Not an artwork</Button>
            <Button onClick={() => {
              updateUserReject('Quality of art is not sufficient');
              setIsModalVisible(false)
            }}>Quality of art is not sufficient</Button>
            <Button onClick={() => {
              updateUserReject('Commercials');
              setIsModalVisible(false)
            }}>Commercials</Button>
            <Button onClick={() => {
              updateUserReject('Plagiarism');
              setIsModalVisible(false)
            }}>Plagiarism</Button>
            <Button onClick={() => {
              updateUserReject('etc..');
              setIsModalVisible(false)
            }}>etc..</Button>
          </Modal>
        </>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      }
    </>
  )

}
export default UserArtVerification;