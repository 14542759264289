import React, { useEffect, useState } from 'react';
import { Input, Select, Button, Form, Card, Space, Upload, Modal, message } from 'antd';
import { firestore, firebase, storage } from '../../firebase/firebase'
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const AddBannerCard = (props) => {
  const [loader, setLoading] = useState(false);
  const [technique, setTechnique] = useState([])
  const [form2] = Form.useForm();

  const [file, setFile] = useState();
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);

  const createUserDocument = async (values) => {
    // get a reference to the Firestore document
    if (technique.length == 0) {
      const bannerId = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : 'home_categories').doc().id
      const url = await customUpload(bannerId);
      values.banner_image = url;
      // create user object
      const addgallery = {
        type: 'banner',
        docId: bannerId,
        title: '',
        banner_image: values.banner_image,
        create_at: firebase.firestore.FieldValue.serverTimestamp(),
        update_at: firebase.firestore.FieldValue.serverTimestamp()
      };
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : 'home_categories').doc(bannerId).set(addgallery)
    } else {
      const url = await customUpload(technique[0].docId);
      values.banner_image = url;
      // create user object
      const addgallery = {
        banner_image: values.banner_image,
        update_at: firebase.firestore.FieldValue.serverTimestamp()
      };
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : 'home_categories').doc(technique[0].docId).update(addgallery)
      // write to Cloud Firestore
    }
  };


  const onSubmit = async (values) => {
    try {
      setLoading(true)
      await createUserDocument(values);
      setLoading(false)
      message.success('Successfully Update Banner card ');
      return props.history.push({
        pathname: `/banner-card`,
      });
    }
    catch (error) {
      console.log(error);
    }

  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview) 
    setPreviewImage(true)

  }

  const handleChange = (info) => {

  }

  const customUpload = (docId) => {
    return (new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: 'image/jpeg'
        }
        const storageRef = storage.ref();
        const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_home_categories/${docId}/image` : `home_categories/${docId}/image`).put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL('banners').then((url) => {
              if (!!url) {
                resolve(url);
              }
            })
          }
        );

      } catch (e) {
        console.log(e);
        reject(e);
      }
    }))
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };


  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  useEffect(() => {
    setLoading(true)
    firestore
      .collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : 'home_categories')
      .onSnapshot((querySnapshot) => {
        let techniques = []
        querySnapshot.docs.map((doc) => {
          if (doc.data().type == 'banner') {
            let techniquesdata = doc.data();
            techniquesdata.docId = doc.id
            techniques.push(techniquesdata)
          }
        });
        setTechnique(techniques);
      });
    setLoading(false)
  }, []);


  return (
    <div>
      {loader == false ?
        <Card className="gx-card" title="Update Banner Card">
          <Form layout="vertical"
            {...formItemLayout}
            form={form2}
            onFinish={onSubmit}
          >
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Banner Image"
              name="banner_image"
              rules={[
                {
                  required: true,
                  message: 'Please input your banner image!',
                }
              ]}
            >
              <Space direction="vertical" size={12}>
                <Upload
                  name="banner_image"
                  listType="picture-card"
                  maxCount={1}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  customRequest={({ onSuccess, file }) =>
                  (onSuccess('ok'),
                    setFile(file))}
                >
                  {file == null ? <img src={technique[0]?.banner_image} alt="avatar" /> : null}
                </Upload>
              </Space>

            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button id="addbutton" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>

          </Form>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
      <Modal
        visible={previewImage}
        footer={null}
        onCancel={() => setPreviewImage(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={image} />
      </Modal>
    </div>
  );
}

export default AddBannerCard;
