import axios from "axios";
import { message } from "antd";

const { REACT_APP_ADMIN_URL_FUNCTION_API } = process.env;
export default class Service {
  static API_URL = REACT_APP_ADMIN_URL_FUNCTION_API;

  static API_Call_Counter = 0;
  static incre_API_Call_Counter = () => this.API_Call_Counter++;
  static decre_API_Call_Counter = () =>
    (this.API_Call_Counter =
      this.API_Call_Counter > 0 ? this.API_Call_Counter - 1 : 0);

  static error_message = "Something went wrong!";
  static error_message_key = "error_message_key";

  static message_containner = [];
  static add_message = (text) => {
    var index = this.message_containner.findIndex((x) => x === text);
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      this.message_containner.push(text);
    }
    return index;
  };
  static remove_message = (message) =>
    (this.message_containner = this.message_containner.filter(
      (m) => m !== message
    ));
  static messageError = (msg) => {
    const index = this.add_message(msg);
    if (index === -1) {
      message.error(msg).then(() => {
        this.remove_message(msg);
      });
    }
  };

  static postMethod = "POST";
  static getMethod = "GET";
  static putMethod = "PUT";
  static deleteMethod = "DELETE";
  static headers = {
    accept: "application/json",
    "content-type": "application/json",
  };

  static sendAdminNotification = "/sendAdminNotification";
  static sendUserNotification = "/sendUserNotification";
  static exhibitionImageZipConvert = "/exhibitionImageZipConvert";
  static suspendAccount = "/suspendAccount";
  static exhibitionPhotographEmail = "/exhibitionPhotographEmail";
  static artworkVerificationEmail = "/artworkVerificationEmail";
  static rejectArtworkEmail = "/rejectArtworkEmail";
  static deleteArtworkEmail = "/deleteArtworkEmail";
  static serarchUserDetail = "/serarchUserDetail";
  static serarchArtworkDetail = "/serarchArtworkDetail";
  static serarchArtworkDetailByID = "/serarchArtworkDetailByID";
  static getContestWinnerList = "/getContestWinnerList";
  static serarchBlogDetail = "/serarchBlogDetail";
  static serarchBlogDetailByID = "/serarchBlogDetailByID";
  static getArtworkDetailscount = "/getArtworkDetailscount";
  static getUserDetails = "/getUserDetails";
  static getArtworkDetailsBytechnique = "/getArtworkDetailsBytechnique";
  static getArtworkDetails = "/getArtworkDetails";
  static winnerArtworkEmail = "/winnerArtworkEmail";
  static getBlogDetails = "/getBlogDetails";
  static serarchUserDetailByID = "/serarchUserDetailByID";
  static submissionEndsoonMail = "/submissionEndsoonMail";
  static getArtworkSubmissionDetails = "/getArtworkSubmissionDetails";
  static everyFirstOfMonthUsersPointAddDev =
    "/everyFirstOfMonthUsersPointAddDev";
  // new api
  static getArtVerificationData = "/getArtVerificationData";
  static artworkVerificationAccept = "/artworkVerificationAccept";
  static artworkVerificationReject = "/artworkVerificationReject";

  static getArtworkGalleries = "/getArtworkGalleries";
  static getArtworkExhibitionImage = "/getArtworkExhibitionImage";
  static getContestList = "/getContestList";

  static getExhibitionMonthsList = "/getExhibitionMonthsList"; //photograh list after exhibition
  static getExhibitionPhotographList = "/getExhibitionPhotographList";

  static userWisemonthlistEPhotograph = "/userWisemonthlist";
  static userExhibitionPhotographs ="/userExhibitionPhotographs";
  static getExhibitionpoints="/getExhibitionPoints";
  static editExhibitionPoints="/editExhibitionPoints"
  static loginNumber = ["+411928374650"];

  static async makeAPICall({
    props,
    methodName,
    api_url,
    body,
    params,
    options,
  }) {
    api_url = this.API_URL + api_url;

    //request interceptor to add the auth token header to requests
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers = {
            accept: "application/json",
            "content-type": "application/json",
            authorization: "Bearer " + accessToken,

            ...options,
          };
        } else {
          config.headers = {
            accept: "application/json",
            "content-type": "application/json",
            ...options,
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //response interceptor to refresh token on receiving token expired error
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem("refreshToken");
        if (
          refreshToken &&
          error?.response?.status === 403 &&
          !originalRequest._retry
        ) {
          if (originalRequest.url.includes("/refreshToken")) {
            return Promise.reject(error);
          }
          originalRequest._retry = true;
          try {
            const url = Service.API_URL + Service.refreshToken;
            const response = await axios.post(url, {
              refreshToken: refreshToken,
            });
            if (response.status === 200 && response.data.authToken) {
              localStorage.setItem(
                "accessToken",
                response.data.authToken.accessToken
              );
              localStorage.setItem(
                "refreshToken",
                response.data.authToken.refreshToken
              );
              const res = await axios(originalRequest);
              return res;
            } else {
              return Promise.reject(response);
            }
          } catch (e) {
            return Promise.reject(e);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    if (methodName === this.getMethod) {
      if (params) {
        api_url = api_url + "?" + params;
      }
      try {
        const response = await axios.get(api_url);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.postMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.post(api_url, body, options);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.putMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.put(api_url, body, options);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.deleteMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.delete(api_url, { data: body });
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
  }

  static logOut(props) {
    props.logOutHandler();
    props.history.push("/login");
  }

  static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  static objectToFormData = function (obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;

    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property]) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }
        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          this.objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  };
}
