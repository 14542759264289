import React, { useState, useEffect, useCallback } from "react"
import { Input, Card, Modal, Button, Row, Col, Form, Space, Upload, message } from 'antd';
import { firestore, firebase, storage } from "../../firebase/firebase";
import { useForm } from 'react-hook-form';
import { EditOutlined } from '@ant-design/icons';
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const { Meta } = Card

const TechniquesDetails = (props) => {
  const [initialValues, setInitialValues] = useState(null)
  const [IsModalVisible, setIsModalVisible] = useState(false)
  const [technique, setTechnique] = useState([])
  const [loader, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(null);
  const { register } = useForm();
  const [form2] = Form.useForm();


  useEffect(() => {
    try {
      setLoading(true)
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : 'home_categories')
        .onSnapshot(async (querySnapshot) => {
          let techniques = []
          await Promise.all(querySnapshot.docs.map((doc) => {
            if (doc.data().type == 'techniques') {
              let techniquesdata = doc.data();
              techniquesdata.docId = doc.id;
              techniques.push(techniquesdata);
            }
          }));
          setTechnique(techniques);
        });
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
    }
  }, []);


  // function beforeUpload(file) {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 5;
  //   if (!isLt2M) {

  //     message.error('Image must smaller than 5MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview) 
    setPreviewImage(true)

  }

  const handleChange = (info) => {

  }

  const customUpload = () => {
    return (new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: 'image/jpeg'
        }
        const fileName = form2.getFieldValue('techniques_type')
        const storageRef = storage.ref();
        const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_home_categories/${fileName}/technique-image` : `home_categories/${fileName}/technique-image`).put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            const fileName = form2.getFieldValue('techniques_type')
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(fileName).then((url) => {
              if (!!url) {
                resolve(url);
              }
            })
          }
        );

      } catch (e) {
        console.log(e);
        reject(e);
      }
    }))
  };

  const onSubmit = async (tech) => {
    try {
      setLoading(true)
      const url = file == null ? null : await customUpload()
      tech.image_url = file == null ? tech?.image_url : url
      tech.title = tech.techniques_type
      tech.route_path = tech.techniques_type.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_home_categories' : "home_categories").doc(tech?.docId).update({
        ...tech,
        update_at: firebase.firestore.FieldValue.serverTimestamp()

      })
      message.success('Successfully Update data');
      setLoading(false)
      setIsModalVisible(false)
      setFile(null)
      return props.history.push({
        pathname: `/techniqueslist`,
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };



  const handleCancel = () => {
    form2.resetFields();
    setIsModalVisible(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };


  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };


  const openUserModal = (tech) => {
    setInitialValues(tech)
    setIsModalVisible(true)
    form2.setFieldsValue(tech);

  }

  return (
    <>
      {loader == false ?
        <Card title="Techniques">
          <div style={{ textAlign: "right", paddingBottom: "10px" }}>
            <Button type="primary" href="/addtechniques">Add Techniques</Button>
          </div>
          <div className="gx-main-content">
            {technique.length == 0 ? <div style={{ textAlign: 'center', width: '100%', display: 'block' }}>No Data</div> :
              <Row>
                {technique.map((tech, index) => (
                  <Col xl={8} lg={12} md={12} sm={24} xs={12} key={index}>
                    <Card
                      hoverable
                      cover={<img alt="techniques image" src={tech.image_url} />}
                      actions={[
                        <Button className="technique" type="link success" onClick={() => {
                          openUserModal(tech);
                        }}>
                          <EditOutlined key="edit" />
                        </Button>
                      ]}
                    >
                      <Meta title={tech.techniques_type} />
                    </Card>
                  </Col>))}
              </Row>
            }
          </div>
          <Modal
            title="Update Techniques"
            visible={IsModalVisible}
            okText="Update"
            onOk={false}
            onCancel={handleCancel}
            footer={null}
          >


            <Form layout="vertical"
              {...formItemLayout}
              form={form2}
              onFinish={onSubmit}
              initialValues={{ ...initialValues }}
            >

              <Form.Item name="docId" hidden={true}>
                <Input />
              </Form.Item>

              {/* <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your title!',
                  }
                ]}
              >
                <Input size="large" />
              </Form.Item> */}

              <Form.Item
                label="Techniques Type"
                name="techniques_type"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your techniques type!',
                  },
                  { max: 30, message: 'Techniques type must be maximum 30 characters.' },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Techniques Image"
                name="image_url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your techniques image!',
                  }
                ]}
              >
                <Space direction="vertical" size={12}>
                  <Upload
                    name="image_url"
                    listType="picture-card"
                    onRemove={() => setFile(null)}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    customRequest={({ onSuccess, file }) =>
                    (onSuccess('ok'),
                      setFile(file))}
                  >
                    {file == null ? <img src={initialValues?.image_url} alt="avatar" style={{ width: 80, height: 80 }} /> : null}
                  </Upload>
                </Space>

              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button id="addbutton" type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={image} />
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      }
    </>
  )

}
export default TechniquesDetails;