import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { withRouter } from "react-router-dom"
import { firestore } from '../../firebase/firebase';
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserProfile = (props) => {
  const [admin, setAdmin] = useState([]);
  const dispatch = useDispatch();


  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => props.history.push('/adminprofile')}>My Profile</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );
  // useEffect(() => {
  //   const usersRef = firestore.collection('admin').doc(localStorage.getItem('user_id')).get()
  //   const unsubscribe = usersRef.onSnapshot((querySnapshot) => {
  //     const setAdmin = querySnapshot.docs.map((doc) => doc.data());
  //     setAdmin(admin);
  //   });
  //   return unsubscribe;
  // }, []);

  useEffect(() => {
    const docRef = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin').doc(localStorage.getItem('user_id'));
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const admin = doc.data();
        setAdmin(admin);
        // form.setFieldsValue(documentData);
      }
    });
    return unsubscribe;
  }, []);
  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={admin?.profile_url}
          className="gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="gx-avatar-name">{admin?.full_name || ' '}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
      </Popover>
    </div>

  )
};

export default withRouter(UserProfile);
