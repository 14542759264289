import { firebase } from "@firebase/app";
import '@firebase/auth'
import 'firebase/firestore';
import 'firebase/storage';
const { REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID } = process.env

// Initialize Firebase
const config = {
  apiKey: REACT_APP_API_KEY ?? "AIzaSyDhz_3051fep8lSSq7-rH3bHUwjuDGBagA",
  authDomain: REACT_APP_AUTH_DOMAIN ?? "artboxy-1df96.firebaseapp.com",
  projectId: REACT_APP_PROJECT_ID ?? "artboxy-1df96",
  storageBucket: REACT_APP_STORAGE_BUCKET ?? "artboxy-1df96.appspot.com",
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID ?? "609727568682",
  appId: REACT_APP_APP_ID ?? "1:609727568682:web:d93e68feba919e60c55c53",
  measurementId: REACT_APP_MEASUREMENT_ID ?? "G-9SL2W89SPD"
};

firebase.initializeApp(config);
const auth = firebase.auth();

// const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();


// const database = firebase.database();
const firestore = firebase.firestore();
const storage = firebase.storage();
const snapshot = firebase.firestore();

export {
  // database,
  auth,
  firestore,
  storage,
  firebase,
  snapshot
  // facebookAuthProvider,
};

export default firebase