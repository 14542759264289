import qs from 'qs';

import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Carousel, message } from 'antd';
import { firestore, storage, firebase } from '../../firebase/firebase';
import CircularProgress from "../CircularProgress/index";
import { ArrowDownOutlined } from '@ant-design/icons';
import Service from '../../service';
import axios from 'axios'
import moment from 'moment';
const { Meta } = Card
const { REACT_APP_PRODUCTION_MODE } = process.env;

const ExhibitionArtwork = (props) => {
  const [galleries, setGalleries] = useState([])
  const [loader, setLoading] = useState(false);

  useEffect(()=>{
    getArtworkGalleries()
  },[])

  const getArtworkGalleries = async () => {
    try {
      setLoading(true);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getArtworkGalleries,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
        },
      });
      if (response.data.status) {
        setGalleries(response.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  }; 
  const zipDownloadCheck = async (gallaryData) => {
    try {
      setLoading(true)
      let pathName = `artwork-Exhibition/${gallaryData.gallary_name}/${gallaryData.month}_${gallaryData.year}`
      const zipDataCheck = await firestore.collection('zip_process').where('gallary_id', '==', gallaryData.docId).where('requested_path', '==', pathName).get()

      if (!zipDataCheck.empty) {
        let data = zipDataCheck.docs[0]?.data()
        if (data?.httpurl) {
          var element = document.createElement("a");
          element.href = data?.httpurl
          // element.download = "image.jpg";
          element.click();
        }
      } else {
        let gallaryName = gallaryData.gallary_name.split(" ").join("_")

        const zipProcessId = await firestore.collection('zip_process').doc().id
        const zip_name = `${gallaryName}_${gallaryData.month}_${gallaryData.year}_${moment(new Date()).format('DDMMYYYYhhmmss')}.zip`

        await await firestore.collection('zip_process').doc(zipProcessId).set({
          requested_path: pathName,
          zip_name: zip_name,
          gallary_id: gallaryData.docId,
          httpurl: '',
          error: '',
          is_error: false,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp()
        })
        var data = {
          'path': pathName,
          'zip_name': zip_name,
          'gallary_id': gallaryData?.docId,
          'zip_process_id': zipProcessId
        };
        var config = {
          method: 'post',
          url: 'https://ziphandler.artboxy.com/start-process',
          // headers: {
          //   'Content-Type': 'application/x-www-form-urlencoded',
          //    'Access-Control-Allow-Origin' : "*"
          // },
          data: data
        };
        axios(config)
          .then(function (response) {
            if (response.data) {
              message.success(response.data?.message)
            }
            return props.history.push('/exhibition-artworks')
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      getArtworkGalleries();
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong')
    }
  }

  return (
    <Card title="Exhibition Artworks">
      {loader == false ?
        <div>
          {galleries.length > 0 ?
            <Row>
              {galleries.map((gallary, index) => (
                <Col xl={8} lg={12} md={12} sm={24} xs={24} key={index}>

                  <Card
                    hoverable
                    cover={<Carousel autoplay>
                      <img alt="image" src={gallary.gallary_image}
                        onClick={() => props.history.push({ pathname: '/exhibition-artworks-image', state: gallary })}
                      />
                    </Carousel>}
                    actions={[
                      <span style={{ color: '#af0612', textAlign: 'center' }}>
                        {gallary?.status}
                      </span>,
                      <Button type="link success" onClick={() => {
                        zipDownloadCheck(gallary);
                      }}>
                        <ArrowDownOutlined key='download' />
                      </Button>
                    ]}
                    className="artwork-exhibit"
                  >
                    <Meta title={gallary.gallary_name + ' ' + ('(' + gallary?.[`${gallary.month}_${gallary.year}`] + ')')} style={{ paddingBottom: "8px" }} />
                  </Card>
                </Col>))}
            </Row>
            : <div style={{ textAlign: 'center' }}>No Data</div>
          }
        </div>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
    </Card>
  )
}

export default ExhibitionArtwork;