import React, { useState, useEffect } from "react";
import { Card, Row, message, Modal, Image, Col } from "antd";
import { firestore } from "../../firebase/firebase";
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
const { REACT_APP_PRODUCTION_MODE } = process.env;

export default function UserExhibitionPhotography() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [loader, setLoading] = useState(false);
  const ID = localStorage.getItem("doc_Id");
  useEffect(() => {
    // setLoading(true);
    // firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_photographs' : 'exhibition_photographs').where('user_id', '==', ID)
    //     .onSnapshot((querySnapshot) => {
    //         const photos = querySnapshot.docs.map((doc) => {
    //             let photosdata = doc.data();
    //             photosdata.docId = doc.id;

    //             return photosdata;
    //         });
    //         setPhotosList(photos);
    //     });
    // setLoading(false);
    getmonthwiseData();
  }, []);
  const getmonthwiseData = async () => {
    try {
      setLoading(true);
      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.userWisemonthlistEPhotograph,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          user_id: ID,
        },
      });
      if (response.data.status) {
        setCardData(response.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };
  const getExhitbitionPhotos = async (item) => {
    let monthYear = item.split("_");
    try {
      setLoading(true);
      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.userExhibitionPhotographs,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          user_id: ID,
          month: monthYear[0],
          year: monthYear[1],
        },
      });
      if (response.data.status) {
        setPhotos(response.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  const showModal = (item) => {
    getExhitbitionPhotos(item);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setPhotos([])
  };
  return (
    <>
      {loader == false ? (
        <div>
          <Card>
            {cardData?.length > 0 ? (
              <Row className="photo-list">
                {cardData.map((item, index) => (
                  <div
                    style={{
                      height: "200px",
                      width: "200px",
                      background: "#908164",
                      margin: "15px 15px",
                      borderRadius: "5px",
                      float: "left",
                    }}
                  >
                    <h2
                      style={{
                        display: "flex",
                        color: "#ffffff",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {item.replace("_", " ")}
                    </h2>

                    <div
                      style={{
                        background: "#ffffff",
                        color: "#000",
                        marginTop: "50%",
                      }}
                      onClick={() => showModal(item)}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginTop: "8px",
                          padding: "4px",
                        }}
                      >
                        View Photos
                      </h4>
                    </div>
                  </div>
                ))}
              </Row>
            ) : (
              <div style={{ textAlign: "center" }}>
                No Exhibition Photograph
              </div>
            )}
          </Card>
        </div>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
     
      <Modal
        title="Exhibition Photos"
        visible={isModalOpen}
        onOk={handleOk}
        width="100vh"
        footer={false}
        onCancel={handleCancel}
        style={{ overflow: "auto" }} 
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {photos.map((photo, index) => (
            <div
              key={index}
              style={{
                width: "calc(50% - 40px)",
                margin: "20px",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={photo?.imageUrl}
                alt={`Exhibition Photo ${index + 1}`}
                style={{ maxWidth: "100%", maxHeight: "400px" }}
              />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
