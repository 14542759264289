import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  Button,
  Form,
  Card,
  Space,
  Upload,
  DatePicker,
  Modal,
  Select,
  Radio,
  message, Col, Row
} from "antd";
import { firestore, firebase, storage } from "../../firebase/firebase";
import { useSelector } from "react-redux";
import CircularProgress from "../CircularProgress/index";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { toInteger } from "lodash-es";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const { REACT_APP_PRODUCTION_MODE } = process.env;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AddContest = (props) => {
  const [loader, setLoading] = useState(false);
  const [contestType, setContestType] = useState("standard");
  const [imageUrl, setImageUrl] = useState("");
  const [date, setDate] = useState([]);
  const [submissionDate, setSubmissionDate] = useState([]);
  const [submissionMonth, setSubmissionMonth] = useState(null);
  const [submissionYear, setSubmissionYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [liveMonth, setLiveMonth] = useState(null);
  const [year, setYear] = useState(null);
  const { register } = useForm();
  const [form2] = Form.useForm();
  const [file, setFile] = useState();
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);
  const [exhibitionPeriod, setExhibitionPeriod] = useState("");
  const contest_name = form2.getFieldValue("contest_name");
  const dateFormat = 'DD-MM-YYYY';


  const createUserDocument = async (values) => {
    try {
      let newdate = new Date()
      let submission_start_date = values.submission_period[0]
      let submission_end_date = values.submission_period[1]
      let exhibition_start_date = values.exhibition_period[0]
      let exhibition_end_date = values.exhibition_period[1]

      // get a reference to the Firestore document
      const url = await customUpload();
      values.contest_image = url;
      const docRef = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : "contest").doc().id
      //   let next_month_period = exhibitionPeriod == "Unlimited" ? moment(
      //     new Date(newdate.getFullYear(), newdate.getMonth() + 1, 1)
      //   )
      //     .format("DD.MM")
      //     .toString() + ' - ' + moment(
      //       new Date(newdate.getFullYear(), newdate.getMonth() + 2, 0)
      //     )
      //       .format("DD.MM")
      //       .toString()
      //     :
      //     moment(date[0], 'Do-MMMM-YYYY').format("DD.MM").toString()
      //     + ' - ' + moment(date[1], 'Do-MMMM-YYYY').format("DD.MM").toString()
      // create user object
      const addcontest = {
        contest_name: values.contest_name,
        contest_description: values.contest_description,
        link_details: values.link_details,
        link_photos: values?.link_photos ? values.link_photos : "",
        winner_2: values.winner_2,
        winner_1: values.winner_1,
        exhibition_start_period: moment.utc((exhibition_start_date).hours("00").minute("00").second("00")).valueOf(),
        exhibition_end_period: moment.utc((exhibition_end_date).hours("23").minute("55").second("00")).valueOf(),
        submission_start_period: moment.utc((submission_start_date).hours("00").minute("00").second("00")).valueOf(),
        submission_end_period: moment.utc((submission_end_date).hours("23").minute("55").second("00")).valueOf(),
        contest_image: values.contest_image,
        contest_current: "on",
        contest_type: contestType,
        is_add: true,
        is_deleted: false,
        is_winner: false,
        // caption: values.caption == undefined ? "" : values.caption,
        create_at: firebase.firestore.FieldValue.serverTimestamp(),
        update_at: firebase.firestore.FieldValue.serverTimestamp(),
      };
      // write to Cloud Firestore
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_contest' : "contest").doc(docRef).set(addcontest);
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const onChange = (e) => {
    setContestType(e.target.value);
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  
  const validator = async (rule, value) => {

    if (rule.field === 'gallary_name') {
      if (value && !(/^[a-zA-Z0-9 ]+$/.test(value))) {
        return Promise.reject('Please remove special character');
      } else {
        return Promise.resolve();
      }
    }
   
    if (rule.field === 'exhibition_period') {
      if (value == null){
        return Promise.reject('Please input your exhibition period');
      }
      
      else if((rule.field == 'submission_period' != null || undefined || []) )
      {
        if (moment(date[0])?.valueOf() < (moment(submissionDate[1])?.valueOf()) && moment(submissionDate[1])?.valueOf() ) {
          return Promise.reject('Your exhibition Start Date can not be less than Submission end date')
        }
      }
      else {
        return Promise.resolve();
      }
    }
  


    
    else {
      return Promise.resolve();
    }

  }

  const currentMonthData = (value) => {
    setExhibitionPeriod(value);
    const date = new Date();
    const firstDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    )
      .format("DD-MMMM-YYYY")
      .toString();
    const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0))
      .format("DD-MMMM-YYYY")
      .toString();
    const monthData = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    )
      .format("MMMM")
      .toString();
    const yearData = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    )
      .format("YYYY")
      .toString();
    const livemonthData = moment(
      new Date(date.getFullYear(), date.getMonth() + 2, 1)
    )
      .format("MMMM")
      .toString();
    setDate([firstDay, lastDay]);
    setMonth(monthData);
    setYear(yearData);
    setLiveMonth(livemonthData);
  };


  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await createUserDocument(values);
      message.success("Successfully Add Contest");
      setLoading(false);
      return props.history.push({
        pathname: `/contest-list`,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview)
    setPreviewImage(true);
  };

  const handleChange = (info) => { };

  const customUpload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: "image/jpeg",
        };
        const fileName = form2.getFieldValue("contest_name");
        const storageRef = storage.ref();
        const uploadTask = storageRef
          .child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_contest/${fileName}/contest-image` : `contest/${fileName}/contest-image`)
          .put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(contest_name).then((url) => {
              if (!!url) {
                setImageUrl(url);
                resolve(url);
              }
            });
          }
        );
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined type={loader ? "loading" : "plus"} />
    </div>
  );

  return (
    <div>
      {loader == false ? (
        <Card title="Add Contest" className="add-contest-card">
          <Form
            layout="vertical"
            {...formItemLayout}
            form={form2}
            onFinish={onSubmit}
            className="contest-form"
          >
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Row>
              <Col md={24}>
                <Form.Item
                  label="Contest Name"
                  name="contest_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Contest name",
                    },
                    { max: 30, message: 'Contest name must be maximum 30 characters.' },
                    { validator: validator }
                  ]}
                >
                  <Input ref={register} />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item label="Contest Description" name="contest_description"
                  rules={[
                    { required: true, max: 100, message: 'Contest Description must be maximum 100 characters.' },
                  ]}>
                  <TextArea rows={4} ref={register} />
                </Form.Item>
              </Col>

              <Col md={24}>
                <Form.Item
                  label="Submission Period"
                  name="submission_period"
                  rules={[{
                    required: true,
                    message: 'Please Choose Submission Date!',
                }
                ]}

                >
                      <RangePicker
                        style={{ width: 400 }}
                        disabledDate={disabledDate}
                        format={dateFormat}
                        onChange={(value, dateString) => {
                          const myArray = value?.toString().split(",");
                          value == null ? setSubmissionDate([]) : 
                          setSubmissionDate(myArray);
                          form2.setFieldsValue({'exhibition_period': null},
                          form2.setFields([{ name: 'exhibition_period', disabled: true }]))
                        }}
                   
                      />
                  
                
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="Contest Exhibition Period"
                  name="exhibition_period"
                  rules={[{
                  validator: validator
                }
                ]}
                >
              
                      <RangePicker
                       format={dateFormat}
                      disabled={submissionDate?.length === 0  ? true : false}
                        style={{ width: 400 }}
                        disabledDate={submissionDate != null ? (value) => {
                          return moment(value).valueOf() < (submissionDate != null ? moment(submissionDate[1]).add(1, "day").valueOf() : "")
                        } : false}
                        onChange={(value, dateString) => {
                          const myArray = value?.toString().split(",");
                          setDate(myArray);
                        }}
                      
                      />
                  
              
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item
                  label="Link to Details"
                  name="link_details"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Link Details",
                    },
                    {
                      type: "url",
                      message: "This field must be a valid url."
                    }
                  ]}
                >
                  <Input type="text" ref={register} />
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item
                  label="Link to Photos"
                  name="link_photos"
                  rules={[
                    {
                      type: "url",
                      message: "This field must be a valid url."
                    },
                  ]}
                >
                  <Input type="text" ref={register} />
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item
                  label="Winner line 1"
                  name="winner_1"
                  rules={[
                    { required: true, max: 20, message: 'Winner line 1 must be maximum 20 characters.' },
                  ]}
                >
                  <Input type="text" ref={register} />
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item
                  label="Winner line 2"
                  name="winner_2"
                  rules={[
                    { required: true, max: 20, message: 'Winner line 2 must be maximum 20 characters.' },
                  ]}
                  
                >
                  <Input type="text" ref={register} />
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item
                  label="Contest Image"
                  name="contest_image"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Contest image",
                    },
                  ]}
                >
                  <span className="contest--image-span">
                    <Upload
                      name="gallary_image"
                      listType="picture-card"
                      maxCount={1}
                      multiple={false}
                      onPreview={handlePreview}
                      onRemove={() => setFile(null)}
                      onChange={handleChange}
                      customRequest={({ onSuccess, file }) => (
                        onSuccess("ok"), setFile(file)
                      )}
                    >
                      {<img src={imageUrl} alt="avatar" /> && uploadButton}
                    </Upload>
                  </span>
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item>
                  <Radio.Group onChange={onChange} value={contestType} name="contest_type">
                    <Radio value={"standard"}>Standard</Radio>
                    <Radio value={"premium"}>Premium Only</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={24} sm={24}>
                <Form.Item {...tailFormItemLayout}>
                  <Button id="addbutton" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
            className="add-contest-modal"
          >
            <img alt="example" style={{ width: "100%" }} src={image} />
          </Modal>
        </Card>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default AddContest;