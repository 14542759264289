import React, { useState, useEffect, useMemo } from 'react'
import { Table, Modal, Form, Button, Select, Avatar, Card, Popconfirm, Input, Space, message, Row, Col } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom';
import CircularProgress from "../CircularProgress/index";
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import Service from "../../service";
import ProfileImage from "../../assets/images/default_profile.jpg"
const { Option } = Select
const { REACT_APP_ZOHO_URL_CONTACT } = process.env

const { Search } = Input;

const UserDeatils = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [previewImage, setPreviewImage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [usersList, setUsersList] = useState([])
  const [usersListLength, setUsersListLength] = useState();
  const [hasSelected, setHasSelected] = useState('')
  const [loader, setLoading] = useState(false);
  // const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [suspendId, setSuspendId] = useState('')
  const searchInput = React.useRef(null)
  const [fieldValue, setFieldValue] = useState("")
  const [TextField, setTextField] = useState(false)
  const { REACT_APP_PRODUCTION_MODE } = process.env;
  const [isModalVisibleee, setIsModalVisibleeee] = useState(false)
  const [searchField, setSearchField] = useState('full_name')
  const [searchedText, setSearchedText] = useState("")
  const [searchUsersData, setSearchUsersData] = useState([])
  const [pagetable, setPagetable] = useState(0)
  const [isSearch, setIsSearch] = useState(false)
  const [elasticData, setData] = useState([])
  const [searchList, setSearchList] = useState([])
  let count = Math.ceil(usersListLength / 50);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })


  useEffect(() => {
    usersListingcount();
    getData();
  }, [])
  function onSearch() {

  }
  const showModal = () => {
    setIsModalVisibleeee(true);
  };
const handlepoint = async() =>{
  const response = await Service.makeAPICall({
    methodName: Service.getMethod,
    api_url: Service.everyFirstOfMonthUsersPointAddDev,
    body: {
      check_mode: REACT_APP_PRODUCTION_MODE,
    }

  });
}
  const handleInputChange = (event) => {
    let data = event.target.value;
    setSearchText(data)

  };

  const handleOk = () => {
    setIsModalVisibleeee(false);
  };
  const handleNext = async () => {
    let data = pagetable
    const getData = async () => {
      setLoading(true);
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getUserDetails,
        body: {
          from: pagetable + 50,
          size: 50,
          check_mode: REACT_APP_PRODUCTION_MODE,
          query: ''
        }

      });

      if (response?.data.status == true) {
        { setUsersList(response?.data?.searchData) }
        setLoading(false);
        setPagetable(data + 50)
      }
    }
    getData()
  }
  const handlePrevious = async () => {
    let data = pagetable
    setPagetable(data - 50)
    const getData = async () => {
      setLoading(true);
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getUserDetails,
        body: {
          from: pagetable - 50,
          size: 50,
          check_mode: REACT_APP_PRODUCTION_MODE,
          query: ''
        }

      });

      if (response?.data.status == true) {
        { setUsersList(response?.data?.searchData) }
        setLoading(false);
        setPagetable(data - 50)
      }
    }
    getData()
  }

  const getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())) {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      } else {
        return ''
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const getData = async (resetSearch) => {
    setLoading(true);
    const response = await Service.makeAPICall({
      methodName: Service.postMethod,
      api_url: Service.getUserDetails,
      body: {
        from: pagetable,
        size: 50,
        check_mode: REACT_APP_PRODUCTION_MODE,
        query: searchText ? searchText : ''
      }

    });

    if (response?.data.status == true) {
      if (response?.data?.searchData.length == 0) {
        setUsersList([])
      }
      else if (searchText == '') {
        setUsersList(response?.data?.searchData)
        setSearchList([])
      } else {
        setSearchList(response?.data?.searchData)
      }
      setLoading(false);
    }
  }
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys)
    setIsSearch(true);
  }




  const handleReset = (clearFilters) => {
    setSearchUsersData([]);
    setIsSearch(false);
    clearFilters();
    setSearchedText('');
    setSearchedColumn('');
  };

  const userNotification = async (userData, reportMsg) => {
    try {
      const artworkNotification = {
        token: userData?.token,
        type: '5',
        body: reportMsg,
        title: 'ArtBoxy',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userData?.user_id,
        post_id: '',
        check_mode: REACT_APP_PRODUCTION_MODE
      };

    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const handleDelete = async (index) => {
    try {
      setLoading(true)
      const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").doc(index).get()
      if (doc.exists) {
        await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp(), token: '' })
        await userNotification(doc.data(), 'Your account has been deleted')
      }
      setLoading(false)
      props.history.push({
        pathname: `/usersdetails`
      })
      message.success('Successfully Deleted');
    } catch (error) {
      setLoading(false)
      message.error(error);
    }

  };
  const getFooterDetails = () => {
    return (
      <div className='footer-table'>
        {(searchList.length == 0 && searchText == '' && searchedText == "" && usersList.length > 0) ? <div className='footer-labels'>
          {searchList.length == 0 ? <label>
            Total Records Count is {(searchList.length == 0) ? usersListLength : ""}
          </label> : ""}

          {searchList.length == 0 ? <label>Current page is {pagetable / 50 + 1}</label> : ""}
          {searchList.length == 0 ? <label>total pages is {count}</label> : ""}



        </div> : ""}
        {(searchList.length == 0 && searchText == '' && searchedText == "" && usersList.length > 0) ? <div className="button-class">
          {(pagetable == 0) ? '' : <Button type="primary" style={{ marginRight: "5px" }} onClick={() => handlePrevious()}>Previous</Button>}
          {
            (usersList.length < 49) ? '' : <Button type="primary" onClick={() => handleNext()}>Next</Button>
          }
        </div> : ""}
      </div>

    )
  }
  const handleTableChange = (pagination, sorter) => {
    setPagination(pagination)
  }

  const updateUserApproval = async (userData, values, suspendMessage) => {
    try {
      setLoading(true);
      if (values == 'rejected') {
        if (suspendMessage.trim().length > 60) {
          setLoading(false);
          message.error("Please must be maximum 50 characters")
          return
        }
        setIsModalVisible(false)
        const userSnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(userData?.user_id).get();
        if (userSnapshot.exists) {
          const data = userSnapshot.data();

          await Service.makeAPICall({
            methodName: Service.postMethod,
            api_url: Service.suspendAccount,
            body: { email: data.email, message: suspendMessage, check_mode: REACT_APP_PRODUCTION_MODE },
          }).then(async (response) => {
            if (response?.data?.status == true) {
              await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").doc(userData?.user_id).update({ status: 'rejected', update_at: firebase.firestore.FieldValue.serverTimestamp(), token: '' })
              setTimeout(() => {
                getData()
              }, 2000);
              await userNotification(userData, `Your account has been suspended.Reason for your account ${suspendMessage}`)

              message.success('Successfully Updated');

            }
          }).catch((e) => {
            console.log(e);
          })
        }
      } else {
        setIsModalVisible(false)
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").doc(userData?.user_id).update({ status: 'approved', is_deleted: false, update_at: firebase.firestore.FieldValue.serverTimestamp(), token: '' })
        setTimeout(() => {
          getData()
        }, 2000);
        await userNotification(userData, 'Your account has been approved')
        // const posts = await firestore.collection('posts').where('user_id', '==', id).get()
        // await Promise.all(posts.docs.map(async (post) => {
        //   await firestore.collection("posts").doc(post.id).update({ is_deleted: false, status: 'approved', update_at: firebase.firestore.FieldValue.serverTimestamp() });
        // }));
        // props.history.push({
        //   pathname: `/usersdetails`
        // })
        // await getData()

        message.success('Successfully Updated');

      }
      setTimeout(() => {
        getData()
      }, 2000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Something went to wrong');
    }
  }

  const usersListingcount = async () => {
    try {
      setLoading(true);
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').get()
      const count = querySnapshot.size;
      console.log(count);
      setUsersListLength(count);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  }

  const handleCancel = async () => {
    setSearchText('')
    const getData = async () => {
      setLoading(true);
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getUserDetails,
        body: {
          from: pagetable,
          size: 50,
          check_mode: REACT_APP_PRODUCTION_MODE,
          query: ''
        }

      });

      if (response?.data.status == true) {
        { setUsersList(response?.data?.searchData) }
        setLoading(false);
      }
    }
    getData();
    setSearchList([])
  }

  const handleCancel1 = () => {
    setPreviewVisible(false);
  }
  const getUserdata = (index) => {
    try {
      setLoading(true)
      localStorage.setItem('doc_Id', index)
      props.history.push({
        pathname: `/usersotherdetails`
      })
      setLoading(false);
    }
    catch (error) {
      setLoading(false)
      message.error(error);
    }
  }
  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'profile_url',
      width: 60,
      render: (text, record) => {

        return (
          <div>
            <Avatar size="default" src={record.profile_url == '' ? ProfileImage : record.profile_url} onClick={() => {
              setPreviewImage(record.profile_url == '' ? ProfileImage : record.profile_url)
              setPreviewVisible(true)
            }} />
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancel1}
            >
              <img alt="profile" style={{ width: '500px', height: '400px' }} src={previewImage} />
            </Modal>

          </div>

        );
      },
    }, {
      title: 'UserId',
      dataIndex: 'user_id',
      width: 150,
      ...getColumnSearchProps('user_id')
    }
    , {
      title: 'Full Name',
      dataIndex: 'full_name',
      width: 150,
      ...getColumnSearchProps('full_name'),
      render: (text, record) => {
        return (
          <div> {record?.zoho_contact_id != '' ? <a href={`${REACT_APP_ZOHO_URL_CONTACT}/${record?.zoho_contact_id}`} target='_blank'>{record?.full_name}</a> : record?.full_name}</div>
        )
      },
    }, {
      title: 'User Name',
      dataIndex: 'user_name',
      width: 150,
      ...getColumnSearchProps('user_name'),
      render: (text, record) => {
        return (
          <div> {record?.zoho_contact_id != '' ? <a href={`${REACT_APP_ZOHO_URL_CONTACT}/${record?.zoho_contact_id}`} target='_blank'>{record?.user_name}</a> : record?.user_name}</div>
        )
      },
    }, {
      title: 'E-mail',
      dataIndex: 'email',
      width: 200,
      ...getColumnSearchProps('email'),
      render: (text, record) => {
        return (
          <div> {record?.zoho_contact_id != '' ? <a href={`${REACT_APP_ZOHO_URL_CONTACT}/${record?.zoho_contact_id}`} target='_blank'>{record?.email}</a> : record?.email}</div>
        )
      },

    }, {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      width: 150,
      ...getColumnSearchProps('phoneNumber')
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription_plan',
      width: 100,
      ...getColumnSearchProps('subscription_plan'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      ...getColumnSearchProps('status'),
      render: (text, record, index) => (
        <>
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>


            <Select
              className={`select ${record.status}`}
              size="large"
              defaultValue={record.status}
              onSelect={(value) => { value == 'rejected' ? setIsModalVisible(true) : updateUserApproval(record, value); setSuspendId(record) }}
            >
              <Option value="approved" style={{ color: 'green' }}>approved</Option>
              <Option value="rejected" style={{ color: 'red' }}>rejected</Option>
              {record.status == "deleted" ?
                <Option value="deleted" disabled style={{ color: 'brown' }}>deleted</Option> : null}
            </Select>

          </div>

        </>

      )
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (text, record, index) => (
        <div style={{
          display: "flex",
          flexwrap: "wrap"
        }}>
          {record.status == 'deleted' ? null :
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.docId)}>
              <Button type="link success"><DeleteOutlined style={{ fontSize: '18px' }} /></Button>
            </Popconfirm>}
        </div>
      ),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (text, record, index) =>
        usersList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>


            <Button type="link success" onClick={() => (getUserdata(record.docId))}><EyeOutlined style={{ fontSize: '18px' }} /></Button>

          </div>
        ) : null,
    }

  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected([])
      setHasSelected(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows) => {

    },
    onSelectAll: (selected, selectedRows, changeRows) => {
    },
  };

  const checkdelete = async () => {
    try {
      setLoading(true);
      for (var i = 0; i < hasSelected.length; i++) {
        const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").doc(hasSelected[i]).get()
        if (doc.exists) {
          // const posts = await firestore.collection('posts').where('user_id', '==', hasSelected[i]).get()
          // await Promise.all(posts.docs.map(async (post) => {
          //   await firestore.collection("posts").doc(post.id).update({ is_deleted: true, update_at: firebase.firestore.FieldValue.serverTimestamp() });
          // }));
          //  await storage.ref().child(`profile-image/${index}`).delete()
          //  await storage.ref().child(`artwork-image/${index}`).delete()
          //  await storage.ref().child(`blog-image/${index}`).delete()
          await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp(), token: '' })
          await userNotification(doc.data(), 'Your account has been deleted')
        }
      }
      setLoading(false);

      props.history.push({
        pathname: `/usersdetails`
      })

      message.success('Successfully Deleted');
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error('Something went to wrong');
    }
  }

  const selector = (
    <Form.Item noStyle>
      <Select showSearch placeholder="Select a data"
        defaultValue='full_name'
        value={searchField}
        style={{ width: 150 }} onSearch={onSearch} filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={(e) => setSearchField(e)}
      >
        <Option value="user_id">UserId</Option>
        <Option value="full_name">Full Name</Option>
        <Option value="user_name">User Name</Option>
        <Option value="email">Email</Option>
        <Option value="phoneNumber">Phone Number</Option>
        <Option value="subscription_plan">Subscription</Option>
        <Option value="status"> Status</Option>
      </Select>
    </Form.Item>
  );

  const datafind = () => {
    setIsSearch(true)
    let searchdata = usersList?.filter((search) => {
      if (searchField === "full_name") {
        return search.full_name?.toString().toLowerCase().includes(searchedText.toLowerCase())
      } else if ("status" == searchField) {
        return search.status?.toString().toLowerCase().includes(searchedText.toLowerCase())
      } else if ('user_id' == searchField) {
        return search.user_id?.toString().toLowerCase().includes(searchedText.toLowerCase())
      } else if ('user_name' == searchField) {
        return search.user_name?.toString().toLowerCase().includes(searchedText.toLowerCase())
      } else if ('subscription_plan' == searchField) {
        return search.subscription_plan?.toString().toLowerCase().includes(searchedText.toLowerCase())
      } else if ('phoneNumber' == searchField) {
        return search.phoneNumber.toString().toLowerCase().includes(searchedText.toLowerCase())
      } else if ('email' == searchField) {
        return search.email.toString().toLowerCase().includes(searchedText.toLowerCase())
      }
    }
    );

    setSearchUsersData(searchdata)
    setIsModalVisibleeee(false);
  };
  const handleResetButton = () => {
    setSearchUsersData([])
  }
  const table = useMemo(() => {

    return loader == false ? <Table
      className="gx-table-responsive"
      columns={columns}
      rowSelection={{ ...rowSelection }}
      rowKey={(usersList) => usersList.docId
      }
      dataSource={
        searchList.length == 0 ? usersList : searchList}
      pagination={searchList.length == 0 ? false : pagination}
      onChange={handleTableChange}
      footer={getFooterDetails}
    />
      :
      <div className="gx-loader-view">
        <CircularProgress />
      </div>
  }, [usersList, searchList, loader, usersListLength, pagetable, pagination])

  return (
    <>

      <Card title="User Details">
        <Row>
          <Col md={16} span={24}>
            <div style={{ maxWidth: "400px", display: "flex", alignItems: "center", gap: "15px" }}>
              <Search
                placeholder="Search..."
                enterButton="Search"
                size="medium"
                onSearch={() => {
                  getData()
                  setHasSelected([])
                }}
                // onCancel={handleCancel}
                // onChange={e => handleInputChange(e)}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
              <Button
                onClick={handleCancel}
                style={{ width: 90 }}
                type="primary"
              >
                Reset
              </Button>
            </div>
          </Col>
          <Col md={8} span={24}>
            <div style={{ textAlign: "right" }}>
              <Link to="/addusers">
                <Button type="primary">Add Users</Button>
              </Link>
              {/* <Button onClick ={handlepoint}type="primary">Givepoints</Button> */}
              {hasSelected.length > 0 &&
                <Popconfirm title="Sure to delete?" onConfirm={() => checkdelete()}>
                  <Button type="danger">
                    Delete
                  </Button>
                </Popconfirm>}
              {/* <Button type="primary" onClick={showModal} >
                    Search
                  </Button> */}
            </div>
          </Col>
        </Row>
        {table}
        <div className='footer-table'>
        </div>
        <Modal title="Reason.." visible={isModalVisible} footer={false} onCancel={() => { setIsModalVisible(false); setFieldValue(''); setTextField(false) }}>
          <p><b>why are you reporting this user?</b></p><br />
          <Button onClick={() => {
            updateUserApproval(suspendId, 'rejected', 'Inappropriate content');
            setIsModalVisible(false)
          }}>
            Inappropriate content</Button>
          <Button onClick={() => {
            updateUserApproval(suspendId, 'rejected', 'Commercials');
            setIsModalVisible(false)
          }}>Commercials</Button>
          <Button onClick={() => setTextField(!TextField)}
          >Other reason</Button>
          {TextField ?
            <>
              <li>
                <div className="report-form">
                  <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                  <Button className="btn primary-btn" type="primary" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? updateUserApproval(suspendId, 'rejected', fieldValue)

                    : message.error("please fill the data")}>Send report</Button>
                </div>
              </li>
            </> : null}
        </Modal>
        {/* <Modal title="Search details" visible={isModalVisibleee} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <Form>
              <Form.Item>
                <Input placeholder="seach anything" value={searchedText} onChange={(e) => setSearchedText(e.target.value)} addonBefore={selector} />
              </Form.Item>
              <Space>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={datafind}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button size="small" onClick={handleResetButton} 
                 style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Form>
          </Modal> */}
      </Card>

    </>
  )
}



export default withRouter(UserDeatils)