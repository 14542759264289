import React from "react";
import { Card, Tabs } from "antd";
import { withRouter } from "react-router";
import ReportPostList from "../ReportPostList/ReportPostList";
import ReportPostCommentList from "../ReportPostCommentList/ReportPostCommentList";
import ReportUserList from "../ReportUserList/ReportUserList";
import DoneReportPostList from "../DoneReportPostList/DoneReportPostList";
import DoneReportUserList from "../DoneReportUserList/DoneReportUserList";
import DoneReportPostCommentList from "../DoneReportPostCommentList/DoneReportPostCommentList";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const TabPane = Tabs.TabPane;

const ReportDetails = () => {

  return (
    <Card title="Report Details">
      <Tabs defaultActiveKey="1" size={"large"}>
        <TabPane tab="Report Post Details" key="1"><ReportPostList /></TabPane>
        <TabPane tab="Report User Details" key="2"><ReportUserList /></TabPane>
        <TabPane tab="Report Post Comment Details" key="3"><ReportPostCommentList /></TabPane>
        <TabPane tab="Done Post Details" key="4"><DoneReportPostList /></TabPane>
        <TabPane tab="Done User Details" key="5"><DoneReportUserList /></TabPane>
        <TabPane tab="Done Post Comment Details" key="6"><DoneReportPostCommentList /></TabPane>
      </Tabs>
    </Card>

  )
}

export default withRouter(ReportDetails);