import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Checkbox, Modal, Image, message } from "antd";
import { firestore, firebase } from "../../firebase/firebase";
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
import { Link } from "react-router-dom";
import moment from "moment";

const { REACT_APP_PRODUCTION_MODE } = process.env;

const ArtworkVerification = (props) => {
  const [artworkList, setArtworkList] = useState([]);
  const [users, setUsers] = useState([]);
  const [loader, setLoading] = useState(false);
  const [userId, setUserId] = useState([]);
  const [userCheck, setUserCheck] = useState([]);
  const [suspendId, setSuspendId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  // const fetchArtworkList = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const querySnapshot = await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE === "development" ? "dev_posts" : "posts"
  //       )
  //       .where("type", "==", "artwork")
  //       .where("status", "==", "pending")
  //       .get();

  //     const newArtworkList = [];
  //     const userIds = new Set();

  //     querySnapshot.forEach((doc) => {
  //       const artworkDataItem = doc.data();
  //       artworkDataItem.docId = doc.id;
  //       newArtworkList.push(artworkDataItem);
  //       userIds.add(doc.data().user_id);
  //       console.log(doc.data().create_at, "===", typeof doc.data().create_at);
  //     });

  //     setUserId([...userIds]);

  //     setArtworkList(newArtworkList);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // }, []);

  useEffect(() => {
    getArtworkVerification();
  }, []);

  const handleSelectAll = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setUserCheck([]);
    } else {
      setUserCheck([...new Set(artworkList.map((li) => li.user_id))]);
    }

  };
  const handleClick = (e) => {
    console.log(userCheck,"check")
    const { id, checked } = e.target;
    console.log("🚀 ~ handleClick ~ id:", id)
    setUserCheck([...userCheck, id]);
    if (!checked) {
      setUserCheck(userCheck.filter((item) => item !== id));
    }
  };

  const getArtworkVerification = async () => {
    try {
      setLoading(true);
      setUserCheck([]);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getArtVerificationData,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          // check_mode:'development',

        },
      });
      if (response.data.status) {
        setUserId(response.data.data.userID);
        setArtworkList(response.data.data.artwork);
        setUsers(response.data.data.users);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };
  // const fetchUsers = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const querySnapshot = await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE === "development" ? "dev_users" : "users"
  //       )
  //       .get();
  //     const fetchedUsers = querySnapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       docId: doc.id,
  //     }));
  //     setUsers(fetchedUsers);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // }, []);

  // useEffect(() => {
  // fetchArtworkList();
  //   fetchUsers();
  // }, [fetchArtworkList, fetchUsers]);

  // const updateUserApproval = async (userId) => {
  //   try {
  //     setLoading(true);
  //     await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
  //       )
  //       .where("user_id", "==",userId)
  //       .where("type", "==", "artwork")
  //       .where("status", "==", "pending")
  //       .get()
  //       .then(function (querySnapshot) {
  //         console.log("🚀 ~ querySnapshot:", querySnapshot)

  //         querySnapshot.forEach(function (doc) {
  //           console.log("🚀 ~ doc:", doc)
  //           doc.ref.update({
  //             status: "approved",
  //             valid_image: true,
  //             update_at: firebase.firestore.FieldValue.serverTimestamp(),
  //           });
  //         });
  //       });
  //     await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE == "development" ? "dev_users" : "users"
  //       )
  //       .where("user_id", "==", userId)
  //       .get()
  //       .then(function (querySnapshot) {
  //         querySnapshot.forEach(async function (doc) {
  //           await artworkApprovedNotification(doc.data(), userId);
  //           await doc.ref.update({
  //             artwork_status: true,
  //             update_at: firebase.firestore.FieldValue.serverTimestamp(),
  //             onBoardPhase2: false,
  //           });
  //         });
  //       });
  //     setLoading(false);
  //     message.success("Successfully Approved");
  //     getArtworkVerification()
  //     return props.history.push({
  //       pathname: `/artworksverification`,
  //     });
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went to wrong");
  //   }
  // };
  const updateUserApproval = async (userId) => {
    try {
      setLoading(true);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.artworkVerificationAccept,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          // check_mode:'development',

          userId: [userId],
        },
      });
      if (response.data.status) {
      setLoading(true);
        message.success("Accepted Successfully");
      }
      getArtworkVerification();
      // setUserCheck([]);
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };
  const updateUserApproval1 = async () => {
    try {
      setLoading(true);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.artworkVerificationAccept,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          // check_mode:'development',

          userId: userCheck,
        },
      });
      if (response.data.status) {
      setLoading(true);
        message.success("Accepted Successfully");
      }
      getArtworkVerification();
      setUserCheck([]);
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };
  // const updateUserApproval1 = async () => {
  //   try {
  //     setLoading(true);
  //     for (var i = 0; i < userCheck.length; i++) {
  //       var snapshot = firestore
  //         .collection(
  //           REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
  //         )
  //         .where("user_id", "==", userCheck[i])
  //         .where("type", "==", "artwork")
  //         .where("status", "==", "pending");

  //       var snapshot = await snapshot.get().then(function (querySnapshot) {
  //         querySnapshot.forEach(function (doc) {
  //           doc.ref.update({
  //             status: "approved",
  //             valid_image: true,
  //             update_at: firebase.firestore.FieldValue.serverTimestamp(),
  //           });
  //         });
  //       });
  //       await firestore
  //         .collection(
  //           REACT_APP_PRODUCTION_MODE == "development" ? "dev_users" : "users"
  //         )
  //         .where("user_id", "==", userCheck[i])
  //         .get()
  //         .then(async function (querySnapshot) {
  //           querySnapshot.forEach(async function (doc) {
  //             await artworkApprovedNotification(doc.data(), userCheck[i]);
  //             await doc.ref.update({
  //               artwork_status: true,
  //               update_at: firebase.firestore.FieldValue.serverTimestamp(),
  //               onBoardPhase2: false,
  //             });
  //           });
  //         });
  //     }
  //     setLoading(false);
  //     getArtworkVerification();
  //     message.success("Successfully Approved");
  //     return props.history.push({
  //       pathname: `/artworksverification`,
  //     });
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went to wrong");
  //   }
  // };

  // const artworkApprovedNotification = async (userData, userId) => {
  //   try {
  //     const artworkNotification = {
  //       token: userData?.token,
  //       type: "0",
  //       body:
  //         userData?.artwork_status == false
  //           ? "Congratulations, your artworks have been accepted by the ARTBOXY curators!"
  //           : "Your post has been reviewed by the ARTBOXY Team! It has been accepted and is now visible on your profile.",
  //       body_spanish:
  //         userData?.artwork_status == false
  //           ? "¡Enhorabuena, sus obras han sido aceptadas por los curadores de ARTBOXY!"
  //           : "Tu post ha sido revisado por el equipo de ARTBOXY. Ha sido aceptada y ahora es visible en tu perfil.",
  //       body_german:
  //         userData?.artwork_status == false
  //           ? "Herzlichen Glückwunsch, deine Kunstwerke wurden von unseren ARTBOXY Kuratoren akzeptiert!"
  //           : "Dein Beitrag wurde vom ARTBOXY Team geprüft! Er wurde angenommen und ist nun in deinem Profil sichtbar.",
  //       body_italian:
  //         userData?.artwork_status == false
  //           ? "Congratulazioni, la tua opera è stata accettata dai nostri curatori di ARTBOXY!"
  //           : "Il tuo post è stato esaminato dal team di ARTBOXY! È stato accettato ed è ora visibile sul tuo profilo.",
  //       body_french:
  //         userData?.artwork_status == false
  //           ? "Félicitations, vos œuvres ont été acceptées par les curateurs d'ARTBOXY !"
  //           : "Votre message a été revu par l'équipe ARTBOXY ! Il a été accepté et est maintenant visible sur votre profil.",
  //       title: "Verification",
  //       title_spanish: "Verificación",
  //       title_german: "Verifikation",
  //       title_italian: "Verifica",
  //       title_french: "Vérification",
  //       sender_user_id: "",
  //       check_device: "web",
  //       receiver_user_id: userId,
  //       post_id: "",
  //       check_mode: REACT_APP_PRODUCTION_MODE,
  //     };
  //     const sendNotification = await Service.makeAPICall({
  //       methodName: Service.postMethod,
  //       api_url: Service.sendUserNotification,
  //       body: artworkNotification,
  //     });
  //     console.log(sendNotification, "sendNotificationsendNotification");

  //     if (userData?.artwork_status == false) {
  //       await Service.makeAPICall({
  //         methodName: Service.postMethod,
  //         api_url: Service.artworkVerificationEmail,
  //         body: { user_id: userId, check_mode: REACT_APP_PRODUCTION_MODE },
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went to wrong");
  //   }
  // };

  // const artworkRejectedNotification = async (
  //   userData,
  //   userId,
  //   reportmessage
  // ) => {
  //   try {
  //     const artworkNotification = {
  //       token: userData?.token,
  //       type: "0",
  //       body: `Your post has been reviewed by the ARTBOXY Team! It has been declined and will not be visible on your profile. Reason: ${reportmessage}`,
  //       body_spanish: `Tu post ha sido rechazado por el equipo de ARTBOXY y no se mostrará en tu perfil. Razón : ${reportmessage}`,
  //       body_german: `YDein Post wurde von dem ARTBOXY Team abgelehnt und wird nicht auf Deinem Profil gezeigt. Grund : ${reportmessage}`,
  //       body_italian: `Il tuo post è stato rifiutato dal team di ARTBOXY e non verrà visualizzato sul tuo profilo. Motivo : ${reportmessage}`,
  //       body_french: `Votre post a été refusé par l'équipe ARTBOXY et ne sera pas affiché sur votre profil. Raison : ${reportmessage}`,
  //       title: "Verification",
  //       title_spanish: "Verificación",
  //       title_german: "Verifikation",
  //       title_italian: "Verifica",
  //       title_french: "Vérification",
  //       sender_user_id: "",
  //       check_device: "web",
  //       receiver_user_id: userId,
  //       post_id: "",
  //       check_mode: REACT_APP_PRODUCTION_MODE,
  //     };
  //     let rejectDetails = {
  //       ...userData,
  //       ...artworkNotification,
  //     };
  //     await Service.makeAPICall({
  //       methodName: Service.postMethod,
  //       api_url: Service.rejectArtworkEmail,
  //       body: {
  //         user_id: userId,
  //         check_mode: REACT_APP_PRODUCTION_MODE,
  //         reject_details: rejectDetails,
  //       },
  //     });
  //     await Service.makeAPICall({
  //       methodName: Service.postMethod,
  //       api_url: Service.sendUserNotification,
  //       body: artworkNotification,
  //     });
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went to wrong");
  //   }
  // };

  const updateUserReject = async (userId, reportmessage) => {
    console.log("🚀 ~ updateUserReject ~ reportmessage:", reportmessage,userId)
    try {
      setLoading(true);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.artworkVerificationReject,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          // check_mode:'development',
          userId: [userId],
          reportmessage: reportmessage
        },
      });
      if (response.data.status) {
      setLoading(true);
      setIsReportModal(false)
        message.success("Rejected Successfully");
      }
      getArtworkVerification();
      setUserCheck([]);
      setUserId([])
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };
  
  const updateUserReject1 = async (reportmessage) => {
    console.log("🚀 ~ updateUserReject1 ~ reportmessage:", reportmessage)
    console.log(userId,'userId')
    console.log(userCheck,'usercheck')
    try {
      setLoading(true);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.artworkVerificationReject,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          // check_mode:'development',

          userId: userCheck,
          reportmessage: reportmessage
        },
      });
      if (response.data.status) {
      setLoading(true);
      setIsReportModal(false)
        message.success("Rejected Successfully");
      }
      getArtworkVerification();
      setUserCheck([]);
      setUserId([])
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  // const updateUserReject = async (userId, reportmessage) => {
  //   try {
  //     setLoading(true);
  //     await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
  //       )
  //       .where("user_id", "==", userId)
  //       .where("type", "==", "artwork")
  //       .where("status", "==", "pending")
  //       .get()
  //       .then(function (querySnapshot) {
  //         querySnapshot.forEach(function (doc) {
  //           doc.ref.update({
  //             status: "rejected",
  //             update_at: firebase.firestore.FieldValue.serverTimestamp(),
  //           });
  //         });
  //       });

  //     await firestore
  //       .collection(
  //         REACT_APP_PRODUCTION_MODE == "development" ? "dev_users" : "users"
  //       )
  //       .where("user_id", "==", userId)
  //       .get()
  //       .then(function (querySnapshot) {
  //         querySnapshot.forEach(function (doc) {
  //           artworkRejectedNotification(doc.data(), userId, reportmessage);
  //         });
  //       });
  //     setLoading(false);
  //     getArtworkVerification();
  //     message.success("Successfully Updated");
  //     return props.history.push({
  //       pathname: `/artworksverification`,
  //     });
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went to wrong");
  //   }
  // };

  // const updateUserReject1 = async (reportmessage) => {
  //   try {
  //     setLoading(true);
  //     for (var i = 0; i < userCheck.length; i++) {
  //       var snapshot1 = firestore
  //         .collection(
  //           REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
  //         )
  //         .where("user_id", "==", userCheck[i])
  //         .where("type", "==", "artwork")
  //         .where("status", "==", "pending");
  //       var snapshot1 = await snapshot1.get().then(function (querySnapshot) {
  //         querySnapshot.forEach(function (doc) {
  //           doc.ref.update({
  //             status: "rejected",
  //             update_at: firebase.firestore.FieldValue.serverTimestamp(),
  //           });
  //         });
  //       });
  //       await firestore
  //         .collection(
  //           REACT_APP_PRODUCTION_MODE == "development" ? "dev_users" : "users"
  //         )
  //         .where("user_id", "==", userCheck[i])
  //         .get()
  //         .then(function (querySnapshot) {
  //           querySnapshot.forEach(function (doc) {
  //             artworkRejectedNotification(
  //               doc.data(),
  //               userCheck[i],
  //               reportmessage
  //             );
  //           });
  //         });
  //     }

  //     setLoading(false);
  //     getArtworkVerification();

  //     message.success("Successfully Updated");
  //     return props.history.push({
  //       pathname: `/artworksverification`,
  //     });
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went to wrong");
  //   }
  // };



  function simpleDateConversion(timestamp) {
    let date;
    if (timestamp && typeof timestamp === "object" && "_seconds" in timestamp) {
      date = moment.unix(timestamp._seconds);
    } else if (moment(timestamp).isValid()) {
      date = moment(timestamp);
    } else {
      return "Invalid Date";
    }

    return date.format("hh:mm:ss ddd MMM DD YYYY");
  }

  return (
    <>
      {loader == false ? (
        <Card title="Art Verification">
          {userId.length > 0 ? (
            <div className="art-varification-block">
              <div className="art-varification-heading">
                <Checkbox
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    userCheck.length ==
                    [...new Set(artworkList.map((li) => li.user_id))].length
                  }
                >
                  Select All{" "}
                </Checkbox>

                {userId.length > 0 && (
                  <>
                    <Button
                      type="button"
                      className="ant-btn ant-btn-primary button"
                      disabled={userCheck.length == 0}
                      onClick={() => updateUserApproval1()}
                    >
                      Accept
                    </Button>
                    <Button
                      type="button"
                      className="ant-btn ant-btn-primary button button-reject"
                      disabled={userCheck.length == 0}
                      onClick={() => setIsReportModal(true)}
                    >
                      Reject
                    </Button>
                    <Modal
                      title=" Reason.."
                      visible={isReportModal}
                      footer={false}
                      onCancel={() => setIsReportModal(false)}
                    >
                      <p>
                        <b>Violation of Community Guidelines</b>
                      </p>
                      <br />
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Violation of Community Guidelines"
                          );
                          setIsReportModal(false);
                        }}
                      >
                        Violation of Community Guidelines
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1("Text is visible on the artwork");
                          setIsReportModal(false);
                        }}
                      >
                        Text is visible on the artwork
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Bad Photo Quality - Artwork is cut off"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Bad Photo Quality - Artwork is cut off
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Image is upside down / sideways"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Image is upside down / sideways
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Multiple artworks in the same photo"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Multiple artworks in the same photo
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Bad Photo Quality - blurry / flash"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Bad Photo Quality - blurry / flash
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Bad Photo Quality - Wall/Furniture/Person is visible Less"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Bad Photo Quality - Wall/Furniture/Person is visible
                        Less
                      </Button>

                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Bad Photo Quality - Higher resolution required"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Bad Photo Quality - Higher resolution required
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Text is visible on the artwork"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Text is visible on the artwork
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1( "Not an artwork");
                          setIsModalVisible(false);
                        }}
                      >
                        Not an artwork
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1(
                            "Quality of art is not sufficient"
                          );
                          setIsModalVisible(false);
                        }}
                      >
                        Quality of art is not sufficient
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1("Commercials");
                          setIsReportModal(false);
                        }}
                      >
                        Commercials
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1("Plagiarism");
                          setIsReportModal(false);
                        }}
                      >
                        Plagiarism
                      </Button>
                      <Button
                        onClick={() => {
                          updateUserReject1("etc..");
                          setIsReportModal(false);
                        }}
                      >
                        etc..
                      </Button>
                    </Modal>
                  </>
                )}
              </div>
              {userId.map((ID, index) => (
                <div key={index}>
                  {users.map((user, index) =>
                    ID === user?.user_id ? (
                      <div className="art-varification-block" key={index}>
                        <div className="art-varification-heading">
                          <Link to="#">
                            <h4
                              onClick={() => {
                                props.history.push({
                                  pathname: `/usersotherdetails`,
                                });
                                localStorage.setItem("doc_Id", user?.user_id);
                              }}
                            >
                              {user?.user_name}
                            </h4>
                          </Link>
                        </div>
                        <div className="art-varification-content">
                          <div className="ant-row" style={{ rowGap: 0 }}>
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-6 ant-col-lg-6 ant-col-xl-2">
                              <Checkbox
                                id={user?.user_id}
                                onChange={handleClick}
                                checked={userCheck.includes(user?.user_id)}
                              ></Checkbox>
                            </div>

                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-10 ant-col-lg-10 ant-col-xl-16">
                              <div className="ant-row" style={{ rowGap: 0 }}>
                                {artworkList.map((arts, index) =>
                                  arts?.user_id === user?.docId ? (
                                    <div
                                      className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-8"
                                      key={index}
                                    >
                                      <div className="art-varification-heading">
                                        <h6>
                                          {simpleDateConversion(
                                            arts?.create_at
                                          )}
                                        </h6>
                                      </div>

                                      <div className="ant-card ant-card-bordered ant-card-hoverable">
                                        <div className="ant-card-cover">
                                          <Image
                                            alt="techniques image"
                                            src={arts.img_url}
                                          />
                                        </div>
                                        <div className="ant-card-body card-desc">
                                          <div className="ant-card-meta">
                                            <div className="ant-card-meta-detail">
                                              <div className="ant-card-meta-title">
                                                Title : {arts.artwork_name}
                                              </div>
                                              <div className="ant-card-meta-description">
                                                Technique :{" "}
                                                {arts.display_technique}
                                              </div>
                                              <div className="ant-card-meta-description">
                                                Status :{" "}
                                                {arts.valid_image === true ? (
                                                  "Appropriate"
                                                ) : (
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Inappropriate
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                )}
                              </div>
                            </div>
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8 ant-col-xl-6">
                              <div className="art-button">
                                <Button
                                  type="button"
                                  className="ant-btn ant-btn-primary button"
                                  onClick={() =>
                                    updateUserApproval(user?.docId)
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  type="button"
                                  className="ant-btn ant-btn-primary button button-reject"
                                  onClick={() => {
                                    setIsModalVisible(true);
                                    setSuspendId(user?.docId);
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>No Pending Artworks</div>
          )}
        </Card>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}

      <Modal
        title="Reason.."
        visible={isModalVisible}
        footer={false}
        onCancel={() => setIsModalVisible(false)}
      >
        <p>
          <b>why are you this post reject?</b>
        </p>
        <br />
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Violation of Community Guidelines");
            setIsModalVisible(false);
          }}
        >
          Violation of Community Guidelines
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Text is visible on the artwork");
            setIsModalVisible(false);
          }}
        >
          Text is visible on the artwork
        </Button>
        <Button
          onClick={() => {
            updateUserReject(
              suspendId,
              "Bad Photo Quality - Artwork is cut off"
            );
            setIsModalVisible(false);
          }}
        >
          Bad Photo Quality - Artwork is cut off
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Image is upside down / sideways");
            setIsModalVisible(false);
          }}
        >
          Image is upside down / sideways
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Multiple artworks in the same photo");
            setIsModalVisible(false);
          }}
        >
          Multiple artworks in the same photo
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Bad Photo Quality - blurry / flash");
            setIsModalVisible(false);
          }}
        >
          Bad Photo Quality - blurry / flash
        </Button>
        <Button
          onClick={() => {
            updateUserReject(
              suspendId,
              " Bad Photo Quality - Wall/Furniture/Person is visible"
            );
            setIsModalVisible(false);
          }}
        >
          Bad Photo Quality - Wall/Furniture/Person is visible
        </Button>
        <Button
          onClick={() => {
            updateUserReject(
              suspendId,
              "Bad Photo Quality - Higher resolution required"
            );
            setIsModalVisible(false);
          }}
        >
          Bad Photo Quality - Higher resolution required
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Text is visible on the artwork");
            setIsModalVisible(false);
          }}
        >
          Text is visible on the artwork
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Not an artwork");
            setIsModalVisible(false);
          }}
        >
          Not an artwork
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Quality of art is not sufficient");
            setIsModalVisible(false);
          }}
        >
          Quality of art is not sufficient
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Commercials");
            setIsModalVisible(false);
          }}
        >
          Commercials
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "Plagiarism");
            setIsModalVisible(false);
          }}
        >
          Plagiarism
        </Button>
        <Button
          onClick={() => {
            updateUserReject(suspendId, "etc..");
            setIsModalVisible(false);
          }}
        >
          etc..
        </Button>
      </Modal>
    </>
  );
};
export default ArtworkVerification;
