import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  Button,
  Form,
  Card,
  Space,
  Upload,
  DatePicker,
  Modal,
  Select,
  Radio,
  message,
} from "antd";
import { firestore, firebase, storage } from "../../firebase/firebase";
import { useSelector } from "react-redux";
import CircularProgress from "../CircularProgress/index";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { toInteger } from "lodash-es";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const { REACT_APP_PRODUCTION_MODE } = process.env;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AddExhibitionGalleries = (props) => {
  const [loader, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [liveMonth, setLiveMonth] = useState(null);
  const [year, setYear] = useState(null);
  const { register } = useForm();
  const [form2] = Form.useForm();
  const [file, setFile] = useState();
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);
  const [exhibitionPeriod, setExhibitionPeriod] = useState("");
  const gallery_name = form2.getFieldValue("gallary_name");

  const createUserDocument = async (values) => {
    try {
      let newdate = new Date();
      // get a reference to the Firestore document
      const url = await customUpload();
      values.gallary_image = url;
      const docRef = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development"
            ? "dev_exhibition_galleries"
            : "exhibition_galleries"
        )
        .doc().id;
      let next_month_period =
        exhibitionPeriod == "Unlimited"
          ? moment(new Date(newdate.getFullYear(), newdate.getMonth() + 1, 1))
              .format("DD.MM")
              .toString() +
            " - " +
            moment(new Date(newdate.getFullYear(), newdate.getMonth() + 2, 0))
              .format("DD.MM")
              .toString()
          : moment(date[0], "Do-MMMM-YYYY").format("DD.MM").toString() +
            " - " +
            moment(date[1], "Do-MMMM-YYYY").format("DD.MM").toString();

      let in_exhibition_period =
        exhibitionPeriod == "Unlimited"
          ? moment(new Date(newdate.getFullYear(), newdate.getMonth(), 1))
              .format("DD.MM")
              .toString() +
            " - " +
            moment(new Date(newdate.getFullYear(), newdate.getMonth() + 1, 0))
              .format("DD.MM")
              .toString()
          : moment(date[0], "Do-MMMM-YYYY").format("DD.MM").toString() +
            " - " +
            moment(date[1], "Do-MMMM-YYYY").format("DD.MM").toString();

      // create user object
      const addgalleies = {
        gallary_name: values.gallary_name,
        city: values.city,
        country: values.country,
        month: month,
        year: year,
        live: exhibitionPeriod == "Unlimited" ? liveMonth : month,
        [`${month}_${year}`]: [],
        exhibition_start_period: date[0],
        exhibition_end_period: date[1],
        next_month_period: next_month_period,
        in_exhibition_period: in_exhibition_period,
        next_month_date: next_month_period,
        inexhibition_month_date: in_exhibition_period,
        exhibited_artwork_id: firebase.firestore.FieldValue.arrayUnion(),
        unlimited_exhibition: exhibitionPeriod == "Unlimited" ? true : false,
        gallary_image: values.gallary_image,
        exhibition_limit: toInteger(values.exhibition_limit),
        caption: values.caption == undefined ? "" : values.caption,
        is_add: true,
        exhibition_current: "on",
        create_at: firebase.firestore.FieldValue.serverTimestamp(),
        update_at: firebase.firestore.FieldValue.serverTimestamp(),
      };
      // write to Cloud Firestore
      await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development"
            ? "dev_exhibition_galleries"
            : "exhibition_galleries"
        )
        .doc(docRef)
        .set(addgalleies);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  const validator = async (rule, value) => {
    if (rule.field === "gallary_name") {
      if (value && !/^[a-zA-Z0-9 ]+$/.test(value)) {
        return Promise.reject("Please remove special character");
      } else if (value?.trim()?.length < 1) {
        return Promise.reject("Please enter Gallery Name");
      }
    }
    if ( rule.field === "city" ) {
      if (value && !/^[a-zA-Z0-9 ]+$/.test(value)) {
        return Promise.reject("Please remove special character");
      } else if (value?.trim()?.length < 1) {
        return Promise.reject("Please enter city");
      }
    }
    if (rule.field === "country") {
      if (value && !/^[a-zA-Z0-9 ]+$/.test(value)) {
        return Promise.reject("Please remove special character");
      } else if (value?.trim()?.length < 1) {
        return Promise.reject("Please enter country");
      }
    }
    
    if (rule.field === "exhibition_period") {
      if (date == null) {
        return Promise.reject("Please input your exhibition period");
      } else {
        return Promise.resolve();
      }
    }
    if (rule.field === "exhibition_limit") {
      if (value < 0) {
        return Promise.reject("Please enter valid numbers");
      } else {
        return Promise.resolve();
      }
    }
  };

  const currentMonthData = (value) => {
    setExhibitionPeriod(value);
    const date = new Date();
    const firstDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    )
      .format("Do-MMMM-YYYY")
      .toString();
    const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0))
      .format("Do-MMMM-YYYY")
      .toString();
    const monthData = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    )
      .format("MMMM")
      .toString();
    const yearData = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    )
      .format("YYYY")
      .toString();
    const livemonthData = moment(
      new Date(date.getFullYear(), date.getMonth() + 2, 1)
    )
      .format("MMMM")
      .toString();
    setDate([firstDay, lastDay]);
    setMonth(monthData);
    setYear(yearData);
    setLiveMonth(livemonthData);
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await createUserDocument(values);
      message.success("Successfully Add Gallary");
      setLoading(false);
      return props.history.push({
        pathname: `/exhibition-galleries-list`,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview)
    setPreviewImage(true);
  };

  // const handleChange = (info) => {};
  const handleChange = (info) => {
    let errorShown = false;
    if (
      info.file.type.split("/")[0] !== "image" &&
      !errorShown &&
      info.file.status === "done"
    ) {
      message.error("You can only upload image files!");
      errorShown = true;
      setFile(null);
      return false; 
    }
    setFile(info.file);
  };
  
  const customUpload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: "image/jpeg",
        };
        const fileName = form2.getFieldValue("gallary_name");
        const storageRef = storage.ref();
        const uploadTask = storageRef
          .child(
            REACT_APP_PRODUCTION_MODE == "development"
              ? `dev_exhibition-galleries/${fileName}/gallary-image`
              : `exhibition-galleries/${fileName}/gallary-image`
          )
          .put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(gallery_name).then((url) => {
              if (!!url) {
                setImageUrl(url);
                resolve(url);
              }
            });
          }
        );
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined type={loader ? "loading" : "plus"} />
    </div>
  );

  return (
    <div>
      {loader == false ? (
        <Card title="Add Exhibition Gallery">
          <Form
            layout="vertical"
            {...formItemLayout}
            form={form2}
            onFinish={onSubmit}
          >
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Gallery Name"
              name="gallary_name"
              rules={[
                {
                  required: true,
                  message: "Please input your gallary name",
                },
                {
                  max: 30,
                  message: "Gallary name must be maximum 30 characters.",
                },
                { validator: validator },
              ]}
            >
              <Input ref={register} />
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please input your city",
                },
                { max: 30, message: "City must be maximum 30 characters." },
                { validator: validator },
              ]}
            >
              <Input ref={register} />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please input your country name",
                },
                {
                  max: 30,
                  message: "Country name must be maximum 30 characters.",
                },
                { validator: validator },
              ]}
            >
              <Input ref={register} />
            </Form.Item>

            <Form.Item
              label="Exhibition Period"
              name="exhibition_period"
              rules={[{ validator: validator }]}
            >
              <Select
                size="large"
                onSelect={(value) =>
                  value == "Unlimited"
                    ? currentMonthData(value)
                    : setExhibitionPeriod(value) || setDate(null)
                }
              >
                <Option value="Exhibition Date Set">Exhibition Date Set</Option>
                <Option value="Unlimited">Unlimited</Option>
              </Select>
              <div style={{ paddingTop: 10 }}>
                {exhibitionPeriod == "Unlimited" ? (
                  <span>
                    Exhibition Peroid : {date?.[0] + " to " + date?.[1]}
                  </span>
                ) : null}
                {exhibitionPeriod == "Exhibition Date Set" ? (
                  <span>
                    <RangePicker
                      style={{ width: 400 }}
                      format="Do-MMMM-YYYY"
                      disabledDate={disabledDate}
                      onChange={(value, dateString) => {
                        setDate(value == null ? null : dateString);
                        setMonth(
                          value == null
                            ? null
                            : moment(dateString[0], "Do-MMMM-YYYY")
                                .format("MMMM")
                                .toString()
                        );
                        setYear(
                          value == null
                            ? null
                            : moment(dateString[0], "Do-MMMM-YYYY")
                                .format("YYYY")
                                .toString()
                        );
                      }}
                    />
                  </span>
                ) : null}
              </div>
            </Form.Item>

            <Form.Item
              label="Exhibition Limit"
              name="exhibition_limit"
              rules={[
                {
                  required: true,
                  message: "Please input your exhibition limit",
                },
              ]}
            >
              <Input type="number" ref={register} min={1}/>
            </Form.Item>
            <Form.Item
              label="Gallery Caption"
              name="caption"
              rules={[
                {
                  max: 500,
                  message: "Gallary caption must be maximum 500 characters.",
                },
              ]}
            >
              <TextArea rows={4} ref={register} />
            </Form.Item>

            <Form.Item
              label="Exhibition Gallery Image"
              name="gallary_image"
              rules={[
                {
                  required: true,
                  message: "Please input your gallary image",
                },
              ]}
            >
              <Space direction="vertical" size={12}>
                <Upload
                  name="gallary_image"
                  listType="picture-card"
                  maxCount={1}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  customRequest={({ onSuccess, file }) => (
                    onSuccess("ok"), setFile(file)
                  )}
                >
                  {<img src={imageUrl} alt="avatar" /> && uploadButton}
                </Upload>
              </Space>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button id="addbutton" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: "100%" }} src={image} />
          </Modal>
        </Card>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default AddExhibitionGalleries;
