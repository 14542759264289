import qs from "qs";

import React, { useEffect, useState } from "react";
import { Card, Row, Col, Checkbox, Button, message, DatePicker } from "antd";
import { firestore, firebase, storage } from "../../firebase/firebase";
import axios from "axios";
import CircularProgress from "../CircularProgress/index";
import moment from "moment";
import Service from "../../service";
import { database } from "firebase-admin";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const ExhibitionGallaryArtworks = (props) => {
  const [exhibitionArtworkList, setExhibitionArtworkList] = useState([]);
  const [loader, setLoading] = useState(false);
  const [nextLoader, setNextLoader] = useState(false);
  const [exArtIdCheck, setExArtIdCheck] = useState([]);
  const gallary_Data = props.location.state;
  const [gallery, setGallery] = useState([]);
  const [exhibitionMonthYear, setExhibitionMonthYear] = useState(
    `${gallary_Data?.month}_${gallary_Data?.year}` ?? ""
  );
  const [exhibitionArtNumber, setExhibitionArtNumber] = useState(21);
  const [exhibitionArtTotalLength, setExhibitionArtTotalLength] = useState(0);

  useEffect(() => {
    if (gallary_Data && exhibitionMonthYear) {
      ExhibitionArtworkListing();
    }
  }, [gallary_Data, exhibitionMonthYear]);
  // const ExhibitionArtworkListing = async () => {
  //     try {
  //         setLoading(true)
  //         setExhibitionArtworkList([])
  //         const exhibitionArtworkRef = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_exhibition_galleries' : 'exhibition_galleries').doc(gallary_Data?.docId).get()
  //         if (exhibitionArtworkRef.exists) {
  //             let exhibitionArtworkData = []
  //             let gallarydata = exhibitionArtworkRef.data();
  //             gallarydata.docId = exhibitionArtworkRef.id;
  //             setExhibitionArtTotalLength(gallarydata?.[exhibitionMonthYear]?.length)
  //             for (const artId of gallarydata?.[exhibitionMonthYear].slice(0, exhibitionArtNumber)) {
  //                 const post = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(artId).get();
  //                 if (post.exists) {
  //                     if (post.data().is_deleted == false) {
  //                         const postdata = post.data();
  //                         postdata.docId = post.id
  //                         exhibitionArtworkData.push(postdata)
  //                     }
  //                 }
  //             }
  //             setExhibitionArtworkList(exhibitionArtworkData);
  //         };
  //         setLoading(false)
  //     } catch (error) {
  //         console.log(error);
  //         setLoading(false)
  //     }
  // }

  const ExhibitionArtworkListing = async () => {
    try {
      setLoading(true);
      setExhibitionArtworkList([]);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getArtworkExhibitionImage,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
          //   check_mode:'development',
          monthYear: exhibitionMonthYear,
          gallery_id: gallary_Data.docId,
        },
      });
      if (response.data.status) {
        setExhibitionArtworkList(response.data.data);
        setExhibitionArtTotalLength(response.data.data.users);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  const NextExhibitionArtworkListing = async () => {
    try {
      setNextLoader(true);
      const exhibitionArtworkRef = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development"
            ? "dev_exhibition_galleries"
            : "exhibition_galleries"
        )
        .doc(gallary_Data?.docId)
        .get();
      if (exhibitionArtworkRef.exists) {
        let exhibitionArtworkData = [];
        let gallarydata = exhibitionArtworkRef.data();
        gallarydata.docId = exhibitionArtworkRef.id;
        setExhibitionArtTotalLength(gallarydata?.[exhibitionMonthYear].length);
        if (gallarydata?.[exhibitionMonthYear].length > 0) {
          for (const artId of gallarydata?.[exhibitionMonthYear].slice(
            exhibitionArtNumber,
            exhibitionArtNumber + 21
          )) {
            setExhibitionArtNumber(exhibitionArtNumber + 21);
            const post = await firestore
              .collection(
                REACT_APP_PRODUCTION_MODE == "development"
                  ? "dev_posts"
                  : "posts"
              )
              .doc(artId)
              .get();
            if (post.exists) {
              if (post.data().is_deleted == false) {
                const postdata = post.data();
                postdata.docId = post.id;
                exhibitionArtworkData.push(postdata);
              }
            }
          }
        }
        setExhibitionArtworkList(
          exhibitionArtworkList.concat(exhibitionArtworkData)
        );
      }
      setNextLoader(false);
    } catch (error) {
      console.log(error);
      setNextLoader(false);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    if (!checked) {
      setExArtIdCheck(exArtIdCheck.filter((item) => item !== id));
    } else if (exArtIdCheck.length < 10) {
      setExArtIdCheck([...exArtIdCheck, id]);
    } else {
      message.error("Please select maximum 10 image");
    }
  };

  const getArtworkGalleries = async () => {
    try {
      setLoading(true);

      let response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getArtworkGalleries,
        body: {
          check_mode: REACT_APP_PRODUCTION_MODE,
        },
      });
      if (response.data.status) {
        setGallery(response.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };
  const zipDownloadCheck = async (gallaryData) => {
    try {
      setLoading(true);
      let pathName = `artwork-Exhibition/${gallaryData?.gallary_name}/${exhibitionMonthYear}`;
      let gallaryName = gallaryData?.gallary_name.split(" ").join("_");
      const zipDataCheck = await firestore
        .collection("zip_process")
        .where("gallary_id", "==", gallaryData?.docId)
        .where("requested_path", "==", pathName)
        .get();

      if (!zipDataCheck.empty) {
        let data = zipDataCheck.docs[0]?.data();
        // await firestore.collection('zip_process').doc(zipDataCheck.docs[0].id).delete()
        // storage.ref().child(data?.zip_name).getDownloadURL().then((data)=>{
        // }).catch((err)=>{
        //     console.log(err,"eerrr");
        // })
        storage
          .ref()
          .child(data.zip_name)
          .getDownloadURL()
          .then(onResolve, onReject);
        async function onResolve(foundURL) {
          await storage.ref().child(data?.zip_name).delete();
        }
        function onReject(error) {
          console.log(error.code);
        }
      }
      const zipProcessId = await firestore.collection("zip_process").doc().id;
      const zip_name = `${gallaryName}_${exhibitionMonthYear}_${moment(
        new Date()
      ).format("DDMMYYYYhhmmss")}.zip`;
      await firestore.collection("zip_process").doc(zipProcessId).set({
        requested_path: pathName,
        zip_name: zip_name,
        gallary_id: gallaryData?.docId,
        httpurl: "",
        error: "",
        is_error: false,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
      var data = {
        path: pathName,
        zip_name: zip_name,
        gallary_id: gallaryData?.docId,
        zip_process_id: zipProcessId,
      };
      var config = {
        method: "post",
        url: "https://ziphandler.artboxy.com/start-process",
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     // 'Access-Control-Allow-Origin':'*',
        // "Access-Control-Allow-Origin": "Origin, X-Requested-With",

        // },
        data: data,
      };
      axios(config)
        .then(function (response) {
          if (response.data) {
            message.success(response.data?.message);
            // getArtworkGalleries();
          }
          //   return props.history.push('/exhibition-artworks')
        })
        .catch(function (error) {
          console.log(error);
        });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  return (
    <Card title={gallary_Data?.gallary_name}>
      {loader == false ? (
        <div className="art-varification-block">
          {/* {gallary_Data?.exhibited_artwork_id?.length == 0 ? null : */}
          {/* <div className="art-varification-heading">
                            {exArtIdCheck.length == 0 ? null :
                                <><span>Select maximum 10 Images</span> <Button type="button" className="ant-btn ant-btn-primary button" onClick={() => downloadArtworkImage()}>Download</Button></>
                            }
                        </div> */}
          <div className="art-varification-heading">
            <DatePicker
              onChange={(value, dateString) =>
                setExhibitionMonthYear(dateString)
              }
              allowClear={false}
              picker="month"
              format={"MMMM_YYYY"}
              defaultValue={moment(
                `${gallary_Data?.month}_${gallary_Data?.year}`,
                "MMMM_YYYY"
              )}
              value={moment(exhibitionMonthYear, "MMMM_YYYY")}
            />

            {/* <DatePicker onChange={(value, dateString) => setExhibitionMonthYear(dateString)}
                            allowClear={false}
                            picker="month"
                            format={'MMMM_YYYY'}
                            defaultValue={moment(`${gallary_Data?.month}_${gallary_Data?.year}`)}
                            value={moment(exhibitionMonthYear)} /> */}
            {gallary_Data?.[exhibitionMonthYear] > 0 ? (
              <Button
                type="button"
                className="ant-btn ant-btn-primary button"
                onClick={() => zipDownloadCheck(gallary_Data)}
              >
                Download
              </Button>
            ) : null}
          </div>
          <div className="gx-main-content gallery-content">
            <Row>
              {exhibitionArtworkList.length > 0 ? (
                exhibitionArtworkList.map((artwork, index) => (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24} key={index}>
                    <Card
                      cover={
                        <LazyLoadImage
                          alt="image"
                          src={artwork.img_url}
                          width="100%" // Making it responsive
                          height="400"
                          effect="blur"
                          style={{ objectFit: "cover" }}
                          threshold={300}
                          // srcSet={`${artwork.small_img_url} 300w, ${artwork.medium_img_url} 768w, ${artwork.large_img_url} 1280w`}
                          sizes="(max-width: 300px) 100vw, (max-width: 768px) 50vw, 25vw"
                        />
                      }
                    >
                      {/* <span>
                                            <Checkbox
                                                id={artwork.docId}
                                                onChange={handleClick}
                                                checked={exArtIdCheck.includes(artwork.docId)}
                                            ></Checkbox>
                                        </span> */}
                    </Card>
                  </Col>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    display: "block",
                  }}
                >
                  No Data
                </div>
              )}
            </Row>
          </div>
          {/* {exhibitionArtworkList.length > 0 ? exhibitionArtworkList.length != exhibitionArtTotalLength ?
                        nextLoader ?
                            <span style={{ marginLeft: 550 }}>Loading....</span>
                            :
                            <div className="text-center">
                                <Button type="primary" style={{ marginLeft: 550 }} onClick={() => NextExhibitionArtworkListing()} disabled={nextLoader}>Load more</Button>
                            </div>
                        : <span style={{ marginLeft: 550 }}>No more data</span> : null} */}
        </div>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </Card>
  );
};

export default ExhibitionGallaryArtworks;
