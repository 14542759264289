import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  Modal,
  Row,
  Col,
  Avatar,
  Checkbox,
  Card,
  Popconfirm,
  Button,
  Tag,
  Input,
  Image,
  Space,
  message,
  Pagination,
  Select,
} from "antd";
import { firestore, firebase } from "../../firebase/firebase";
import {
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  FlagOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import webUrl from "../../routes/api";
import Highlighter from "react-highlight-words";
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
import moment from "moment";
const {
  REACT_APP_PRODUCTION_MODE,
  REACT_APP_IMAGE_URL_FIREBASE,
  REACT_APP_IMAGE_URL_COMPRESS,
} = process.env;
const { Search } = Input;
const Artworkapproved = (props) => {
  const [artworkList, setArtworkList] = useState([]);
  const [artworkListCount, setArtworkListCount] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoading] = useState(false);
  const [pagetable, setPagetable] = useState(0);
  const [suspendId, setSuspendId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  const [hasSelected, setHasSelected] = useState("");
  const [searchArtworkList, setSearchArtworkList] = useState([]);
  const [totalArtworks, setTotalArtworks] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const searchInput = React.useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [TextField, setTextField] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchedText, setSearchedText] = useState("");
  const [searchTechnique, setIsSearchTechnique] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  useEffect(() => {
    setFieldValue("");
    setTextField(false);
  }, []);
  // const handleTableChange = (pagination, sorter) => {
  //   setPagination(pagination)
  // }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleCancel = async () => {
    setIsSearchTechnique(null);
    setSearchText("");
    setPagination({ ...pagination, current: 1 });
    const artworkListing = async (page) => {
      setLoading(true);
      console.log(searchTechnique, "searchTechnique");
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url:Service.getArtworkDetails,
        body: {
          from: (page - 1) * 50,
          size: 25,
          check_mode: REACT_APP_PRODUCTION_MODE,
          query:  ""
        },
      });

      if (response?.data.status == true) {
        setLoading(true);
        setTotalArtworks(response.data?.total?.value);
        setArtworkList(response?.data?.searchData);
        setLoading(false);
      }
    };
    artworkListing(1);
  };
  const handleInputChange = (event) => {
    let searchvalue = event.target.value;
    setSearchText(searchvalue);
  };

  //       methodName: Service.postMethod,
  //       api_url: Service.serarchArtworkDetailByID,
  //       body: {
  //         searchdata: searchValue,
  //         dataIndex: dataIndex,
  //         check_mode: REACT_APP_PRODUCTION_MODE
  //       }

  //     });
  //     if (response?.data?.status == true) {
  //       const data = response?.data?.searchData;
  //       let payload = data.toString().split(',')[0]
  //       const answer = await Service.makeAPICall({
  //         methodName: Service.postMethod,
  //         api_url: Service.serarchArtworkDetail,
  //         body: {
  //           searchdata: payload,
  //           dataIndex: "user_id",
  //           check_mode: REACT_APP_PRODUCTION_MODE
  //         }
  //       });
  //       if (answer?.data?.status === true) {
  //         if (answer?.data) {
  //           let posts = []
  //           await Promise.all(answer?.data?.searchData.map(async (doc) => {
  //             let postdata = doc;
  //               postdata.img_url = postdata.img_url.replace(REACT_APP_IMAGE_URL_FIREBASE, REACT_APP_IMAGE_URL_COMPRESS);
  //               posts.push(postdata);

  //           }))
  //           confirm();
  //           setSearchartworkData(posts);
  //           setSearchText(searchValue)
  //           setIsSearch(true);
  //         }
  //       }

  //     }
  //   }
  //   else {
  //     const searchValue = selectedKeys[0].toString()
  //     const response = await Service.makeAPICall({
  //       methodName: Service.postMethod,
  //       api_url: Service.serarchArtworkDetail,
  //       body: {
  //         searchdata: searchValue,
  //         dataIndex: dataIndex,
  //         check_mode: REACT_APP_PRODUCTION_MODE
  //       }

  //     });
  //     if (response?.data?.status === true) {
  //       if (response?.data) {
  //         let posts = []
  //         await Promise.all(response?.data?.searchData.map(async (doc) => {
  //           let postdata = doc
  //           let user = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').where('user_id', '==', postdata.user_id).get()
  //           if (user.docs.length > 0) {
  //             { user.docs[0].data().user_name ? postdata.user_name = user.docs[0].data().user_name : postdata.user_name = "no userName" }
  //             postdata.user_name = user.docs[0].data().user_name
  //             postdata.img_url = postdata.img_url.replace(REACT_APP_IMAGE_URL_FIREBASE, REACT_APP_IMAGE_URL_COMPRESS);
  //             posts.push(postdata);

  //           }

  //         }))
  //         confirm();
  //         setSearchartworkData(posts);
  //         setSearchText(searchValue)
  //         setIsSearch(true);
  //       }
  //     }
  //   }
  // };
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys);
    setIsSearch(true);
  };
  const handleReset = (clearFilters) => {
    setIsSearch(false);
    clearFilters();
    setSearchedText("");
    setSearchedColumn("");
  };

  const artworkDeleteNotification = async (
    postId,
    userData,
    reportmessage,
    artworkName
  ) => {
    try {
      const artworkNotification = {
        token: userData?.token,
        type: "0",
        body: `Your artwork has been deleted. Reason: ${reportmessage}`,
        body_spanish: `Tu obra de arte ha sido eliminada. Razón: ${reportmessage}`,
        body_german: `Dein Kunstwerk wurde gelöscht. Grund: ${reportmessage}`,
        body_italian: `La tua opera d'arte è stata cancellata. Motivo: ${reportmessage}`,
        body_french: `Votre œuvre d'art a été supprimée. Raison: ${reportmessage}`,
        title: "Artwork",
        title_spanish: "Presentar una obra de arte",
        title_german: "Kunstwerk einreichen",
        title_italian: "Inviare un'opera d'arte",
        title_french: "Soumettre une œuvre d'art",
        artwork_name: artworkName,
        sender_user_id: "",
        check_device: "web",
        receiver_user_id: userData?.user_id,
        post_id: postId,
        check_mode: REACT_APP_PRODUCTION_MODE,
      };
      let delete_details = {
        ...userData,
        ...artworkNotification,
      };

      await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.deleteArtworkEmail,
        body: {
          postId: postId,
          check_mode: REACT_APP_PRODUCTION_MODE,
          userData: delete_details,
        },
      });
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: artworkNotification,
      });
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  const handleDelete = async (index, reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters");
        return;
      }
      setLoading(true);
      setIsModalVisible(false);
      const doc = await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
        )
        .doc(index)
        .get();
      if (doc.exists) {
        await doc.ref.update({
          is_deleted: true,
          status: "deleted",
          update_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
        let artworkName = doc.data().artwork_name;
        await firestore
          .collection(
            REACT_APP_PRODUCTION_MODE == "development" ? "dev_users" : "users"
          )
          .where("user_id", "==", doc.data().user_id)
          .get()
          .then(async function (querySnapshot) {
            // Modified to use async/await
            for (const doc of querySnapshot.docs) {
              // Using for...of loop for async/await
              await artworkDeleteNotification(
                index,
                doc.data(),
                reportmessage,
                artworkName
              );
            }
          });
        console.log("here");
        setTimeout(() => {
          artworkListing();
        }, 1000);
        message.success("Successfully delete");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false); // Set loading to false in the error scenario
      console.log(error);
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    artworkListing(pagination?.current);
  }, [pagination.current, searchTechnique]);
  const artworkListing = async (page) => {
    console.log(
      "🚀 ~ file: Artworkapproved.js:306 ~ artworkListing ~ page:",
      page
    );
    setLoading(true);
    const response = await Service.makeAPICall({
      methodName: Service.postMethod,
      api_url:
        searchTechnique == null
          ? Service.getArtworkDetails
          : Service.getArtworkDetailsBytechnique,
      body: {
        from: (page - 1) * 25 ? (page - 1) * 25 : 0,
        size: 25,
        check_mode: REACT_APP_PRODUCTION_MODE,
        query: searchTechnique != null  ? searchTechnique : searchText,
      },
    });

    if (response?.data.status == true) {
      setLoading(true);
      setTotalArtworks(response.data?.total?.value);
      setArtworkList(response?.data?.searchData);
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setPagination({ ...pagination, current: page });
  };
  const handleReportStatus = async (data) => {
    try {
      await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development"
            ? "dev_report_post"
            : "report_post"
        )
        .add({
          report_id: moment(new Date()).format("DDMMYYYYhhmmss").toString(),
          report_message: "",
          report_post_id: data?.docId,
          sender_report_user_id: "ADMIN",
          is_deleted: false,
          status: false,
          create_at: firebase.firestore.FieldValue.serverTimestamp(),
          update_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
      await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
        )
        .doc(data?.docId)
        .update({ report_status: true });
      setTimeout(() => {
        artworkListing();
      }, 1000);
      message.success("Sucessfully report");
    } catch (error) {
      message.error("something went to wrong");
    }
  };

  const handleModerationStatus = async (data, moderationFlag) => {
    try {
      setLoading(true);
      await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
        )
        .doc(data?.docId)
        .update({ moderation: moderationFlag });
      setTimeout(() => {
        artworkListing(pagination?.current);
      }, 1500);
      setLoading(false);
      message.success("Sucessfully moderation updated");
    } catch (error) {
      setLoading(false);
      message.error("something went to wrong");
    }
  };

  const handleArtworkFeedShow = async (data, flag) => {
    try {
      let docId = data?.docId;
      setLoading(true);
      await firestore
        .collection(
          REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
        )
        .doc(docId)
        .update({ post_show: flag });
      message.success("Sucessfully updated");
      setTimeout(() => {
        artworkListing(pagination?.current);
      }, 1000);
      setLoading(false);
    } catch (error) {
      message.error("something went to wrong");
    }
  };

  const columns = [
    {
      title: "Artwork Id",
      dataIndex: "docId",
      width: 300,
      ellipsis: true,
      ...getColumnSearchProps("docId"),
    },
    {
      title: "Artwork",
      dataIndex: "img_url",
      width: 200,
      height: 200,
      render: (text, record, id) => {
        return (
          <div>
            <Image width={150} height={150} src={record.img_url} />
          </div>
        );
      },
    },
    {
      title: "Moderation",
      dataIndex: "moderation",
      width: 120,
      ...getColumnSearchProps("moderation"),
      render: (text, record, id) => {
        return (
          <div>
            <Checkbox
              onClick={() =>
                handleModerationStatus(
                  record,
                  record.moderation == "ischecked" ? "unchecked" : "ischecked"
                )
              }
              checked={record?.moderation == "ischecked" ? true : false}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: "Flag",
      dataIndex: "report_status",
      width: 80,
      height: 80,
      render: (text, record, id) => {
        return (
          <div>
            {record?.report_status ? (
              <FlagOutlined style={{ color: "red", fontSize: "30px" }} />
            ) : (
              <Popconfirm
                title="Sure to report?"
                onConfirm={() => handleReportStatus(record)}
              >
                <Button type="link success">
                  <FlagOutlined style={{ color: "green", fontSize: "30px" }} />
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Artwork Show",
      dataIndex: "post_show",
      width: 160,
      height: 160,
      render: (text, record, id) => {
        return (
          <div>
            {record?.post_show == "Yes" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  className="side-circle"
                  style={{
                    background:
                      record?.nextMonthGallaryData?.length > 0 ||
                      record?.exhibitionMonthGallaryData?.length > 0
                        ? "#f50"
                        : "green",
                    height: "18px",
                    width: "18px",
                    display: "block",
                    marginBottom: "8px",
                    marginRight: "15px",
                    borderRadius: "100%",
                  }}
                ></span>
                <Tag
                  icon={<CheckOutlined />}
                  color="green"
                  onClick={() => handleArtworkFeedShow(record, "No")}
                >
                  Feed
                </Tag>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  className="side-circle"
                  style={{
                    background:
                      record?.nextMonthGallaryData?.length > 0 ||
                      record?.exhibitionMonthGallaryData?.length > 0
                        ? "#f50"
                        : "green",
                    height: "18px",
                    width: "18px",
                    display: "block",
                    marginBottom: "8px",
                    marginRight: "15px",
                    borderRadius: "100%",
                  }}
                ></span>
                <Tag
                  icon={<PlusOutlined />}
                  color="magenta"
                  onClick={() => handleArtworkFeedShow(record, "Yes")}
                >
                  Feed
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Artwork name",
      dataIndex: "artwork_name",
      width: 150,
      ellipsis: true,
      ...getColumnSearchProps("artwork_name"),
    },
    {
      title: "Caption",
      dataIndex: "caption",
      width: 120,
      ellipsis: true,
      ...getColumnSearchProps("caption"),
    },
    {
      title: "Height",
      dataIndex: "height",
      width: 80,
    },
    {
      title: "Width",
      dataIndex: "width",
      width: 80,
    },
    {
      title: "Depth",
      dataIndex: "depth",
      width: 80,
    },
    {
      title: "Technique",
      dataIndex: "display_technique",
      width: 160,
      ellipsis: true,
      sorter: (a, b) => a.display_technique.length - b.display_technique.length,
    },
    {
      title: "Year of Creation",
      dataIndex: "year_of_creation",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      ...getColumnSearchProps("status"),
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            flexwrap: "wrap",
          }}
        >
          {record.status == "approved" ? (
            <span style={{ color: "green" }}>approved</span>
          ) : (
            <span style={{ color: "red" }}>rejected</span>
          )}
          {/* <Select
              className={`select ${record.status}`}
              size="large"
              defaultValue={record.status}
            // updateUserApproval(record.docId, value)
            >
              <Option value="approved" style={{ color: 'green' }}>Approved</Option>
              <Option value="rejected" style={{ color: 'red' }}>Rejected</Option>
            </Select> */}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (text, record, index) =>
        artworkList.length >= 1 ? (
          <div
            style={{
              display: "flex",
              flexwrap: "wrap",
            }}
          >
            <Button
              type="link success"
              onClick={() => {
                setIsModalVisible(true);
                setSuspendId(record.docId);
              }}
            >
              <DeleteOutlined
                style={{ fontSize: "18px", marginLeft: "10px" }}
              />
            </Button>
          </div>
        ) : null,
    },
    {
      title: "Details",
      dataIndex: "details",
      width: 100,
      render: (text, record, index) =>
        artworkList.length >= 1 ? (
          <div
            style={{
              display: "flex",
              flexwrap: "wrap",
            }}
          >
            <a
              href={`${webUrl?.postDetailsURl}/${record.docId}`}
              target="_blank"
            >
              <Button type="link success">
                <EyeOutlined style={{ fontSize: "20px" }} />
              </Button>
            </a>
          </div>
        ) : null,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected([]);
      if (selectedRowKeys?.length <= 50) {
        setHasSelected(selectedRowKeys);
      } else {
        message.error("Please select maximum 50 data");
      }
    },
  };

  const checkdelete = async (reportmessage) => {
    try {
      if (reportmessage.trim().length > 50) {
        message.error("Please select maximum 50 data");
        return;
      }
      setLoading(true);
      setIsReportModal(false);
      await Promise.all(
        hasSelected.map(async (dataID) => {
          const doc = await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
            )
            .doc(dataID)
            .get();
          if (doc.exists) {
            let artworkName = doc.data().artwork_name;
            await doc.ref.update({
              is_deleted: true,
              status: "deleted",
              update_at: firebase.firestore.FieldValue.serverTimestamp(),
            });
            await firestore
              .collection(
                REACT_APP_PRODUCTION_MODE == "development"
                  ? "dev_users"
                  : "users"
              )
              .where("user_id", "==", doc.data().user_id)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                  await artworkDeleteNotification(
                    dataID,
                    doc.data(),
                    reportmessage,
                    artworkName
                  );
                });
              });
          }
        })
      );
      setLoading(false);
      message.success("Successfully Deleted");
      return props.history.push({
        pathname: `/artworksapproved`,
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleModerationStatusAll = async () => {
    try {
      setLoading(true);
      await Promise.all(
        hasSelected.map(async (data) => {
          await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
            )
            .doc(data)
            .update({ moderation: "ischecked" });
        })
      );
      setTimeout(() => {
        artworkListing(pagination?.current);
      }, 1000);
      setLoading(false);
      setHasSelected([]);
      message.success("Sucessfully moderation updated");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleFeedChangeAll = async (flag) => {
    try {
      setLoading(true);
      await Promise.all(
        hasSelected.map(async (data) => {
          await firestore
            .collection(
              REACT_APP_PRODUCTION_MODE == "development" ? "dev_posts" : "posts"
            )
            .doc(data)
            .update({ post_show: flag });
        })
      );
      setTimeout(() => {
        artworkListing(pagination?.current);
      }, 1000);
      setLoading(false);
      setHasSelected([]);
      message.success("Sucessfully updated");
    } catch (error) {
      setLoading(false);
      message.error("something went to wrong");
    }
  };
  const handlechange = (sorter, filter, pagination) => {
    setSortedInfo(sorter);
  };
  const table = useMemo(() => {
    return loader == false ? (
      <Table
        className="gx-table-responsive"
        columns={columns}
        rowSelection={{ ...rowSelection }}
        rowKey={(artworkList) => artworkList.docId}
        sortedInfo={sortedInfo}
        onChange={handlechange}
        dataSource={artworkList}
        pagination={false}
      />
    ) : (
      <div className="gx-loader-view">
        <CircularProgress />
      </div>
    );
  }, [
    artworkList,
    searchArtworkList,
    loader,
    searchTechnique,
    artworkListCount,
    pagetable,
    pagination,
  ]);
  const onChange = (value) => {
    setIsSearchTechnique(value);
    console.log(`selected ${value}`);
  };
  const handleSearchby = (value) => {
    console.log("search:", value);
  };
  return (
    <>
      {loader == false ? (
        <Card title="Artwork Details">
          <Row>
            <Col md={16} span={24}>
              <div
                style={{
                  maxWidth: "550px",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "15px",
                }}
              >
                <Search
                  placeholder="Search..."
                  enterButton="Search"
                  size="medium"
                  onSearch={() => {
                    setIsSearchTechnique(null);
                    artworkListing(pagination?.current);
                    setHasSelected([]);
                  }}
                  onCancel={handleCancel}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
                <Button
                  onClick={handleCancel}
                  style={{ width: 90 }}
                  type="primary"
                >
                  Reset
                </Button>
                <Select
                  showSearch
                  style={{ minWidth: 150 }}
                  placeholder="Search by Technique"
                  optionFilterProp="children"
                  onChange={onChange}
                  value={searchTechnique} // Only set the value when you have a selected technique
                  onSearch={handleSearchby}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Paintings",
                      label: "Paintings",
                    },
                    {
                      value: "Mixed Media",
                      label: "Mixed Media",
                    },
                    {
                      value: "Sculptures",
                      label: "Sculptures",
                    },
                    {
                      value: "Digital",
                      label: "Digital Art",
                    },
                    {
                      value: "Photography",
                      label: "Photography",
                    },
                    {
                      value: "Street",
                      label: "Street Art",
                    },
                    {
                      value: "Prints",
                      label: "Prints",
                    },
                  ]}
                >
                  {console.log(searchTechnique,"searchTechnique")}
                  {/* Add an additional option for the placeholder */}
                  {searchTechnique === null && (
                    <Select.Option value="" label="">
                      Search by Technique
                    </Select.Option>
                  )}
                </Select>
              </div>
            </Col>
          </Row>

          <Button
            type="danger"
            hidden={!hasSelected.length > 0}
            onClick={() => setIsReportModal(true)}
          >
            Delete
          </Button>
          <Popconfirm
            title="Sure to moderation?"
            onConfirm={() => handleModerationStatusAll()}
          >
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              hidden={!hasSelected.length > 0}
            >
              Moderation
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Sure to feed add?"
            onConfirm={() => handleFeedChangeAll("Yes")}
          >
            <Tag
              icon={<CheckOutlined />}
              color="green"
              hidden={!hasSelected.length > 0}
            >
              Feed
            </Tag>
          </Popconfirm>
          <Popconfirm
            title="Sure to feed remove?"
            onConfirm={() => handleFeedChangeAll("No")}
          >
            <Tag
              icon={<PlusOutlined />}
              color="magenta"
              hidden={!hasSelected.length > 0}
            >
              Feed
            </Tag>
          </Popconfirm>
          <Modal
            title="Reason.."
            visible={isReportModal}
            footer={false}
            onCancel={() => {
              setIsReportModal(false);
              setFieldValue("");
              setTextField(false);
            }}
          >
            <p>
              <b>why are you this post delete?</b>
            </p>
            <br />
            <Button
              onClick={() => {
                checkdelete("Violation of Community Guidelines");
                setIsReportModal(false);
              }}
            >
              Violation of Community Guidelines
            </Button>
            <Button
              onClick={() => {
                checkdelete("Text is visible on the artwork");
                setIsReportModal(false);
              }}
            >
              Text is visible on the artwork
            </Button>
            <Button
              onClick={() => {
                checkdelete("Bad Photo Quality - Artwork is cut off");
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - Artwork is cut off
            </Button>
            <Button
              onClick={() => {
                checkdelete("Image is upside down / sideways");
                setIsModalVisible(false);
              }}
            >
              Image is upside down / sideways
            </Button>
            <Button
              onClick={() => {
                checkdelete("Multiple artworks in the same photo");
                setIsModalVisible(false);
              }}
            >
              Multiple artworks in the same photo
            </Button>
            <Button
              onClick={() => {
                checkdelete("Bad Photo Quality - blurry / flash");
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - blurry / flash
            </Button>
            <Button
              onClick={() => {
                checkdelete(
                  "Bad Photo Quality - Wall/Furniture/Person is visible Less"
                );
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - Wall/Furniture/Person is visible Less
            </Button>
            <Button
              onClick={() => {
                checkdelete("Bad Photo Quality - Higher resolution required");
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - Higher resolution required
            </Button>
            <Button
              onClick={() => {
                checkdelete("Not an artwork");
                setIsModalVisible(false);
              }}
            >
              Not an artwork
            </Button>
            <Button
              onClick={() => {
                checkdelete("Quality of art is not sufficient");
                setIsModalVisible(false);
              }}
            >
              Quality of art is not sufficient
            </Button>
            <Button
              onClick={() => {
                checkdelete("Commercials");
                setIsReportModal(false);
              }}
            >
              Commercials
            </Button>
            <Button
              onClick={() => {
                checkdelete("Plagiarism");
                setIsReportModal(false);
              }}
            >
              Plagiarism
            </Button>
            <Button onClick={() => setTextField(!TextField)}>
              Other reason
            </Button>
            {TextField ? (
              <>
                <li>
                  <div className="report-form">
                    <Input
                      type="text"
                      placeholder="write other reason"
                      value={fieldValue}
                      onChange={(e) => setFieldValue(e.target.value)}
                    />
                    <Button
                      className="btn primary-btn"
                      style={{ marginTop: 10 }}
                      onClick={() =>
                        fieldValue.trim().length > 0
                          ? checkdelete(fieldValue)
                          : message.error("please fill the data")
                      }
                    >
                      Send report
                    </Button>
                  </div>
                </li>
              </>
            ) : null}
          </Modal>
          {table}
          {searchedText == "" ? (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "15px",
                flexWrap: "wrap",
              }}
            >
              <label>Total Records Count is {totalArtworks}</label>

              {
                <div>
                  <Pagination
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={totalArtworks}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              }
            </div>
          ) : (
            ""
          )}

          {/* <div className='footer-table'>
            <div className='footer-labels'>
              {

                (searchText == "" && !isSearch) ?
                  <><label style={{ flex: 1 }}>
                    Total Records Count is {searchArtworkData.length != 0 ? searchArtworkData.length : artworkListCount}
                  </label><label>Total pages is {totalPages == 0 ? 1 : totalPages}</label>
                    <label>current page is {page}</label></>

                  : <label style={{ flex: 1 }}>
                    Total search Records Count is {searchArtworkData.length}

                  </label>

              }
            </div>
            {(searchText == '') ? <div className="button-class">
              {pagetable === 0 ? '' : <Button type="primary" style={{ marginRight: "5px" }} onClick={() => showPrevious()}>Previous</Button>}
              {
                (pagetable === count) ? '' : <Button type="primary" onClick={() => showNext()}>Next</Button>
              }
            </div> : ""}

          </div> */}
          <Modal
            title="Reason.."
            visible={isModalVisible}
            footer={false}
            onCancel={() => {
              setIsModalVisible(false);
              setFieldValue("");
              setTextField(false);
            }}
          >
            <p>
              <b>why are you this post delete?</b>
            </p>
            <br />
            <Button
              onClick={() => {
                handleDelete(suspendId, "Violation of Community Guidelines");
                setIsModalVisible(false);
              }}
            >
              Violation of Community Guidelines
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Text is visible on the artwork");
                setIsModalVisible(false);
              }}
            >
              Text is visible on the artwork
            </Button>
            <Button
              onClick={() => {
                handleDelete(
                  suspendId,
                  "Bad Photo Quality - Artwork is cut off"
                );
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - Artwork is cut off
            </Button>
            <Button
              onClick={() => {
                handleDelete(
                  suspendId,
                  " Bad Photo Quality - Wall/Furniture/Person is visible"
                );
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - Wall/Furniture/Person is visible Less
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Image is upside down / sideways");
                setIsModalVisible(false);
              }}
            >
              Image is upside down / sideways
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Multiple artworks in the same photo");
                setIsModalVisible(false);
              }}
            >
              Multiple artworks in the same photo
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Bad Photo Quality - blurry / flash");
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - blurry / flash
            </Button>
            <Button
              onClick={() => {
                handleDelete(
                  suspendId,
                  "Bad Photo Quality - Higher resolution required"
                );
                setIsModalVisible(false);
              }}
            >
              Bad Photo Quality - Higher resolution required
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Not an artwork");
                setIsModalVisible(false);
              }}
            >
              Not an artwork
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Quality of art is not sufficient");
                setIsModalVisible(false);
              }}
            >
              Quality of art is not sufficient
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Commercials");
                setIsModalVisible(false);
              }}
            >
              Commercials
            </Button>
            <Button
              onClick={() => {
                handleDelete(suspendId, "Plagiarism");
                setIsModalVisible(false);
              }}
            >
              Plagiarism
            </Button>
            <Button onClick={() => setTextField(!TextField)}>
              Other reason
            </Button>
            {TextField ? (
              <>
                <li>
                  <div className="report-form">
                    <Input
                      type="text"
                      placeholder="write other reason"
                      value={fieldValue}
                      onChange={(e) => setFieldValue(e.target.value)}
                    />
                    <Button
                      className="btn primary-btn"
                      style={{ marginTop: 10 }}
                      onClick={() =>
                        fieldValue.trim().length > 0
                          ? handleDelete(suspendId, fieldValue)
                          : message.error("please fill the data")
                      }
                    >
                      Send report
                    </Button>
                  </div>
                </li>
              </>
            ) : null}
          </Modal>
        </Card>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
export default Artworkapproved;
