import React, { useState, useEffect } from "react"
import { Button, Card, Checkbox, Modal, Image, message } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
import { Link } from "react-router-dom";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const BlogVerification = (props) => {
  const [blogList, setBlogList] = useState([])
  const [users, setUsers] = useState([])
  const [loader, setLoading] = useState(false)
  const [userId, setUserId] = useState([])
  const [userCheck, setUserCheck] = useState([]);
  const [suspendId, setSuspendId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);

  const getusers = async () => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users')
        .onSnapshot((querySnapshot) => {
          const users = querySnapshot.docs.map((doc) => {
            let user = doc.data();
            user.docId = doc.id;
            return user;
          });
          setUsers(users);
        });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }

  }

  const getblogList = async () => {
    setLoading(true)
    await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
      .where('type', '==', 'blog')
      .where('status', "==", "pending")
      .onSnapshot((querySnapshot) => {
        const blogapproved = querySnapshot.docs.map((doc) => {
          let blogapproveddata = doc.data();
          blogapproveddata.docId = doc.id;
          userId.push(doc.data().user_id)
          setUserId([...new Set(userId)])
          return blogapproveddata;
        });
        setBlogList(blogapproved);
      });
    setLoading(false)
  }

  useEffect(() => {
    getblogList()
    getusers()
  }, [])

  const updateUserApproval = async (userId) => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : "posts").where('user_id', '==', userId).where('type', '==', 'blog').where('status', '==', 'pending')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.update({ status: "approved", valid_image: true, update_at: firebase.firestore.FieldValue.serverTimestamp() });

          });
        })
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', userId)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            await blogApprovedNotification(doc.data(), userId);
            await doc.ref.update({ artwork_status: true, update_at: firebase.firestore.FieldValue.serverTimestamp() });
          });
        })
      setLoading(false)
      message.success('Successfully Approved');
      return props.history.push({
        pathname: `/blogsverification`,
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  }

  const updateUserApproval1 = async () => {
    try {
      setLoading(true)
      for (var i = 0; i < userCheck.length; i++) {
        var snapshot = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : "posts").where('user_id', '==', userCheck[i]).where('type', '==', 'blog').where('status', '==', 'pending')

        var snapshot = await snapshot.get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              doc.ref.update({ status: "approved", valid_image: true, update_at: firebase.firestore.FieldValue.serverTimestamp() });
            });
          })
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', userCheck[i]).get()
          .then(async function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
              await blogApprovedNotification(doc.data(), userCheck[i]);
              await doc.ref.update({ artwork_status: true, update_at: firebase.firestore.FieldValue.serverTimestamp() });
            });
          })
      }
      setLoading(false)
      message.success('Successfully Approved');
      return props.history.push({
        pathname: `/blogsverification`,
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  }

  const blogApprovedNotification = async (userData, userId) => {
    try {

      const blogNotification = {
        token: userData?.token,
        type: '0',
        body: 'Your post has been reviewed by the ARTBOXY Team! It has been accepted and is now visible on your profile.',
        body_spanish: "Tu post ha sido revisado por el equipo de ARTBOXY. Ha sido aceptada y ahora es visible en tu perfil.",
        body_german: "Dein Beitrag wurde vom ARTBOXY Team geprüft! Er wurde angenommen und ist nun in deinem Profil sichtbar.",
        body_italian: "Il tuo post è stato esaminato dal team di ARTBOXY! È stato accettato ed è ora visibile sul tuo profilo.",
        body_french: "Votre message a été revu par l'équipe ARTBOXY ! Il a été accepté et est maintenant visible sur votre profil.",
        title: 'Verification',
        title_spanish: 'Verificación',
        title_german: 'Verifikation',
        title_italian: 'Verifica',
        title_french: 'Vérification',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userId,
        post_id: "",
        check_mode: REACT_APP_PRODUCTION_MODE
      };
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: blogNotification
      });

    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const blogRejectedNotification = async (userData, userId, reportmessage) => {
    try {
      const blogNotification = {
        token: userData?.token,
        type: '0',
        body: `Your post has been reviewed by the ARTBOXY Team! It has been declined and will not be visible on your profile. Reason: ${reportmessage}`,
        body_spanish: `Tu post ha sido rechazado por el equipo de ARTBOXY y no se mostrará en tu perfil. Razón : ${reportmessage}`,
        body_german: `YDein Post wurde von dem ARTBOXY Team abgelehnt und wird nicht auf Deinem Profil gezeigt. Grund : ${reportmessage}`,
        body_italian: `Il tuo post è stato rifiutato dal team di ARTBOXY e non verrà visualizzato sul tuo profilo. Motivo : ${reportmessage}`,
        body_french: `Votre post a été refusé par l'équipe ARTBOXY et ne sera pas affiché sur votre profil. Raison : ${reportmessage}`,
        title: 'Verification',
        title_spanish: 'Verificación',
        title_german: 'Verifikation',
        title_italian: 'Verifica',
        title_french: 'Vérification',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userId,
        post_id: "",
        check_mode: REACT_APP_PRODUCTION_MODE
      };

      await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: blogNotification
      })
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const updateUserReject = async (userId, reportmessage) => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : "posts").where('user_id', '==', userId).where('type', '==', 'blog').where('status', '==', 'pending')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.update({ status: "rejected", update_at: firebase.firestore.FieldValue.serverTimestamp() });
          });
        })

      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', userId).get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            blogRejectedNotification(doc.data(), userId, reportmessage);
          });
        })
      setLoading(false)
      message.success('Successfully Updated');
      return props.history.push({
        pathname: `/blogsverification`,
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  }

  const updateUserReject1 = async (reportmessage) => {
    try {
      setLoading(true)
      for (var i = 0; i < userCheck.length; i++) {
        var snapshot1 = firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : "posts").where('user_id', '==', userCheck[i]).where('type', '==', 'blog').where('status', '==', 'pending')
        var snapshot1 = await snapshot1.get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              doc.ref.update({ status: "rejected", update_at: firebase.firestore.FieldValue.serverTimestamp() });
            });
          })
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', userCheck[i]).get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              blogRejectedNotification(doc.data(), userCheck[i], reportmessage);
            });
          })
      }

      setLoading(false)
      message.success('Successfully Updated');
      return props.history.push({
        pathname: `/blogsverification`,
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  }


  const handleSelectAll = e => {
    const { checked } = e.target;

    if (!checked) {
      setUserCheck([]);
    }
    else {
      setUserCheck([...new Set(blogList.map(li => li.user_id))]);
    }

  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setUserCheck([...userCheck, id]);
    if (!checked) {
      setUserCheck(userCheck.filter(item => item !== id));
    }


  };

  return (
    <>
      {loader == false ?
        <Card title="Blog Verification">
          {userId.length > 0 ?
            <div className="art-varification-block">
              <div className="art-varification-heading">

                <Checkbox
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={userCheck.length == [...new Set(blogList.map(li => li.user_id))].length}
                >Select All </Checkbox>

                {userId.length > 0 &&
                  <>
                    <Button type="button" className="ant-btn ant-btn-primary button" disabled={userCheck.length == 0} onClick={() => updateUserApproval1()}>Accept</Button>
                    <Button type="button" className="ant-btn ant-btn-primary button button-reject" disabled={userCheck.length == 0} onClick={() => setIsReportModal(true)}>Reject</Button>
                    <Modal title="Reason.." visible={isReportModal} footer={false} onCancel={() => setIsReportModal(false)}>
                      <p><b>why are you this post reject?</b></p><br />
                      <Button onClick={() => {
                        updateUserReject1('Inappropriate content');
                        setIsReportModal(false)
                      }}>
                        Inappropriate content</Button>
                      <Button onClick={() => {
                        updateUserReject1('Commercials');
                        setIsReportModal(false)
                      }}>Commercials</Button>
                      <Button onClick={() => {
                        updateUserReject1('Plagiarism');
                        setIsReportModal(false)
                      }}>Plagiarism</Button>
                      <Button onClick={() => {
                        updateUserReject1('etc..');
                        setIsReportModal(false)
                      }}>etc..</Button>
                    </Modal>
                  </>
                }
              </div>
              {userId.map((ID, index) => (
                <div key={index}>
                  {users.map((user, index) => (
                    ID === user?.user_id ?
                      <div className="art-varification-block" key={index}>
                        <div className="art-varification-heading">
                          <Link to='#'>
                            <h4 onClick={() => {
                              props.history.push({
                                pathname: `/usersotherdetails`
                              }); localStorage.setItem('doc_Id', user?.user_id)
                            }}>{user?.user_name}</h4></Link>
                        </div>
                        <div className="art-varification-content">
                          <div className="ant-row" style={{ rowGap: 0 }}>
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-6 ant-col-lg-6 ant-col-xl-2">
                              <Checkbox
                                id={user?.user_id}
                                onChange={handleClick}
                                checked={userCheck.includes(user?.user_id)}
                              ></Checkbox>
                            </div>

                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-10 ant-col-lg-10 ant-col-xl-16">

                              <div className="ant-row" style={{ rowGap: 0 }}>
                                {blogList.map((arts, index) => (
                                  arts?.user_id === user?.docId ?
                                    <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-8" key={index}>
                                      <div className="art-varification-heading">
                                        <h6>{arts?.create_at.toDate().toLocaleTimeString() + ' ' + arts?.create_at.toDate().toDateString()}</h6>
                                      </div>

                                      <div className="ant-card ant-card-bordered ant-card-hoverable"
                                      >
                                        <div className="ant-card-cover">
                                          <Image alt="techniques image" src={arts.img_url} />
                                        </div>
                                        <div className="ant-card-body card-desc">
                                          <div className="ant-card-meta">
                                            <div className="ant-card-meta-detail">
                                              <div className="ant-card-meta-description">Status : {arts.valid_image === true ? "Appropriate" : <span style={{ color: 'red' }}>Inappropriate</span>}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div> : null))}
                              </div>
                            </div>
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8 ant-col-xl-6">
                              <div className="art-button">
                                <Button type="button" className="ant-btn ant-btn-primary button" onClick={() => updateUserApproval(user?.docId)}>Accept</Button>
                                <Button type="button" className="ant-btn ant-btn-primary button button-reject" onClick={() => { setIsModalVisible(true); setSuspendId(user?.docId) }}>Reject</Button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      : null))}
                </div>
              ))}

            </div> :
            <div style={{ textAlign: 'center' }}>No Pending Blogs</div>}
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      }

      <Modal title="Reason.." visible={isModalVisible} footer={false} onCancel={() => setIsModalVisible(false)}>
        <p><b>why are you this post reject?</b></p><br />
        <Button onClick={() => {
          updateUserReject(suspendId, 'Inappropriate content');
          setIsModalVisible(false)
        }}>
          Inappropriate content</Button>
        <Button onClick={() => {
          updateUserReject(suspendId, 'Commercials');
          setIsModalVisible(false)
        }}>Commercials</Button>
        <Button onClick={() => {
          updateUserReject(suspendId, 'Plagiarism');
          setIsModalVisible(false)
        }}>Plagiarism</Button>
        <Button onClick={() => {
          updateUserReject(suspendId, 'etc..');
          setIsModalVisible(false)
        }}>etc..</Button>
      </Modal>
    </>
  )

}
export default BlogVerification;