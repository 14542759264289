import React, { useState, useEffect } from "react"
import { Table, Modal, Card, Checkbox, Button, Image, Input, Space, message, Popconfirm } from 'antd';
import { firestore, firebase } from "../../firebase/firebase"
import { DeleteOutlined, EyeOutlined, SearchOutlined, FlagOutlined } from '@ant-design/icons'
import webUrl from "../../routes/api";
import Highlighter from 'react-highlight-words';
import CircularProgress from "../CircularProgress/index";
import Service from "../../service";
import moment from "moment";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const UserBlogs = (props) => {
  // const [previewVisible, setPreviewVisible] = useState(false)
  const [suspendId, setSuspendId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  const [loader, setLoading] = useState(false);
  const [page,setPage]= useState(1);
  const [blogList, setBlogList] = useState([])
  const [hasSelected, setHasSelected] = useState('')
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })

  const ID = localStorage.getItem("doc_Id")
  const searchInput = React.useRef(null)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [fieldValue, setFieldValue] = useState("")
  const [TextField, setTextField] = useState(false)

  useEffect(() => {
    setFieldValue("")
    setTextField(false)
  }, [])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const handleDelete = async (index, reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters")
        return
      }
      setLoading(true)
      const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(index).get();
      if (doc.exists) {
        await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp() })
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', doc.data().user_id).get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
              await blogDeleteNotification(index, doc.data(), reportmessage);
            });
          })
      }
      setLoading(false)
      message.success('Successfully Updated');
      return props.history.push({
        pathname: `/usersotherdetails`,
      });

    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination)
  }
  const showPrevious = ({ item }) => {
    const fetchPreviousData = async () => {
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
      .where('user_id', '==', ID)
      .where('type', '==', 'blog')
      .where('is_deleted', '==', false)
      .orderBy('create_at', 'desc').endBefore(item.create_at)
      .limitToLast(5)
      .onSnapshot(async (querySnapshot) => {
        const blogsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
          let blogdata = doc.data();
          blogdata.docId = doc.id;
          const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(blogdata.user_id).get()
          if (userData.exists) {
            blogdata.user_name = userData.data()?.user_name
          }
          return blogdata;
        }));
        setBlogList(blogsData);
        setPage(page+1);
      });

    };
    fetchPreviousData();
  };

  const getFooterDetails = () => {
    return (<>
          <label>
        Total Records Count is {blogList.length}
      </label>
      { page === 1 ? '' : <Button type="primary"  style={{ float: "right" , marginLeft: "5px" }}onClick={() => showPrevious({ item: blogList[0] }) }>Previous</Button>}
          {
            blogList.length < 5 ? '' :<Button type="primary" onClick={() => showNext({ item: blogList[blogList.length - 1] })} style={{ float: "right"}}>Next</Button>
          }
    </>

    )
  }


  useEffect(() => {
    try {
      setLoading(true)
      firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
        .where('user_id', '==', ID)
        .where('type', '==', 'blog')
        .where('is_deleted', '==', false)
        .limit(5)
        .onSnapshot(async (querySnapshot) => {
          const blogsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
            let blogdata = doc.data();
            blogdata.docId = doc.id;
            const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(blogdata.user_id).get()
            if (userData.exists) {
              blogdata.user_name = userData.data()?.user_name
            }
            return blogdata;
          }));
          setBlogList(blogsData);
        });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }, []);

  const showNext = ({ item }) => {
    console.log(item,"itemmm");
    if (blogList.length === 0) {
      alert("Thats all we have for now !")
    } else {
      const fetchNextData = async () => {
        firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts')
        .where('user_id', '==', ID)
        .where('type', '==', 'blog')
        .where('is_deleted', '==', false)
        .orderBy('create_at', 'desc').limit(5).startAfter(item.create_at)
        .onSnapshot(async (querySnapshot) => {
          const blogsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
            let blogdata = doc.data();
            blogdata.docId = doc.id;
            const userData = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(blogdata.user_id).get()
            if (userData.exists) {
              blogdata.user_name = userData.data()?.user_name
            }
            return blogdata;
          }));
          setBlogList(blogsData);
          setPage(page+1);
        });
      };
      fetchNextData();
    }
  };

  const handleReportStatus = async (data) => {
    try {
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_report_post' : 'report_post')
        .add({
          report_id: moment(new Date()).format('DDMMYYYYhhmmss').toString(),
          report_message: '',
          report_post_id: data?.docId,
          sender_report_user_id: 'ADMIN',
          is_deleted: false,
          status: false,
          create_at: firebase.firestore.FieldValue.serverTimestamp(),
          update_at: firebase.firestore.FieldValue.serverTimestamp()
        })
      await firestore.collection('posts').doc(data?.docId).update({ report_status: true })
      message.success('Sucessfully report')
    } catch (error) {
      message.error('something went to wrong')
    }

  }
  const handleModerationStatus = async (data, moderationFlag) => {
    try {
      setLoading(true)
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data?.docId).update({ moderation: moderationFlag })
      setLoading(false)
      message.success('Sucessfully moderation updated')
    } catch (error) {
      setLoading(false)
      message.error('something went to wrong')
    }
  }

  const columns = [
    {
      title: 'Blog Id',
      dataIndex: 'docId',
      width: 300,
      ellipsis: true,
      ...getColumnSearchProps('docId')
    },
    {
      title: 'Blog',
      dataIndex: 'img_url',
      width: 300,
      height: 300,
      render: (text, record) => {
        return (
          <div>
            <Image width={150} height={150} src={record.img_url}
            />
          </div>
        );
      },
    },
    {
      title: 'Moderation',
      dataIndex: 'moderation',
      width: 120,
      ...getColumnSearchProps('moderation'),
      render: (text, record, id) => {
        return (
          <div>
            <Checkbox
              onClick={() => handleModerationStatus(record, record.moderation == 'ischecked' ? 'unchecked' : 'ischecked')}
              checked={record?.moderation == 'ischecked' ? true : false}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Flag',
      dataIndex: 'report_status',
      width: 80,
      height: 80,
      render: (text, record, id) => {

        return (
          <div>
            {record?.report_status ? <FlagOutlined style={{ color: 'red', fontSize: '30px' }} /> :
              <Popconfirm title="Sure to report?" onConfirm={() => handleReportStatus(record)}>
                <Button type="link success"><FlagOutlined style={{ color: 'green', fontSize: '30px' }} /></Button>
              </Popconfirm>

            }
          </div>
        );
      },
    },
    {
      title: 'Caption',
      dataIndex: 'caption',
      width: 130,
      ellipsis: true,
      ...getColumnSearchProps('caption')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      ...getColumnSearchProps('status'),
      render: (text, record, index) => (
        <div style={{
          display: "flex",
          flexwrap: "wrap"
        }}>

          {record.status == "approved" ? <span style={{ color: "green" }}>approved</span> : <span style={{ color: "red" }}>rejected</span>}
        </div>

      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record, index) =>
        blogList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>
            <Button type="link success" onClick={() => { setIsModalVisible(true); setSuspendId(record.docId) }}><DeleteOutlined style={{ fontSize: '18px', marginLeft: '10px' }} /></Button>
          </div>
        ) : null,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: 100,
      render: (text, record, index) =>
        blogList.length >= 1 ? (
          <div style={{
            display: "flex",
            flexwrap: "wrap"
          }}>
            <a href={`${webUrl?.postDetailsURl}/${record.docId}`} target="_blank">
              <Button type="link success">
                <EyeOutlined style={{ fontSize: '20px' }} />
              </Button>
            </a>
          </div>
        ) : null,
    }
  ]


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected([])
      if (selectedRowKeys?.length <= 50) {
        setHasSelected(selectedRowKeys)
      } else {
        message.error('Please select maximum 50 data')
      }
    },
    onSelect: (record, selected, selectedRows) => {

    },
    onSelectAll: (selected, selectedRows, changeRows) => {
    },
  };

  const blogDeleteNotification = async (postId, userData, reportmessage) => {
    try {
      const blogNotification = {
        token: userData?.token,
        type: '0',
        body: `Your blog has been deleted. Reason: ${reportmessage}`,
        body_spanish: `Tu blog ha sido eliminado. Razón: ${reportmessage}`,
        body_german: `Dein Blog wurde gelöscht. Grund: ${reportmessage}`,
        body_italian: `Il tuo blog è stato cancellato. Motivo: ${reportmessage}`,
        body_french: `Votre blog a été supprimé. Raison: ${reportmessage}`,
        title: 'Blog',
        title_spanish: 'Blog',
        title_german: 'Blog',
        title_italian: 'Blog',
        title_french: 'Blog',
        sender_user_id: '',
        check_device: 'web',
        receiver_user_id: userData?.user_id,
        post_id: postId,
        check_mode: REACT_APP_PRODUCTION_MODE
      };
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: blogNotification
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const checkdelete = async (reportmessage) => {
    try {
      if (reportmessage.trim().length > 60) {
        message.error("Please must be maximum 50 characters")
        return
      }
      setLoading(true)
      setIsReportModal(false)
      await Promise.all(hasSelected.map(async (dataID) => {
        const doc = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(dataID).get();
        if (doc.exists) {
          await doc.ref.update({ is_deleted: true, status: 'deleted', update_at: firebase.firestore.FieldValue.serverTimestamp() })
          await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : "users").where('user_id', '==', doc.data().user_id).get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(async function (doc) {
                await blogDeleteNotification(dataID, doc.data(), reportmessage);
              });
            })
        }
      }))
      setLoading(false)
      message.success('Successfully Deleted');
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const handleModerationStatusAll = async () => {
    try {
      setLoading(true)
      await Promise.all(hasSelected.map(async (data) => {
        await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_posts' : 'posts').doc(data).update({ moderation: 'ischecked' })
      }))
      setLoading(false)
      setHasSelected([])
      message.success('Sucessfully moderation updated')
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  return (
    <>
      {loader == false ?
        <Card>
          <Button type="danger" hidden={!hasSelected.length > 0} onClick={() => setIsReportModal(true)}>Delete</Button>
          <Popconfirm title="Sure to moderation?" onConfirm={() => handleModerationStatusAll()}>
            <Button style={{ backgroundColor: 'green', color: 'white' }} hidden={!hasSelected.length > 0}>Moderation</Button>
          </Popconfirm>
          <Modal title="Reason.." visible={isReportModal} footer={false} onCancel={() => { setIsReportModal(false); setFieldValue(''); setTextField(false) }}>
            <p><b>why are you this post delete?</b></p><br />
            <Button onClick={() => {
              checkdelete('Inappropriate content');
              setIsReportModal(false)
            }}>
              Inappropriate content</Button>
            <Button onClick={() => {
              checkdelete('Commercials');
              setIsReportModal(false)
            }}>Commercials</Button>
            <Button onClick={() => {
              checkdelete('Plagiarism');
              setIsReportModal(false)
            }}>Plagiarism</Button>
            <Button onClick={() => setTextField(!TextField)}
            >Other reason</Button>
            {TextField ?
              <>
                <li>
                  <div className="report-form">
                    <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                    <Button className="btn primary-btn" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? checkdelete(fieldValue)
                      : message.error("please fill the data")}>Send report</Button>
                  </div>
                </li>
              </> : null}
          </Modal>
          <Table
            className="gx-table-responsive"
            columns={columns}
            rowSelection={{ ...rowSelection }}
            rowKey={(blogList) => blogList.docId}
            dataSource={[...blogList]}
            pagination={false}
            // onChange={handleTableChange}
            footer={getFooterDetails}
          />
          <Modal title="Reason.." visible={isModalVisible} footer={false} onCancel={() => { setIsModalVisible(false); setFieldValue(''); setTextField(false) }}>
            <p><b>why are you this post delete?</b></p><br />
            <Button onClick={() => {
              handleDelete(suspendId, 'Inappropriate content');
              setIsModalVisible(false)
            }}>
              Inappropriate content</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Commercials');
              setIsModalVisible(false)
            }}>Commercials</Button>
            <Button onClick={() => {
              handleDelete(suspendId, 'Plagiarism');
              setIsModalVisible(false)
            }}>Plagiarism</Button>
            <Button onClick={() => setTextField(!TextField)}
            >Other reason</Button>
            {TextField ?
              <>
                <li>
                  <div className="report-form">
                    <Input type="text" placeholder="write other reason" value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
                    <Button className="btn primary-btn" style={{ marginTop: 10 }} onClick={() => fieldValue.trim().length > 0 ? handleDelete(suspendId, fieldValue)
                      : message.error("please fill the data")}>Send report</Button>
                  </div>
                </li>
              </> : null}
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      }
    </>

  )

}
export default UserBlogs;